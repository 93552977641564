import { useEffect } from 'react'

import { Branding, Link, SocialLink } from './apiTypes'

export function weightAsLbsAndOunces(weightInOunces: number) {
  const OUNCES_PER_POUND = 16
  const lbs = Math.floor(weightInOunces / OUNCES_PER_POUND)
  const ounces = Math.round((weightInOunces - lbs * OUNCES_PER_POUND) * 100) / 100
  return [lbs, ounces]
}

export function transformV1BrandingToV2(branding: BrandingCompatV1) {
  const settings = (branding && branding.settings) || {}

  if (!branding) {
    return null
  }

  if (branding?._API_VERSION) {
    return branding
  }

  const menu_links =
    branding.menu_links ??
    branding.menuLinks?.map((ml: MenuLinkCompatV1) => ({ url: ml.link, text: ml.text } as Link)) ??
    []

  const social_media =
    branding.social_media ??
    branding.socialMedia?.map(
      (ml: SocialLinkCompatV1) => ({ url: ml.url || ml.handle, type: ml.type } as SocialLink),
    ) ??
    []

  const {
    branded_tracking_config_id,
    menuLinks,
    socialMedia,
    ...brandingV2
  }: {
    branded_tracking_config_id: string
    menuLinks: Array<MenuLinkCompatV1> | null
    socialMedia: Array<SocialLinkCompatV1> | null
    brandingV2: Branding
  } = {
    ...branding,
    ...settings,
    // @ts-ignore
    branding_id: branding.branded_tracking_config_id,
    menu_links,
    social_media,
    external_links: [],
  }

  if (process.env.NODE_ENV !== 'test') {
    console.debug && console.debug('Transformed Branding:', brandingV2)
  }

  return brandingV2
}

interface BrandingCompatV1 extends Branding {
  _API_VERSION: string
  branded_tracking_config_id: string
  menuLinks?: Array<MenuLinkCompatV1> | null
  socialMedia?: Array<SocialLinkCompatV1> | null
  settings?: object | null
}

export interface MenuLinkCompatV1 {
  link: string
  text: string
}

export interface SocialLinkCompatV1 {
  handle?: string
  url?: string
  type: string
}

export function useBrandedDocTitle(branding: any) {
  useEffect(() => {
    window.document.title = `${branding?.store_name || ''} Shipment`
  }, [branding])
}

export function isDevMode() {
  const url = new URL(window.location.href)
  return url.searchParams.get('devmode')
}

export function originIsSafe(origin: string) {
  // probably need to revisit this regex as it continues to grow
  return (
    process.env.NODE_ENV === 'test' ||
    origin.match(/localhost:[0-9]+/) ||
    origin.match(/\.shippingeasy\.com$/) ||
    origin.match(/\.shipstation\.com$/) ||
    origin.match(/\.stamps\.com$/) ||
    origin.match(/\.endicia\.com$/) ||
    origin.match(/\.goglobalpost\.com$/) ||
    origin.match(/\.sslocal\.com$/) ||
    origin.match(/supreme-chainsaw-996dc937.pages.github.io/)
  ) // branding-editor-components' Github Pages (docs)
}

export function filterMap<T>(array: Array<T>, callback: (item: T) => any) {
  const mapped = array.map(callback)
  const filtered = mapped.filter(isSet)
  return filtered
}

export function isSet<T>(value: T | undefined): value is T {
  return value !== undefined && value !== null
}

export function hexToRgb(hex: string | undefined) {
  const DEFAULT_COLOR = { r: 0, g: 0, b: 0 }

  if (!hex || hex.length !== 7 || hex[0] !== '#') {
    console.warn(`Invalid hex color in TrackingCard: '${hex}'`)
    return DEFAULT_COLOR
  }

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : DEFAULT_COLOR
}

export const MIN_TABLET_SIZE = 768
export const MIN_DESKTOP_SIZE = 1050
