import React, { useState, useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import * as Sentry from '@sentry/browser'
import classnames from 'classnames'

import { makeBtStyles } from '../Theme'
import { analytics, EVENT_GIFTWRAP_SHARED } from '../Analytics'

import { BtLink } from './BtLink'
import { Dialog, DialogTitle, DialogContent } from './Dialog'

const useStyles = makeBtStyles(theme => ({
  emailOrCopyBlurb: {
    marginBottom: '20px',
  },
  fieldLabel: {
    display: 'block',
    marginBottom: '5px',
  },
  inputWrapper: {
    marginBottom: '26px',
    display: 'flex',
    border: '1px solid #e2e2e2',
    borderRadius: '2px',
    '&:focus-within': {
      border: `1px solid ${theme.colors.main}`,
    },
  },
  input: {
    width: '100%',
    fontSize: '14px',
    height: '40px',
    padding: '8px',
    border: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  inputActionLink: {
    display: 'block',
    borderRadius: '2px',
    paddingLeft: '8px',
    paddingRight: '15px',
    lineHeight: '38px',
    whiteSpace: 'nowrap',
    color: theme.link.color,
    cursor: 'pointer',
    '&.disabled': {
      color: '#ccc',
    },
    '&:hover': {
      textDecoration: 'none',
    },
  },
}))

export default function GiftWrapDialog({
  toggle,
  open,
  shareTitle,
  giftwrapLink,
}: {
  toggle: () => void
  open: boolean
  shareTitle: string
  giftwrapLink: string
}) {
  const intl = useIntl()
  const [mailtoLink, setMailtoLink] = useState('')
  const DEFAULT_COPY_ACTION_TEXT = intl.formatMessage({
    description: 'copyGiftLinkActionText',
    defaultMessage: 'Copy Link',
  })
  const [copyActionText, setCopyActionText] = useState(DEFAULT_COPY_ACTION_TEXT)
  const classes = useStyles()
  const emailActionRef = useRef<HTMLAnchorElement>()

  const sendLinkText = intl.formatMessage({ description: 'sendGiftLinkEmailActionText', defaultMessage: 'Send Link' })

  const onEmailChange = (event: any) => {
    const email = event.target.value

    const subject = shareTitle
    const body = intl.formatMessage(
      {
        description: 'shareGiftLinkEmailBody',
        defaultMessage: 'I just sent you a gift! Track delivery here: {giftwrapLink}',
      },
      { giftwrapLink: encodeURIComponent(giftwrapLink) },
    )

    if (email) {
      setMailtoLink(`mailto:${email}?subject=${subject}&body=${body}`)
    } else {
      setMailtoLink('')
    }
  }

  const onCopyInputFocus = (event: any) => {
    const { target: input, relatedTarget } = event

    input.setSelectionRange(0, input.value.length)
    navigator.clipboard
      .writeText(giftwrapLink)
      .then(() => {
        const copiedText = intl.formatMessage({ description: 'copyGiftLinkCopiedAck', defaultMessage: 'Copied!' })
        setCopyActionText(copiedText)
        analytics.event(EVENT_GIFTWRAP_SHARED, { native: false })
      })
      .catch(error => {
        console.warn && console.warn('Copying giftwrap link to clipboard threw an exception')
        // Seems we get a spurious exception if the user focuses the box _implicitly_ (eg by switching tabs) bc Safari
        // doesn't allow copy-to-clipboard except on user events. This should motly silence those errors. The copy
        // does appear to work, unlike if we tried to write to clipboard from, eg, a `useEffect`
        if (relatedTarget) {
          Sentry.captureException(error)
        }
      })
  }

  const onCopyInputBlur = () => setCopyActionText(DEFAULT_COPY_ACTION_TEXT)

  const onEmailKeypress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      emailActionRef?.current?.click()
    }
  }

  return (
    <Dialog id="share-giftwrap-link-dialog" toggle={toggle} open={open}>
      <DialogTitle onClose={toggle}>
        <FormattedMessage description="shareGiftwrapLinkDialogTitle" defaultMessage="Send a gift tracking link" />
      </DialogTitle>

      <DialogContent>
        <p className={classes.emailOrCopyBlurb}>
          <FormattedMessage
            description="shareGiftwrapExplainer"
            defaultMessage="Send that special someone a tracking link for their gift. We won't reveal the contents or price of the items."
          />
        </p>

        <label className={classes.fieldLabel}>
          <FormattedMessage description="emailAddressFieldLabel" defaultMessage="Email Address" />
        </label>
        <div className={classes.inputWrapper}>
          <input
            className={classes.input}
            type="text"
            placeholder="example@gmail.com"
            onChange={onEmailChange}
            onKeyPress={onEmailKeypress}
          />
          {mailtoLink ? (
            <BtLink href={mailtoLink} className={classes.inputActionLink} target="_blank" ref={emailActionRef}>
              {sendLinkText}
            </BtLink>
          ) : (
            <span className={classnames(classes.inputActionLink, 'disabled')}>{sendLinkText}</span>
          )}
        </div>

        <label className={classes.fieldLabel}>
          <FormattedMessage description="copyGiftLinkFieldLabel" defaultMessage="Copy Link" />
        </label>
        <div className={classes.inputWrapper}>
          <input
            className={classes.input}
            type="text"
            value={giftwrapLink}
            readOnly={true}
            id="copy-gift-link-input"
            onFocus={onCopyInputFocus}
            onBlur={onCopyInputBlur}
          />
          <label className={classes.inputActionLink} htmlFor="copy-gift-link-input">
            {copyActionText}
          </label>
        </div>
      </DialogContent>
    </Dialog>
  )
}
