import React from 'react'
import {
  createTheme,
  MuiThemeProvider,
  responsiveFontSizes,
  makeStyles,
  ThemeOptions,
  Theme as MuiTheme,
} from '@material-ui/core/styles'
import { Breakpoints } from '@material-ui/core/styles/createBreakpoints'
import CssBaseline from '@material-ui/core/CssBaseline'
import _merge from 'lodash/merge'

import { useBrandingData, ThemeKeys } from './dataProviders'
import { findTheme, ThemeConfig, CssPropsAndValues } from './themes'

export interface Theme extends ThemeConfig {
  breakpoints: Breakpoints
}

export function makeBtStyles(callbackOrStyles: ((theme: Theme) => object) | object, options: any = {}) {
  if (typeof callbackOrStyles === 'function') {
    return makeStyles((theme: MuiTheme) => {
      const themeConfig = theme as unknown as Theme
      return callbackOrStyles(themeConfig)
    }, options) as (params?: any) => any
  } else {
    return makeStyles(callbackOrStyles, options)
  }
}

const DEFAULT_FONT_FAMILY = [
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  'Oxygen',
  'Ubuntu',
  'Cantarell',
  '"Fira Sans"',
  '"Droid Sans"',
  '"Helvetica Neue"',
  'sans-serif',
]

const DEFAULT_THEME_CONFIG = {
  page: {
    fontFamily: DEFAULT_FONT_FAMILY.join(','),
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}

const muiOverrides = (themeConfig: ThemeConfig) => ({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          height: '100%',
        },
      },
    },
    MuiButtonBase: {
      root: {
        color: themeConfig.link.color,
      },
    },
    MuiButton: {
      root: {
        color: themeConfig.link.color,
      },
    },
  },
})

const DEFAULT_BODY_BACKGROUND = '#222222'

const usePageStyles = makeStyles((theme: ThemeConfig) => ({
  '@global': {
    body: {
      position: 'relative', // Make sure position:absolute elements land correctly, specifically PoweredBy
      minHeight: '100%',
      height: '100%',
      ...theme.page,
      '& #root': {
        height: '100%',
      },
    },
    a: {
      ...theme.link,
    },
  },
}))

function PageStyles() {
  usePageStyles()
  return null
}

export interface ThemeConfigOverrides {
  colors?: {
    main?: string
    characteristic?: string | undefined
  }
  page?: CssPropsAndValues
  link?: CssPropsAndValues
  recentTrackingDot?: CssPropsAndValues
  layoutSpecific?: any
}

export default function ThemeProvider({ children }: { children: React.ReactNode }) {
  let theme
  const { theme_key, colors } = useBrandingData()
  const anyCustomColors = !!(colors?.primary || colors?.secondary || colors?.tertiary)
  const chosenThemeColors: ThemeConfigOverrides = colors
    ? {
        page: { background: colors.secondary },
        link: { color: colors.tertiary },
        colors: { main: colors.primary },
        recentTrackingDot: { backgroundColor: colors.tertiary },
      }
    : {}

  let themeConfig = findTheme(anyCustomColors ? ThemeKeys.Custom : theme_key)

  // Cascading defaults
  themeConfig = _merge({}, DEFAULT_THEME_CONFIG, themeConfig)

  /*****************************************************************************************************/
  /* The logic here (combined with the `chosenThemeColors` provided by the layout styles) ensures that */
  /* the necessary styles (esp colors) are present in the theme structure at usage time and            */
  /* no uses need to use backup colors (ie NO `theme.main.color || theme.link.color`).                 */
  /*                                                                                                   */
  /*****************************************************************************************************/

  // Layout overrides (has to come before hierarchy computations!).
  themeConfig = _merge({}, themeConfig, chosenThemeColors)

  // Hierarchical overrides.  These are not strictly necessary b/c we could just fully-specify the themes' styles.
  // But since the `chosenThemeColors` may contain changes to some of those things it still makes sense.
  themeConfig = {
    ...themeConfig,
    page: {
      color: DEFAULT_BODY_BACKGROUND,
      ...themeConfig.page,
    },
    link: _merge({}, { color: themeConfig.colors.main }, themeConfig.link),
  }

  // Page-level rules specially targetted in MUI
  themeConfig = _merge({}, muiOverrides(themeConfig), themeConfig)

  try {
    theme = createTheme(themeConfig as ThemeOptions)
  } catch (_) {
    const emptyTheme: ThemeConfig = {
      colors: {
        main: '',
      },
      page: {},
      link: {},
      recentTrackingDot: {},
      characteristicFont: {},
      mainTrackingStatus: {},
      signatureRequired: {},
      card: {},
      layoutSpecific: {
        layout01: { cardHeader: {} },
        layout02: { cardHeader: {} },
        layout03: { cardHeader: {} },
      },
    }

    theme = createTheme(emptyTheme as ThemeOptions)
  }

  theme = responsiveFontSizes(theme)

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <PageStyles />
      {children}
    </MuiThemeProvider>
  )
}
