import React from 'react'

import { Branding } from '../dataProviders'
import ThemeProvider from '../Theme'
import PoweredBy from '../components/PoweredBy'
import { makeBtStyles } from '../Theme'
import { MIN_DESKTOP_SIZE } from '../utils'

import AppBar from './AppBar'
import TrackingPage from './TrackingPage'
import ReturnsPage from './ReturnsPage'

const useStyles = makeBtStyles(theme => ({
  appBar: {
    [theme.breakpoints.up(MIN_DESKTOP_SIZE)]: {
      display: 'none',
    },
  },
  layout03: {
    position: 'relative', // want 'absolute'-ly positioned elements to be able to go below the fold (body => height:100%)
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flexGrow: '1',
    display: 'flex',
    [theme.breakpoints.down(MIN_DESKTOP_SIZE)]: {
      justifyContent: 'center',
    },
  },
  poweredBy: {
    [theme.breakpoints.up(MIN_DESKTOP_SIZE)]: {
      display: 'none',
    },
  },
}))

function Layout03({ children }: { branding: Branding; children: React.ReactNode }) {
  const classes = useStyles()

  return (
    <ThemeProvider>
      <div data-testid="layout03" className={classes.layout03}>
        <AppBar className={classes.appBar} />
        <div className={classes.content}>{children}</div>
        <PoweredBy className={classes.poweredBy} />
      </div>
    </ThemeProvider>
  )
}

export default {
  Layout: Layout03,
  TrackingPage,
  ReturnsPage,
}
