import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import classnames from 'classnames'

import { makeBtStyles } from '../Theme'
import { analytics, EVENT_GIFTWRAP_SHARE_CLICK, EVENT_GIFTWRAP_SHARED } from '../Analytics'
import { useFeatureEnables } from '../dataProviders'

import { BtLink } from './BtLink'
import { ReactComponent as GoldPresent } from './GoldPresent.svg'
import GiftWrapDialog from './GiftWrapDialog'
import { MIN_TABLET_SIZE } from '../utils'

const useStyles = makeBtStyles(theme => ({
  panel: {
    background: 'white',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '17px',
    padding: '16px',
    color: theme.link.color,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
    '& svg': {
      position: 'absolute',
      left: '20px',
      top: '50%',
      marginTop: '-46.5px', // 1/2 height of GoldPresent svg
    },
    [theme.breakpoints.down('xs')]: {
      '& svg': {
        transform: 'scale(0.65)',
        left: '-10px',
      },
    },
  },
  panelMessage: ({isNarrow}: {isNarrow: boolean}) => ({
    ...(isNarrow ? {
      marginLeft: '110px',
    } : {
      textAlign: 'center'
    }),
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      marginLeft: 'calc(9% + 45px)', // centered-ish text between md & xs
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
    },
  }),
  text: {
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      whiteSpace: 'break-spaces',
    },
  },
  link: {
    whiteSpace: 'nowrap',
    textDecoration: 'underline',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      whiteSpace: 'break-spaces',
    },
  },
}))

const CURRENT_URL = new URL(window.location.href)
const GIFT_WRAP_PARAM = 'giftwrap'
const GIFT_WRAP_LINK = `${CURRENT_URL}&${GIFT_WRAP_PARAM}=1`

export const isGiftWrapped = CURRENT_URL.searchParams.get(GIFT_WRAP_PARAM)

export default function GiftWrapPanel({ className = '', isNarrow = false }) {
  const [shareDialogOpen, setShareDialogOpen] = useState(false)
  const classes = useStyles({isNarrow})
  const intl = useIntl()
  const { showGiftLink } = useFeatureEnables()

  if (!showGiftLink) {
    return null
  }

  const shareTitle = intl.formatMessage({
    description: 'shareGiftLinkTitle',
    defaultMessage: 'A gift is on its way!',
  })

  const shareLink = async () => {
    try {
      await navigator.share({
        title: shareTitle,
        url: GIFT_WRAP_LINK,
      })
      analytics.event(EVENT_GIFTWRAP_SHARED, { native: true })
    } catch (err) {
      // Experimentally found on Safari: decided not to share (or failed to share and clicked link again)
      analytics.event(EVENT_GIFTWRAP_SHARE_CLICK, { native: false })
      setShareDialogOpen(true)
    }
  }

  const panelClassname = classnames(className, classes.panel)

  return isGiftWrapped ? (
    <div className={panelClassname}>
      <GoldPresent />
      <div className={classes.panelMessage}>
        <FormattedMessage description="giftwrapPanelRecipientMessage" defaultMessage="You have a gift arriving soon!" />
      </div>
    </div>
  ) : (
    <>
      <BtLink onClick={shareLink} className={panelClassname}>
        <GoldPresent />
        <div className={classes.panelMessage}>
          <span className={classes.text}>
            <FormattedMessage description="giftwrapPanelBlurb" defaultMessage="Shipping this as a gift?" />
          </span>{' '}
          <span className={classes.link}>
            <FormattedMessage description="giftwrapPanelShareLink" defaultMessage="Send them a gift tracking link" />.
          </span>
        </div>
      </BtLink>
      <GiftWrapDialog
        toggle={() => setShareDialogOpen(!shareDialogOpen)}
        open={shareDialogOpen}
        shareTitle={shareTitle}
        giftwrapLink={GIFT_WRAP_LINK}
      />
    </>
  )
}
