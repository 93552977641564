import React from 'react'
import { makeBtStyles } from '../Theme'
import { FormattedMessage } from 'react-intl'

import { useBrandingData } from '../dataProviders'
import { BtLink } from './BtLink'

const useStyles = makeBtStyles(
  theme => ({
    root: {
      margin: '0 auto',
      width: '100%',
      background: 'white',
      padding: '4px 0 8px',
      display: 'flex',
      justifyContent: 'center',
    },
    link: {
      textAlign: 'center',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '17px',
      display: 'block',
      justifyContent: 'center',
      color: '#929292',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    preText: {
      marginRight: '3px',
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    image: {
      maxHeight: '15px',
      marginLeft: '1px',
      display: 'inline-block',
      verticalAlign: 'middle',
      marginBottom: '-2px',
    },
  }),
  { name: 'powered-by' },
)

export default function PoweredBy({ className = '' }) {
  const classes = useStyles()
  const { powered_by: { name, logo_url, url } = {} } = useBrandingData()

  if (!name && !logo_url) {
    return null
  }

  return (
    <div className={`${classes.root} ${className}`}>
      <OptionalLink className={classes.link} href={url || undefined} target="_blank">
        <span className={classes.preText}>
          <FormattedMessage description="poweredByPrefixText" defaultMessage="Powered By" />
        </span>
        {logo_url ? (
          <img src={logo_url} alt={name || undefined} className={classes.image} />
        ) : (
          <span className={classes.name}>{name}</span>
        )}
      </OptionalLink>
    </div>
  )
}

const OptionalLink = ({ href, className, children, ...rest }: any) => {
  if (href) {
    return (
      <BtLink className={className} href={href} {...rest}>
        {children}
      </BtLink>
    )
  } else {
    return <span className={className}>{children}</span>
  }
}
