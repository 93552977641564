import {
  ThemeConfig,
  Layout01ThemeCustomizations,
  Layout02ThemeCustomizations,
  Layout03ThemeCustomizations,
} from './index'

const Unicorns: ThemeConfig = {
  colors: {
    main: '#D5367E',
    characteristic: '#32267D',
  },
  page: {
    background: 'linear-gradient(135deg, #ffc84b 0%,#f78e78 25%,#ee7392 50%,#d685d5 75%,#90b6e7 100%)',
    backgroundAttachment: 'fixed',
  },
  link: {},
  characteristicFont: {
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: '700',
  },
  mainTrackingStatus: {
    fontSize: '34px',
    lineHeight: '1.176',
  },
  signatureRequired: {
    background: '#7FDAD4',
    color: '#000000',
  },
  card: {
    background: 'white',
  },
  recentTrackingDot: {
    backgroundColor: '#D5367E',
  },
  layoutSpecific: {
    layout01: {
      cardHeader: {
        color: '#32267D',
      },
    } as Layout01ThemeCustomizations,
    layout02: {
      cardHeader: {
        // color: '#32267D'
      },
    } as Layout02ThemeCustomizations,
    layout03: {
      cardHeader: {
        color: '#32267D',
      },
    } as Layout03ThemeCustomizations,
  },
}

export default Unicorns
