import React from 'react'

import SocialMediaLink from '../components/SocialMediaLink'
import { useBrandingData, useFeatureEnables } from '../dataProviders'

export interface SocialMediaGridClasses {
  root?: string
  link?: string
  linkIcon?: string
}

export default function SocialMediaGrid({ classes = {} }: { classes: SocialMediaGridClasses }) {
  const { showSocialMedia } = useFeatureEnables()
  const { social_media } = useBrandingData()
  const validLinks = social_media.filter(({ url }) => url)

  if (!showSocialMedia) {
    return null
  }

  return (
    <div className={classes.root}>
      {validLinks.map(link => (
        <SocialMediaLink key={link.type} link={link} classes={{ root: classes.link, icon: classes.linkIcon }} />
      ))}
    </div>
  )
}
