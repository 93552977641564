import React from 'react'

import { Branding } from '../dataProviders'
import ThemeProvider from '../Theme'
import PoweredBy from '../components/PoweredBy'
import { makeBtStyles } from '../Theme'
import { MIN_DESKTOP_SIZE } from '../utils'

import AppBar from './AppBar'
import TrackingPage from './TrackingPage'
import ReturnsPage from './ReturnsPage'

const useStyles = makeBtStyles(theme => ({
  appBar: {
    [theme.breakpoints.up(MIN_DESKTOP_SIZE)]: {
      display: 'none',
    },
  },
  layout02: {
    position: 'relative', // want 'absolute'-ly positioned elements to be able to go below the fold (body => height:100%)
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flexGrow: '1',
    display: 'flex',
  },
  poweredBy: {
    bottom: '0',
  },
}))

function Layout02({ children }: { branding: Branding; children: React.ReactNode }) {
  const classes = useStyles()

  return (
    <ThemeProvider>
      <div data-testid="layout02" className={classes.layout02}>
        <AppBar className={classes.appBar} />
        <div className={classes.content}>{children}</div>
        <PoweredBy className={classes.poweredBy} />
      </div>
    </ThemeProvider>
  )
}

export default {
  Layout: Layout02,
  TrackingPage,
  ReturnsPage,
}
