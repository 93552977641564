import React from 'react'
import { useShipmentData } from '../dataProviders'
import { makeBtStyles } from '../Theme'
import { CarbonNeutralLink } from './BtLink'
import { ReactComponent as CarbonNeutralSvg } from './CarbonNeutral.svg'

const useStyles = makeBtStyles(theme => ({
  carbonNeutralWrapper: {
    backgroundColor: '#f5f5f5',
    padding: '8px 20px 8px 10px',
    display: 'flex',
    width: '100%',
    marginTop: '10px',
    [theme.breakpoints.down('xs')]: {
      paddingRight: '10px',
    },
  },
  carbonNeutralTextWrapper: ({ isFlex }: { isFlex: boolean }) => ({
    ...(isFlex
      ? {
          display: 'flex',
          flexDirection: 'column',
        }
      : {
          display: 'block',
        }),
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  }),
  carbonNeutralText: {
    color: '#65696d',
    fontSize: '12px',
    lineHeight: '16px',
  },
  carbonNeutralIcon: {
    minWidth: '20px',
    width: '20px',
    height: '20px',
    marginRight: '10px',
  },
  link: {
    width: 'max-content',
    fontSize: '12px',
  },
}))

export default function CarbonNeutral({ isFlex = false }) {
  const classes = useStyles({ isFlex })
  const redirectUrl = 'https://shippingeasy.com/carbon-neutral-shipping-greenest-way-to-ship/'
  const { carbon_neutral } = useShipmentData()

  return carbon_neutral ? (
    <div className={classes.carbonNeutralWrapper}>
      <CarbonNeutralSvg className={classes.carbonNeutralIcon} />
      <div className={classes.carbonNeutralTextWrapper}>
        <span className={classes.carbonNeutralText}>
          We've made this shipment carbon-neutral, in partnership with ShippingEasy.
        </span>{' '}
        <CarbonNeutralLink className={classes.link} href={redirectUrl} target="_blank">
          Learn more
        </CarbonNeutralLink>
      </div>
    </div>
  ) : null
}
