import React from 'react'
import { faPhone, faEnvelope, faUndo } from '@fortawesome/free-solid-svg-icons'
import { useIntl, FormattedMessage } from 'react-intl'

import { useBrandingData, useFeatureEnables } from '../dataProviders'
import Icon from './Icon'
import { useReturnPolicyDialog } from './ReturnPolicyDialog'
import { BtLink } from './BtLink'

export function ContactPhone({ className, withIcon = true }: { className?: string; withIcon?: boolean }) {
  const intl = useIntl()
  const { showPhone } = useFeatureEnables()
  const { phone } = useBrandingData()

  if (!showPhone) {
    return null
  }

  const ariaLabel = intl.formatMessage({ description: 'contactPhone.label', defaultMessage: 'Contact Phone' })

  return (
    <BtLink href={`tel:${phone}`} className={className} aria-label={ariaLabel}>
      <span className="text">{phone}</span>
      {withIcon && (
        <>
          {' '}
          <Icon icon={faPhone} className="icon" />
        </>
      )}
    </BtLink>
  )
}

export function ContactEmail({ className, withIcon = true }: { className?: string; withIcon?: boolean }) {
  const intl = useIntl()
  const { showEmail } = useFeatureEnables()
  const { email } = useBrandingData()

  if (!showEmail) {
    return null
  }

  const ariaLabel = intl.formatMessage({ description: 'contactEmail.label', defaultMessage: 'Contact Email' })

  return (
    <BtLink href={`mailto:${email}`} className={className} aria-label={ariaLabel}>
      <span className="text">{email}</span>
      {withIcon && (
        <>
          {' '}
          <Icon icon={faEnvelope} className="icon" />
        </>
      )}
    </BtLink>
  )
}

export function ReturnPolicyLink({ className }: { className?: string }) {
  const { showReturnPolicy } = useFeatureEnables()
  const { toggle: toggleDialog } = useReturnPolicyDialog()

  if (!showReturnPolicy) {
    return null
  }

  return (
    <BtLink onClick={toggleDialog} className={className}>
      <span className="text">
        <FormattedMessage description="returnPolicyLinkText" defaultMessage="Return Policy" />
      </span>
      <Icon icon={faUndo} className="icon" />
    </BtLink>
  )
}

export default function ContactInfo({ className }: { className?: string }) {
  const { showPhone, showEmail, showReturnPolicy } = useFeatureEnables()

  if (!showPhone && !showEmail && !showReturnPolicy) {
    return null
  }

  return (
    <div className={className}>
      <ContactPhone className="contactPhone" />
      <ContactEmail className="contactEmail" />
      <ReturnPolicyLink className="returnPolicyLink" />
    </div>
  )
}
