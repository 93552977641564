import React from 'react'
import { useMediaQuery } from '@material-ui/core'

import { makeBtStyles } from '../Theme'
import PromoImage, { usePromoImage } from '../components/PromoImage'
import GiftWrapPanel from '../components/GiftWrapPanel'
import { useFeatureEnables } from '../dataProviders'
import { MIN_TABLET_SIZE, MIN_DESKTOP_SIZE } from '../utils'

import MainCard from './MainCard'
import TrackingCard from './TrackingCard'
import SmsCard from './SmsCard'
import ShipmentActivity from './ShipmentActivity'
import TabletContactCard from './TabletContactCard'

export const MENU_COL_WIDTH = 300
export const SECONDARY_COL_WIDTH = 649
export const THIRD_COL_WIDTH = 300
export const COL_SPACING = 15

const CONTENT_WIDTH = 1200
const CONTENT_HEIGHT = 645
const MOBILE_CONTENT_WIDTH = 95

const SHORT_PROMO_IMAGE_HEIGHT = 250
const PROMO_IMAGE_TALL_TYPE = 'tall'

const useTrackingPageStyles = makeBtStyles(theme => ({
  contentWrapper: {
    maxWidth: `${CONTENT_WIDTH}px`,
    [theme.breakpoints.up(MIN_DESKTOP_SIZE)]: {
      margin: `0px 0px 0px ${MENU_COL_WIDTH + 85}px`,
    },
    [theme.breakpoints.down(MIN_DESKTOP_SIZE)]: {
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      width: '100%',
      margin: '0',
      maxWidth: 'none',
    },
  },
  cardGrid: ({ isBetweenMdLg }: { isBetweenMdLg: boolean }) => ({
    display: isBetweenMdLg ? 'block' : 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      display: 'block',
    },
  }),
  menuColumn: {
    position: 'fixed',
    left: '0',
    top: '0',
    bottom: '0',
    boxShadow: '-20px 0px 50px #888888',
    width: `${MENU_COL_WIDTH}px`,
    height: '100vh',
    zIndex: '1',
    [theme.breakpoints.down(MIN_DESKTOP_SIZE)]: {
      display: 'none',
    },
  },
  trackingCard: ({ showGiftLink }: { showGiftLink: boolean }) => ({
    [theme.breakpoints.up(MIN_DESKTOP_SIZE)]: {
      height: showGiftLink ? '739px' : '100%',
    },
  }),
  trackingColumn: {
    height: '815px',
    [theme.breakpoints.up(MIN_DESKTOP_SIZE)]: {
      margin: '47px 0px 20px',
    },
    [theme.breakpoints.up(MIN_TABLET_SIZE)]: {
      borderLeftStyle: 'none',
      width: `${SECONDARY_COL_WIDTH}px`,
    },
    [theme.breakpoints.down(MIN_DESKTOP_SIZE)]: {
      minWidth: '400px',
      height: '780px',
      margin: '20px auto',
    },
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      width: MOBILE_CONTENT_WIDTH + '% !important',
      maxWidth: '625px !important',
      minWidth: '290px',
      height: 'auto',
      margin: '15px auto',
    },
  },
  secondaryColumn: ({ isBetweenMdLg }: { isBetweenMdLg: boolean }) => ({
    width: `${THIRD_COL_WIDTH}px`,
    margin: isBetweenMdLg ? '0 auto' : '47px 0px 20px 25px',
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      width: '100% !important',
      height: 'auto',
      minWidth: '270px',
      margin: '0 !important',
    },
    [theme.breakpoints.between(MIN_TABLET_SIZE, MIN_DESKTOP_SIZE)]: {
      width: `${SECONDARY_COL_WIDTH}px`,
      margin: '0px 20px',
    },
  }),
  smsCard: {
    width: '100%',
    marginBottom: `${COL_SPACING}px`,
    [theme.breakpoints.up(MIN_DESKTOP_SIZE)]: {
      height: '200px !important',
    },
    [theme.breakpoints.down(MIN_DESKTOP_SIZE)]: {
      margin: '15px auto',
    },
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      maxWidth: '625px',
      minHeight: '120px',
      padding: '0',
      margin: '10px auto !important',
      width: MOBILE_CONTENT_WIDTH + '% !important',
    },
  },
  promoImageWrapper: {
    [theme.breakpoints.down(MIN_DESKTOP_SIZE)]: {
      width: '100%',
      maxWidth: '320px',
      margin: '30px auto',
    },
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      width: '100%',
      maxWidth: '320px',
      margin: '30px auto',
    },
  },
  giftWrapPanel: {
    display: 'block',
    border: '0.5px solid #DFE3E8',
    boxShadow: '0 1px 10 rgb (0, 0, 0, 0.05)',
    position: 'relative',
    margin: '0px 0 25px 0',
    [theme.breakpoints.between(MIN_TABLET_SIZE, MIN_DESKTOP_SIZE)]: {
      margin: '30px 20px',
    },
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      maxWidth: '625px',
      marginTop: '30px',
    },
  },
  displayOnMobile: {
    [theme.breakpoints.up(MIN_TABLET_SIZE)]: {
      display: 'none',
    },
  },
  hideOnMobile: {
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      display: 'none',
    },
  },
  displayOnTablet: {
    width: '649px',
    [theme.breakpoints.up(MIN_DESKTOP_SIZE)]: {
      display: 'none',
    },
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      display: 'none',
    },
  },
  hideOnTablet: {
    [theme.breakpoints.between(MIN_TABLET_SIZE, MIN_DESKTOP_SIZE)]: {
      display: 'none',
    },
  },
  displayOnDesktop: {
    [theme.breakpoints.down(MIN_DESKTOP_SIZE)]: {
      display: 'none',
    },
  },
  hideOnDesktop: {
    [theme.breakpoints.up(MIN_DESKTOP_SIZE)]: {
      display: 'none',
    },
  },
}))

export default function TrackingPage() {
  const promoImage = usePromoImage()
  const isBetweenMdLg = useMediaQuery('(max-width: 1370px)')

  const promoMaxHeight = promoImage?.size_type === PROMO_IMAGE_TALL_TYPE ? CONTENT_HEIGHT : SHORT_PROMO_IMAGE_HEIGHT

  const { showGiftLink } = useFeatureEnables()
  const classes = useTrackingPageStyles({ showGiftLink, isBetweenMdLg })

  return (
    <div className={classes.contentWrapper}>
      <GiftWrapPanel className={`${classes.giftWrapPanel} ${classes.displayOnTablet}`} isNarrow />
      <div className={classes.cardGrid}>
        <div className={classes.menuColumn}>
          <MainCard />
        </div>
        <div className={classes.trackingColumn}>
          <GiftWrapPanel className={`${classes.giftWrapPanel} ${classes.hideOnTablet}`} isNarrow />
          <TrackingCard className={classes.trackingCard} />
        </div>
        {!!promoImage && (
          <div className={`${classes.secondaryColumn} ${classes.displayOnDesktop}`}>
            <SmsCard className={classes.smsCard} />
            <div className={classes.promoImageWrapper}>
              <PromoImage maxHeight={promoMaxHeight} />
            </div>
          </div>
        )}
      </div>
      <div className={`${classes.secondaryColumn} ${classes.hideOnDesktop}`}>
        <SmsCard className={classes.smsCard} />
        <div className={classes.hideOnMobile}>
          <TabletContactCard />
        </div>
        <div className={classes.displayOnMobile}>
          <ShipmentActivity />
        </div>
        {!!promoImage && (
          <div className={classes.promoImageWrapper}>
            <PromoImage maxHeight={promoMaxHeight} />
          </div>
        )}
      </div>
    </div>
  )
}
