import React from 'react'
import MuiAppBar from '@material-ui/core/AppBar'
import { Box, Toolbar } from '@material-ui/core'

import { NavClasses, NavLogoClasses, ToggleClasses } from './SharedAppBar'
import NavLogo from './NavLogo'
import NavLinks from './NavLinks'
import SocialMediaGrid from './SocialMediaGrid'

export default function MenuLinksNavBar({
  className,
  logoClasses,
  navClasses = {},
  showSocialMedia,
  truncateLinksAt,
  toggleClasses,
}: {
  className?: string
  logoClasses: NavLogoClasses
  navClasses: NavClasses
  toggleClasses?: ToggleClasses
  showSocialMedia: Boolean
  truncateLinksAt?: number
}) {
  return (
    <div className={className}>
      <MuiAppBar position="static" classes={{ root: navClasses.root }}>
        <Toolbar classes={{ root: navClasses.toolbar }}>
          <Box flexGrow={1}>
            <NavLogo classes={logoClasses} />
            <div className={navClasses.linkContainer}>
              <span aria-label="Main Navigation">
                <NavLinks linkClassName={navClasses.link} truncateLinksAt={truncateLinksAt} classes={toggleClasses} />
              </span>
            </div>
          </Box>
          {showSocialMedia && (
            <SocialMediaGrid
              classes={{
                root: navClasses.socialMediaGrid,
                link: navClasses.socialMediaLink,
                linkIcon: navClasses.socialMediaLinkIcon,
              }}
            />
          )}
        </Toolbar>
      </MuiAppBar>
    </div>
  )
}
