import React from 'react'
import { FormattedMessage } from 'react-intl'

import LineItems, { LineItemCount, useHasLineItems } from '../components/LineItems'
import OrderNumber, { useHasOrderNumber } from '../components/OrderNumber'
import { useFeatureEnables } from '../dataProviders'
import { isGiftWrapped } from '../components/GiftWrapPanel'

import Card, { CardHeader } from './Card'
import { makeBtStyles } from '../Theme'

const useStyles = makeBtStyles(theme => ({
  contentLabel: {
    fontSize: '10px',
    fontWeight: '700',
    lineHeight: '12px',
    textTransform: 'uppercase',
    display: 'block',
    marginBottom: '2px',
  },
  scrollableSection: {
    overflow: 'auto',
    height: 'calc(100% - 42px)',
  },
  orderNumberSection: {
    margin: '12px 20px',
  },
  orderNumber: {
    fontSize: '14px',
    fontWeight: '300',
    lineHeight: '20px',
  },
  lineItems: {
    marginTop: '20px',
  },
  itemsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

const useLineItemStyles = makeBtStyles(theme => ({
  item: {
    minHeight: '78px',
    marginBottom: '12px',
    '&.item--no-image': {
      minHeight: '30px',
      '& $name, & $quantity, & $options': {
        marginLeft: '20px',
      },
    },
  },
  image: {
    float: 'left',
    width: '78px',
    height: '78px',
    border: '1px solid #DFE3E8',
    display: 'flex',
    margin: '0 10px 0 20px',
    '& img': {
      alignSelf: 'center',
      margin: '0 auto',
    },
  },
  name: {
    marginLeft: '88px',
    paddingRight: '20px',
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '16px',
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
  },
  description: {
    fontSize: '12px',
    fontWeight: '300',
    lineHeight: '20px',
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
  },
  options: {
    marginLeft: '88px',
    paddingRight: '20px',
    fontSize: '12px',
    fontWeight: '300',
    lineHeight: '20px',
    listStyleType: 'none',
    padding: '0',
    marginTop: '0',
    marginBottom: '0',
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
  },
  optionItem: {
    display: 'inline',
    '&:after': {
      content: '", "',
    },
    '&:last-child:after': {
      display: 'none',
    },
  },
  optionLabel: {
    fontWeight: 'bold',
    textTransform: 'capitalize',
    display: 'none',
  },
  quantity: {
    display: 'table',
    marginLeft: '88px',
    fontSize: '12px',
    fontWeight: '300',
    lineHeight: '20px',
  },
  unitPrice: {
    display: 'table',
    marginLeft: '88px',
    fontSize: '12px',
    fontWeight: '300',
    lineHeight: '20px',
  },
  subtotal: {
    display: 'none',
  },
}))

export function useIsLineItemsCardShown() {
  const hasLineItems = useHasLineItems()
  const hasOrderNumber = useHasOrderNumber()
  const { showShipmentDetails } = useFeatureEnables()

  return !((!hasLineItems && !hasOrderNumber) || !showShipmentDetails || isGiftWrapped)
}

export default function LineItemsCard({ className }: { className: string }) {
  const classes = useStyles()
  const lineItemClasses = useLineItemStyles()
  const hasOrderNumber = useHasOrderNumber()

  return (
    <Card className={className}>
      <CardHeader className={classes.itemsHeader}>
        <div>
          <FormattedMessage description="layout01.lineItemsCard.heading" defaultMessage="Shipment Contents" />
        </div>
        <div>
          <LineItemCount />
        </div>
      </CardHeader>
      <div className={classes.scrollableSection}>
        {hasOrderNumber && (
          <div className={classes.orderNumberSection}>
            <label className={classes.contentLabel}>
              <FormattedMessage description="layout01.lineItemsCard.orderNumberLabel" defaultMessage="Order Number" />:
            </label>
            <OrderNumber className={classes.orderNumber} />
          </div>
        )}
        <div className={classes.lineItems}>
          <LineItems classes={lineItemClasses} formatQuantity={q => <>Qty {q}</>} />
        </div>
      </div>
    </Card>
  )
}
