import { ThemeKeys } from '../dataProviders'

import light from './Light'
import dark from './Dark'
import paparazzi from './Paparazzi'
import peach from './Peach'
import unicorns from './Unicorns'
import custom from './Custom'

export interface CssPropsAndValues {
  [key: string]: string | undefined
}

export interface Layout01ThemeCustomizations {
  cardHeader: CssPropsAndValues
}

export interface Layout02ThemeCustomizations {
  cardHeader: CssPropsAndValues
}

export interface Layout03ThemeCustomizations {
  cardHeader: CssPropsAndValues
}

export interface ThemeConfig {
  colors: {
    main: string
    characteristic?: string | undefined
  }
  page: CssPropsAndValues
  link: CssPropsAndValues
  characteristicFont: {
    fontFamily?: string
    fontWeight?: string
  }
  mainTrackingStatus: CssPropsAndValues
  recentTrackingDot: CssPropsAndValues
  signatureRequired: CssPropsAndValues
  card: CssPropsAndValues
  layoutSpecific: {
    layout01: Layout01ThemeCustomizations
    layout02: Layout02ThemeCustomizations
    layout03: Layout02ThemeCustomizations
  }
}

export function findTheme(themeKey: ThemeKeys): ThemeConfig {
  switch (themeKey) {
    case ThemeKeys.Light:
      return light
    case ThemeKeys.Dark:
      return dark
    case ThemeKeys.Paparazzi:
      return paparazzi
    case ThemeKeys.Peach:
      return peach
    case ThemeKeys.Unicorns:
      return unicorns
    case ThemeKeys.Custom:
      return custom
    default:
      return light
  }
}
