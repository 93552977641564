import React, { useMemo, useContext } from 'react'
import moment from 'moment'

import { Tracking as ApiTracking } from '../apiTypes/api'

export interface Tracking {
  estimated_delivery_date?: string
  timestamp?: string
  events: Array<TrackingEvent>
  carrier_status_description?: string
  isDelivered: boolean
  latestUpdate: TrackingEvent
  status_code?: TrackingStatusCodes
  source?: string
  sourceEvent: TrackingEvent
  tracking_number: string
  loaded: boolean
}

export interface TrackingEvent {
  city_locality?: string
  country_code?: string
  description?: string
  latitude?: number
  longitude?: number
  carrier_occurred_at?: string
  state_province?: string
}

export enum TrackingStatusCodes {
  AC = 'AC',
  IT = 'IT',
  DE = 'DE',
  EX = 'EX',
  UN = 'UN',
  AT = 'AT',
  NY = 'NY',
}

export enum TrackingStepNumbers {
  Proccessed = 1,
  Shipped = 2,
  InTransit = 3,
  OutForDelivery = 4,
  Delivered = 5,
}

const TrackingDataContext = React.createContext({})
export const TrackingDataProvider = TrackingDataContext.Provider
export function useTrackingData(): Tracking {
  const trackingContextData = (useContext(TrackingDataContext) || {}) as { tracking: ApiTracking; loaded: boolean }
  const trackingData = (trackingContextData?.tracking || {}) as ApiTracking
  const orderedEvents = useOrderedCarrierEvents(trackingData.events)

  let status_code: TrackingStatusCodes | undefined = undefined
  Object.values(TrackingStatusCodes).forEach(status => {
    if ((trackingData.status_code as string) === (status as string)) {
      status_code = status as TrackingStatusCodes
    }
  })

  return {
    estimated_delivery_date: trackingData.estimated_delivery_date,
    timestamp: trackingData.timestamp,
    events: orderedEvents,
    carrier_status_description: trackingData.carrier_status_description,
    isDelivered: (trackingData.status_code || '').toUpperCase() === 'DE',
    latestUpdate: orderedEvents && orderedEvents[0],
    status_code: status_code,
    source: trackingData.source,
    sourceEvent: orderedEvents && orderedEvents[orderedEvents.length - 1],
    tracking_number: trackingData.tracking_number,
    loaded: trackingContextData.loaded,
  }
}

export const useOrderedCarrierEvents = (events: any) => {
  // Note: Assumes that events are already sorted by occurrence but we don't
  // know which direction. Finds the first and last event w/ valid timestamps
  // and compares to keep the order or reverse. This keep any events w/
  // invalid timestamps in place.
  return useMemo(() => {
    let orderedEvents = events || []

    if (orderedEvents.length > 1) {
      const reversedEvents = [...orderedEvents].reverse()
      const firstTimestampedEvent = orderedEvents.find(
        (e: any) => e.carrier_occurred_at && moment(e.carrier_occurred_at).isValid(),
      )
      const lastTimestampedEvent = reversedEvents.find(
        (e: any) => e.carrier_occurred_at && moment(e.carrier_occurred_at).isValid(),
      )

      if (
        moment(firstTimestampedEvent.carrier_occurred_at).isBefore(moment(lastTimestampedEvent.carrier_occurred_at))
      ) {
        orderedEvents = reversedEvents
      }
    }

    return orderedEvents
  }, [events])
}
