import {
  ThemeConfig,
  Layout01ThemeCustomizations,
  Layout02ThemeCustomizations,
  Layout03ThemeCustomizations,
} from './index'

const Light: ThemeConfig = {
  colors: {
    main: '#007ACC',
  },
  page: {
    background: '#f8f8f8',
  },
  link: {},
  characteristicFont: {
    fontWeight: '700',
  },
  mainTrackingStatus: {
    textTransform: 'uppercase',
    fontSize: '34px',
    lineHeight: '1.176',
  },
  signatureRequired: {
    background: '#95E4FD',
    color: '#222222',
  },
  card: {
    background: 'white',
    border: '1px solid #DFE3E8',
    borderRadius: '2px',
  },
  recentTrackingDot: {
    backgroundColor: '#007ACC',
  },
  layoutSpecific: {
    layout01: {
      cardHeader: {
        shadow: '',
      },
    } as Layout01ThemeCustomizations,
    layout02: {
      cardHeader: {
        shadow: '',
      },
    } as Layout02ThemeCustomizations,
    layout03: {
      cardHeader: {
        shadow: '',
      },
    } as Layout03ThemeCustomizations,
  },
}

export default Light
