import React from 'react'

import { useShipmentData, useTrackingData } from '../dataProviders'

export function AbbreviatedDestinationAddress({ className = '' }) {
  const { events = [] } = useTrackingData()
  const { destination, store_address = {} } = useShipmentData()

  const sourceCountryCode = events.reduceRight(
    (country, e) => country || e.country_code || '',
    store_address.country_code || '',
  )

  const { city, state, postal_code, country_code } = destination

  if (!city && !state && !postal_code) {
    return null
  }

  const stateAndPostalCode = [state, postal_code].filter(i => i).join(' ')
  const country = sourceCountryCode && sourceCountryCode === country_code ? null : country_code

  return (
    <div className={`abbreviated-dest-addr ${className}`} data-testid="abbreviated-dest-addr">
      {[city, stateAndPostalCode, country].filter(i => i).join(', ')}
    </div>
  )
}
