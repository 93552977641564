import React, { useRef, useContext } from 'react'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import List from '@material-ui/core/List'
import Button from '@material-ui/core/Button'
import MuiAppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { useIntl } from 'react-intl'

import NavDrawerItem from './NavDrawerItem'
import { useBrandingData, usePreviewConfig, useFeatureEnables } from '../dataProviders'
import SocialMediaGrid from './SocialMediaGrid'
import NavLogo from './NavLogo'
import { ReactComponent as CloseSvg } from './Close.svg'
import { NavClasses } from './SharedAppBar'

const LinkList = ({
  classes = {},
}: {
  classes?: {
    list?: string
    item?: string
  }
}) => {
  const { showMenuLinks } = useFeatureEnables()
  const { menu_links = [] } = useBrandingData()
  const validItems = menu_links.filter(item => item && item.url && item.text)

  if (!showMenuLinks) {
    return null
  }

  return (
    <List className={classes.list}>
      {validItems.map(item => (
        <NavDrawerItem key={item.text} text={item.text} href={item.url} className={classes.item} />
      ))}
    </List>
  )
}

type NavDrawerContextType = {
  ref: React.RefObject<HTMLDivElement> | null
}

const NavDrawerContext = React.createContext<NavDrawerContextType>({ ref: null })
export const NavDrawerProvider = ({ children }: { children: React.ReactNode }) => {
  const containerRef = useRef<HTMLDivElement>(null)

  return (
    <NavDrawerContext.Provider value={{ ref: containerRef }}>
      {children}
      <div ref={containerRef} />
    </NavDrawerContext.Provider>
  )
}

export interface NavDrawerClasses {
  root?: string
  paper?: string
  listWrapper?: string
  list?: string
  item?: string
  socialMediaGrid?: string
  socialMediaLink?: string
  socialMediaLinkIcon?: string
}

export default function NavDrawer({
  toggleDrawer,
  open,
  classes = {},
  anchor = 'top',
  logoClasses = {},
  navClasses = {},
  children,
}: {
  toggleDrawer: (open: boolean) => any
  open: boolean
  classes: NavDrawerClasses
  anchor?: 'bottom' | 'left' | 'right' | 'top'
  logoClasses: {}
  navClasses: NavClasses
  children?: (params: { LinkList: () => React.ReactNode; SocialMediaGrid: () => React.ReactNode }) => React.ReactNode
}) {
  const intl = useIntl()
  const closeDrawer = toggleDrawer(false)
  const { isPreview } = usePreviewConfig()
  const drawerContext = useContext(NavDrawerContext)

  const WrappedLinkList = (props: any = {}) => <LinkList classes={classes} {...props} />
  const WrappedSocialMediaGrid = (props: any = {}) => (
    <SocialMediaGrid
      classes={{
        root: classes.socialMediaGrid,
        link: classes.socialMediaLink,
        linkIcon: classes.socialMediaLinkIcon,
      }}
      {...props}
    />
  )
  const mobileNavAriaLabel = intl.formatMessage({
    description: 'mobileNavAriaLabel',
    defaultMessage: 'Mobile Navigation',
  })
  const closeMobileNavAriaLabel = intl.formatMessage({
    description: 'closeMobileNavAriaLabel',
    defaultMessage: 'Close Navigation',
  })

  return (
    <div className={classes.root}>
      <SwipeableDrawer
        anchor={anchor}
        open={open}
        onClose={closeDrawer}
        onOpen={closeDrawer}
        classes={{ paper: classes.paper }}
        transitionDuration={350}
        ModalProps={{
          disableAutoFocus: isPreview,
          disableEnforceFocus: isPreview,
          container: drawerContext?.ref?.current,
        }}>
        <div
          className={classes.listWrapper}
          role="presentation"
          onClick={closeDrawer}
          onKeyDown={closeDrawer}
          aria-label={mobileNavAriaLabel}>
          {children ? (
            children({
              LinkList: WrappedLinkList,
              SocialMediaGrid: WrappedSocialMediaGrid,
            })
          ) : (
            <>
              <MuiAppBar position="static" classes={{ root: navClasses.root }}>
                <Toolbar classes={{ root: navClasses.toolbar }}>
                  <Button
                    onClick={toggleDrawer(true)}
                    className={navClasses.menuLink}
                    aria-label={closeMobileNavAriaLabel}>
                    <CloseSvg />
                  </Button>
                  <NavLogo classes={logoClasses} />
                </Toolbar>
              </MuiAppBar>
              <WrappedLinkList />
              <WrappedSocialMediaGrid />
            </>
          )}
        </div>
      </SwipeableDrawer>
    </div>
  )
}
