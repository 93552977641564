import React from 'react'

import { makeBtStyles } from '../Theme'
import { MIN_TABLET_SIZE, MIN_DESKTOP_SIZE } from '../utils'
import SharedAppBar from '../components/SharedAppBar'
import { MOBILE_CONTENT_WIDTH } from '../components/defaultStyleVars'

const useLogoStyles = makeBtStyles(
  theme => ({
    textLogo: {
      textAlign: 'left',
      display: 'inline-block',
      marginLeft: 0,
      fontSize: 20,
      fontWeight: '500',
      lineHeight: '24px',
      color: '#222222',
      [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
        textAlign: 'left',
      },
      [theme.breakpoints.up('xs')]: {
        textAlign: 'center',
      },
    },
    root: {
      textAlign: 'left',
      display: 'inline-block',
      verticalAlign: 'middle',
      marginLeft: 0,
      marginRight: '5px',
      [theme.breakpoints.down(MIN_DESKTOP_SIZE)]: {
        margin: '0 auto',
      },
    },
    image: {
      maxWidth: '150px',
      [theme.breakpoints.down(MIN_DESKTOP_SIZE)]: {
        maxHeight: '50px',
      },
    },
  }),
  { name: 'LogoStyles' },
)

const useNavStyles = makeBtStyles(
  theme => ({
    root: {
      boxShadow: 'none',
      backgroundColor: 'white',
      border: '1px solid rgba(0, 0, 0, 0.12)',
      verticalAlign: 'middle',
      padding: '20px 0 0 10px',
      height: '100px',
      [theme.breakpoints.up(MIN_DESKTOP_SIZE)]: {
        height: '500px',
      },
    },
    toolbar: () => ({
      [theme.breakpoints.up(MIN_DESKTOP_SIZE)]: {
        minHeight: '400px',
        width: '100%',
        display: 'flex',
      },
      maxHeight: '64px',
      backgroundColor: 'white',
      width: MOBILE_CONTENT_WIDTH,
      padding: 0,
      margin: '0 auto',
      flexWrap: 'wrap',
      [theme.breakpoints.up(MIN_TABLET_SIZE)]: {
        width: '800px',
        maxWidth: '98%',
      },
    }),
    paper: {},
    menuLink: {
      position: 'absolute',
      marginLeft: '-19px',
      '& svg': {
        transform: 'scale(1.6)',
        fill: theme.link.color,
      },
    },
    link: {
      fontSize: '14px',
      verticalAlign: 'middle',
      marginLeft: '15px',
      textDecoration: 'none',
      fontWeight: '500',
    },
    socialMediaGrid: {
      display: 'flex',
    },
    socialMediaLink: {
      padding: '0 5px',
      fontSize: '18px',
      marginLeft: 'auto',
      '&:last-child': {
        paddingRight: '0',
      },
    },
    socialMediaLinkIcon: {
      verticalAlign: 'middle',
    },
    linkContainer: {
      position: 'absolute',
      right: '20px',
      top: '30%',
    },
  }),
  { name: 'NavStyles' },
)

const useNavDrawerStyles = makeBtStyles(
  theme => ({
    paper: {
      minWidth: '100%',
      bottom: '0',
      backgroundColor: 'white',
    },
    list: {
      width: '100%',
      margin: 'auto',
      padding: '0',
    },
    item: {
      padding: '10px 52px 5px',
      [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
        textAlign: 'center',
        padding: '20px 52px 5px',
      },
    },
    socialMediaGrid: {
      width: MOBILE_CONTENT_WIDTH,
      margin: '16px auto 0',
      display: 'flex',
      justifyContent: 'center',
    },
    socialMediaLink: {
      padding: '12px',
    },
    socialMediaLinkIcon: {
      fontSize: '1.25rem',
    },
  }),
  { name: 'DrawerStyles' },
)

const useStyles = makeBtStyles(theme => ({
  desktopMenuClasses: {
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      display: 'none',
    },
  },
  draweMenuClasses: {
    [theme.breakpoints.up(MIN_TABLET_SIZE)]: {
      display: 'none',
    },
  },
}))

export default function AppBar({ className }: { className: string }) {
  const classes = useStyles()
  const logoClasses = useLogoStyles()
  const navClasses = useNavStyles()
  const navDrawerClasses = useNavDrawerStyles()

  return (
    <div className={className}>
      <SharedAppBar
        classes={classes}
        logoClasses={logoClasses}
        navClasses={navClasses}
        navDrawerClasses={navDrawerClasses}
        showSocialMedia={false}
      />
    </div>
  )
}

AppBar.propTypes = {}
