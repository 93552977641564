import React from 'react'
import { ReactComponent as DeliveredCheckMarkSvg } from './DeliveredCheckMark.svg'

import { makeBtStyles } from '../Theme'

const useStyles = makeBtStyles(theme => ({
  deliveredCheck: {
    height: '30px',
    marginLeft: '8px',
    display: 'inline-block',
    verticalAlign: 'baseline',
    marginBottom: '-1px',
    stroke: `${theme.colors.characteristic ? theme.colors.characteristic : theme.colors.main}`,
    '& .delivered-check_circle, .delivered-check_check': {
      strokeDasharray: '1000',
      strokeDashoffset: '0',
    },
    '& .delivered-check_circle': {
      animation: '$deliveredCheck .9s ease-in-out',
    },
    '& .delivered-check_check': {
      strokeDashoffset: '-100',
      animation: '$deliveredCheck-check .9s .35s ease-in-out forwards',
    },
  },
  '@keyframes deliveredCheck': {
    '0%': {
      strokeDashoffset: '1000',
    },
    '100%': {
      strokeDashoffset: '0',
    },
  },
  '@keyframes deliveredCheck-check': {
    '0%': {
      strokeDashoffset: '-30',
    },
    '100%': {
      strokeDashoffset: '300',
    },
  },
}))

export default function DeliveredCheckMark({ className }: { className?: string }) {
  const classes = useStyles()
  return <DeliveredCheckMarkSvg className={`${classes.deliveredCheck} ${className}`} />
}
