import React from 'react'

import { makeBtStyles } from '../Theme'
import { isGiftWrapped } from '../components/GiftWrapPanel'
import { MIN_TABLET_SIZE, MIN_DESKTOP_SIZE } from '../utils'

import { useIsLineItemsCardShown } from './LineItemsCard'
import TabbedShipmentActivityContent from './TabbedShipmentActivityContent'
import ShipmentActivityContent from './ShipmentActivityContent'

const useStyles = makeBtStyles(theme => ({
  root: ({ isTabbed }: { isTabbed: boolean }) => ({
    [theme.breakpoints.up(MIN_DESKTOP_SIZE)]: {
      display: isTabbed ? 'none' : '',
    },
    [theme.breakpoints.down(MIN_DESKTOP_SIZE)]: {
      display: isTabbed ? '' : 'none',
    },
  }),
  lineItemsCard: {
    border: 'none',
    margin: '10px 0 0 20px',
    boxShadow: 'none',
    [theme.breakpoints.up(MIN_DESKTOP_SIZE)]: {
      maxHeight: '90%',
    },
    [theme.breakpoints.up(MIN_TABLET_SIZE)]: {
      margin: '10px 0 0 20px',
      width: '100%',
    },
    [theme.breakpoints.between(MIN_TABLET_SIZE, MIN_DESKTOP_SIZE)]: {
      height: '330px',
    },
    [theme.breakpoints.down(MIN_DESKTOP_SIZE)]: {
      margin: '10px 0 0 0px',
    },
  },
  shipmentActivity: {
    justifyContent: 'space-between',
    padding: '20px 20px 10px 20px',
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.up(MIN_DESKTOP_SIZE)]: {
      padding: '10px 20px 0px 20px',
      // height: '360px',
    },
    [theme.breakpoints.between(MIN_TABLET_SIZE, MIN_DESKTOP_SIZE)]: {
      height: '370px',
    },
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      border: '1px solid #DFE3E8',
      borderTop: 'none',
      background: '#FFFFFF',
      borderBottomLeftRadius: '10px',
      borderBottomRightRadius: '10px',
      margin: '15px auto',
      marginTop: '0',
      width: '95% !important',
      maxWidth: '625px',
    },
  },
  scrollableSection: {
    overflow: 'auto',
    width: '100%',
    [theme.breakpoints.up(MIN_DESKTOP_SIZE)]: {
      height: '90%',
    },
    [theme.breakpoints.down(MIN_DESKTOP_SIZE)]: {
      height: '100%',
    },
  },
  marker: {
    position: 'absolute',
    '& path': {
      fill: `${theme.colors.characteristic ? theme.colors.characteristic : theme.colors.main}`,
    },
    left: '10px',
    marginTop: '5px',
    fontSize: '25px',
  },
  eventRow: () => {
    return {
      paddingLeft: '38px',
      position: 'relative',
      color: '#929292',
      '&::before': {
        content: '""',
        width: '4px',
        backgroundImage: 'radial-gradient(circle, #D8D8D8 2px, transparent 2px)',
        bottom: '0px',
        height: '100%',
        backgroundSize: '100% 20px',
        position: 'absolute',
        left: '17px',
      },
      '&.mostRecentEvent': {
        color: 'inherit',
        '&::before': {
          content: '""',
          backgroundImage: 'none',
        },
        '&::after': {
          content: '""',
          width: '4px',
          backgroundImage: 'radial-gradient(circle, #D8D8D8 2px, transparent 2px)',
          backgroundSize: '100% 30px',
          position: 'absolute',
          left: '17px',
          [theme.breakpoints.up(MIN_DESKTOP_SIZE)]: {
            height: '30%',
            bottom: '0px',
          },
          [theme.breakpoints.down(MIN_DESKTOP_SIZE)]: {
            height: '0%',
            bottom: '0px',
          },
        },
      },
      '&:last-child::before': {
        content: '""',
        width: '4px',
        height: '75%',
        backgroundImage: 'radial-gradient(circle, #D8D8D8 2px, transparent 2px)',
        backgroundSize: '100% 20px',
        maskImage: 'linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1))',
        position: 'absolute',
        left: '17px',
        bottom: '13px',
      },
    }
  },
  shipmentTabs: {
    display: 'flex',
    background: '#FFFFFF',
    minHeight: '40px',
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      margin: '15px auto',
      marginBottom: '0',
      borderTopRightRadius: '10px',
      borderTopLeftRadius: '10px',
      width: '95% !important',
      maxWidth: '625px',
    },
  },
  tab: ({ lineItemsCardShown }: { lineItemsCardShown: boolean }) => ({
    fontSize: '14px',
    fontWeight: '500',
    color: '#000000',
    width: '50%',
    textDecoration: 'none !important',
    [theme.breakpoints.up(MIN_DESKTOP_SIZE)]: {
      textAlign: 'left',
      padding: '20px 0 0 40px',
    },
    [theme.breakpoints.up(MIN_TABLET_SIZE)]: {
      borderTop: 'none !important',
      border: lineItemsCardShown ? '' : 'none !important',
    },
    [theme.breakpoints.down(MIN_DESKTOP_SIZE)]: {
      padding: lineItemsCardShown ? '10px' : '20px 0 0 40px',
      border: '1px solid #DDDDDD',
      borderBottom: lineItemsCardShown ? '' : 'none',
      width: lineItemsCardShown ? '50%' : '100%',
      textAlign: lineItemsCardShown ? 'center' : 'left',
    },
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      borderTopRightRadius: lineItemsCardShown ? '' : '10px',
    },
    padding: '10px',
  }),
  activeTab: {
    borderBottom: 'none !important',
  },
  inactiveTab: {
    background: '#F6F5F5',
  },
  leftTab: ({ lineItemsCardShown }: { lineItemsCardShown: boolean }) => ({
    [theme.breakpoints.up(MIN_TABLET_SIZE)]: {
      borderLeft: 'none !important',
    },
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      borderTopLeftRadius: '10px',
    },
    borderRight: lineItemsCardShown ? 'none !important' : '',
  }),
  rightTab: {
    [theme.breakpoints.up(MIN_DESKTOP_SIZE)]: {
      marginLeft: '-30px',
    },
    [theme.breakpoints.up(MIN_TABLET_SIZE)]: {
      borderRight: 'none !important',
    },
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      borderTopRightRadius: '10px',
    },
  },
}))

const useTrackingStyles = makeBtStyles(theme => ({
  event: {
    [theme.breakpoints.up(MIN_DESKTOP_SIZE)]: {
      minHeight: '40px',
    },
    '& .timestamp': {
      fontSize: '12px',
      fontWeight: '700',
      lineHeight: '12px',
      display: 'block',
      textTransform: 'uppercase',
      marginBottom: '2px',
    },
    '& .description, & .location': {
      fontSize: '14px',
      fontWeight: '300',
      lineHeight: '14px',
      letterSpacing: '0',
    },
    '& .location': {
      marginLeft: '3px',
    },
  },
  emptyMsg: {
    padding: '15px 20px',
    fontStyle: 'italic',
    color: '#929292',
  },
  collapseToggle: {
    [theme.breakpoints.up(MIN_TABLET_SIZE)]: {
      position: 'relative',
      bottom: '-16px',
    },
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      padding: '10px 20px',
    },
    padding: '0px 20px',
    fontSize: '14px',
    textTransform: 'lowercase',
    width: '100%',
  },
  showMore: {
    display: 'inline-block',
  },
}))

const useLineItemStyles = makeBtStyles(theme => ({
  item: {
    minHeight: '60px',
    marginBottom: '18px',
    '&.item--no-image': {
      minHeight: '48px',
      '& $name, & $quantity, & $options': {
        marginLeft: '20px',
      },
    },
  },
  image: {
    float: 'left',
    marginRight: '10px',
    width: '56px',
    height: '56px',
    border: '1px solid #DFE3E8',
    display: 'flex',
    '& img': {
      alignSelf: 'center',
      margin: '0 auto',
    },
  },
  name: {
    marginLeft: '66px',
    paddingRight: '20px',
    fontSize: '14px',
    lineHeight: '18px',
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
  },
  description: {
    marginLeft: '66px',
    paddingRight: '20px',
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: '300',
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-line-clamp': '1',
    '-webkit-box-orient': 'vertical',
  },
  options: {
    marginLeft: '66px',
    paddingRight: '20px',
    fontSize: '12px',
    fontWeight: '300',
    lineHeight: '18px',
    listStyleType: 'none',
    padding: '0',
    marginTop: '0',
    marginBottom: '0',
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-line-clamp': '1',
    '-webkit-box-orient': 'vertical',
  },
  optionItem: {
    display: 'inline',
    '&:after': {
      content: '", "',
    },
    '&:last-child:after': {
      display: 'none',
    },
  },
  optionLabel: {
    fontWeight: 'bold',
    textTransform: 'capitalize',
    display: 'none',
  },
  quantity: {
    display: 'table',
    marginLeft: '66px',
    fontSize: '12px',
    fontWeight: '300',
    lineHeight: '18px',
  },
  unitPrice: {
    display: 'table',
    marginLeft: '66px',
    fontSize: '12px',
    fontWeight: '300',
    lineHeight: '18px',
  },
  subtotal: {
    display: 'none',
  },
  collapseToggle: {
    [theme.breakpoints.up(MIN_TABLET_SIZE)]: {
      position: 'relative',
      bottom: '-4px',
    },
    fontSize: '14px',
    textTransform: 'lowercase',
    width: '100%',
  },
}))

const useLineItemCardStyles = makeBtStyles(theme => ({
  contentLabel: {
    fontSize: '12px',
    display: 'block',
  },
  scrollableSection: {
    overflow: 'auto',
    [theme.breakpoints.up(MIN_DESKTOP_SIZE)]: {
      maxHeight: '97% !important',
    },
    [theme.breakpoints.up(MIN_TABLET_SIZE)]: {
      maxHeight: '330px',
    },
  },
  orderNumberSection: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '12px',
  },
  orderNumber: {
    fontSize: '12px',
    fontWeight: '300',
    marginLeft: '5px',
  },
}))

export default function ShipmentActivity({ className }: { className: string }) {
  const lineItemsCardShown = useIsLineItemsCardShown()
  const isGiftWrappedBool = isGiftWrapped ? true : false

  const classes = useStyles({ lineItemsCardShown, isTabbed: false })
  const tabbedClasses = useStyles({ lineItemsCardShown, isTabbed: true })

  const trackingClasses = useTrackingStyles()
  const lineItemClasses = useLineItemStyles()
  const lineItemCardClasses = useLineItemCardStyles()

  return (
    <div className={className}>
      <ShipmentActivityContent
        classes={classes}
        trackingClasses={trackingClasses}
        lineItemClasses={lineItemClasses}
        lineItemCardClasses={lineItemCardClasses}
        isGiftWrapped={isGiftWrappedBool}
        lineItemsCardShown={lineItemsCardShown}
      />
      <TabbedShipmentActivityContent
        classes={tabbedClasses}
        trackingClasses={trackingClasses}
        lineItemClasses={lineItemClasses}
        lineItemCardClasses={lineItemCardClasses}
        isGiftWrapped={isGiftWrappedBool}
        lineItemsCardShown={lineItemsCardShown}
      />
    </div>
  )
}
