// @ts-nocheck
import React from 'react'
import * as Sentry from '@sentry/browser'
import { IntlProvider } from 'react-intl'
import '@formatjs/intl-locale/polyfill' // Needed for many newest browsers so may as well include in bundle
import '@formatjs/intl-getcanonicallocales/polyfill' // Needed for many newest browsers so may as well include in bundle
import { shouldPolyfill as shouldPolyfillPluralRules } from '@formatjs/intl-pluralrules/should-polyfill'

export const DEFAULT_LOCALE = 'en'

async function polyfillFormatToParts() {
  if (!Intl.DateTimeFormat.prototype.formatToParts) {
    await import('./polyfills/formatToParts') // Only works properly for en-us right now but better than erroring
  }
}

async function polyfillPluralRules() {
  if (shouldPolyfillPluralRules()) {
    await import('@formatjs/intl-pluralrules/polyfill')
  }
}

const initialPolyfillLoad = Promise.all([polyfillFormatToParts(), polyfillPluralRules()]).catch(error => {
  Sentry.captureException(error)
  console.error && console.error(`Failed to load initial locale polyfills: ${error}`)
})

async function finishLoadingPolyfills(normalizedLocale) {
  await initialPolyfillLoad

  if (Intl.PluralRules.polyfilled) {
    switch (normalizedLocale) {
      case 'fr':
      case 'fr-ca':
        await import('@formatjs/intl-pluralrules/locale-data/fr')
        break
      case 'es':
        await import('@formatjs/intl-pluralrules/locale-data/es')
        break
      case 'de':
        await import('@formatjs/intl-pluralrules/locale-data/de')
        break
      default:
        await import('@formatjs/intl-pluralrules/locale-data/en')
    }
  }
}

async function loadLocaleData(locale: string, isRetry?: boolean): Promise<object> {
  try {
    const normalizedLocale = locale.toLocaleLowerCase()

    switch (normalizedLocale) {
      case 'en-xa':
        return await import('./locales/compiled/en-XA.json')
      case 'fr-ca':
        return await import('./locales/compiled/fr-CA.json')
      case 'fr':
        return await import('./locales/compiled/fr.json')
      case 'es':
        return await import('./locales/compiled/es.json')
      case 'de':
        return await import('./locales/compiled/de.json')
      default:
        return await import('./locales/compiled/en.json')
    }
  } catch (error) {
    if (isRetry) {
      console.error && console.error(`Failed to load translation for locale '${locale}'`)
      Sentry.captureException(error)
      return {} // Just in case something goes wrong, this will ensure we render w/ the English defaults
    } else {
      console.warn && console.warn(`Retrying translation load for locale '${locale}'`)
      Sentry.captureMessage(`Retrying translation load for locale '${locale}'`)
      return await loadLocaleData(locale, true)
    }
  }
}

export class LocaleProvider extends React.Component<{ locale: string }> {
  state = {
    locale: this.props.locale,
    messages: {},
    failed: false,
  }

  loadMessages() {
    const { locale } = this.state

    if (!locale) {
      return
    }

    const polyfillLoad = finishLoadingPolyfills(locale).catch(error => {
      Sentry.captureException(error)
      console.error && console.error('Error loading late locale polyfills: ', error)
    })

    Promise.all([polyfillLoad, loadLocaleData(locale)])
      .then(([_, localeData]) => {
        this.setState({ messages: localeData, failed: false })
      })
      .catch(error => {
        this.setState({ messages: {}, failed: false }) // Just in case something goes wrong, this will ensure we render w/ the English defaults
        Sentry.captureException(error)
        console.error && console.error('Error loading translation data: ', error)
      })
  }

  componentDidMount = () => this.loadMessages()
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.locale !== this.props.locale) {
      this.setState({ locale: this.props.locale, messages: {} })
    }
    if (prevState.locale !== this.state.locale) {
      this.loadMessages()
    }
  }

  componentDidCatch(error) {
    Sentry.captureException(error)
    console.error(error)

    if (!this.state.failed) {
      this.setState({ locale: DEFAULT_LOCALE, messages: {}, failed: true })
    }
  }

  render() {
    const { messages, locale } = this.state

    if (!locale || !messages) {
      return null
    }

    return (
      <IntlProvider messages={messages} locale={locale} defaultLocale={DEFAULT_LOCALE}>
        {this.props.children}
      </IntlProvider>
    )
  }
}

export function getLocale(shipment = null) {
  const url = new URL(window.location.href)
  const localeFromUrl = url.searchParams.get('locale')

  if (localeFromUrl) {
    return localeFromUrl
  }

  if (shipment && shipment.locale) {
    try {
      return Intl.getCanonicalLocales(shipment.locale)[0]
    } catch (_) {
      return DEFAULT_LOCALE
    }
  } else {
    return DEFAULT_LOCALE
  }
}
