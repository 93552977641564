import {
  ThemeConfig,
  Layout01ThemeCustomizations,
  Layout02ThemeCustomizations,
  Layout03ThemeCustomizations,
} from './index'

const Paparazzi: ThemeConfig = {
  colors: {
    main: '#32267D',
  },
  page: {
    background: 'radial-gradient(#FCAADD, #EC008C)',
  },
  link: {
    color: "#EC008C"
  },
  characteristicFont: {
    fontWeight: '700',
  },
  mainTrackingStatus: {
    textTransform: 'uppercase',
    fontSize: '34px',
    lineHeight: '1.176',
  },
  signatureRequired: {
    background: '#EC008C',
    color: 'white',
  },
  card: {
    background: 'white',
  },
  recentTrackingDot: {
    backgroundColor: '#EC008C',
  },
  layoutSpecific: {
    layout01: {
      cardHeader: {
        color: '#32267D',
      },
    } as Layout01ThemeCustomizations,
    layout02: {
      cardHeader: {
        color: '#32267D',
      },
    } as Layout02ThemeCustomizations,
    layout03: {
      cardHeader: {
        color: '#32267D',
      },
    } as Layout03ThemeCustomizations,
  },
}

export default Paparazzi
