import React from 'react'
import moment, { Moment } from 'moment'
import { IntlShape, useIntl, FormattedMessage } from 'react-intl'
import * as Sentry from '@sentry/browser'

import { useTrackingData } from '../dataProviders'
import { makeBtStyles } from '../Theme'

const deliveryDateOrToday = ({ deliveryDate }: { deliveryDate: Moment }, intl: IntlShape) => {
  const isToday = deliveryDate.isSame(moment(), 'day')

  if (isToday) {
    const text = intl.formatMessage({
      description: 'estimatedDateOrStatus.deliveryEstimatedToday',
      defaultMessage: 'Today',
    })
    return { text }
  } else {
    return { date: deliveryDate.toISOString() }
  }
}

const parseEstimatedDeliveryDate = (estimated_delivery_date: string | null | undefined) => {
  if (!estimated_delivery_date) {
    return undefined
  }

  const parsedDate = moment(estimated_delivery_date)

  if (!parsedDate.isValid()) {
    Sentry.captureMessage(`Unparseable estimated delivery date '${estimated_delivery_date}'`)
    return undefined
  }

  return parsedDate
}

function useMessageAndStatus(
  source: string | null,
  status_code: string,
  estimated_delivery_date: string | undefined | null,
  hasAnyEvents: boolean,
) {
  const intl = useIntl()

  const deliveryDate = parseEstimatedDeliveryDate(estimated_delivery_date)

  const defaultStatusText = intl.formatMessage({
    description: 'estimatedDateOrStatus.defaultStatus',
    defaultMessage: 'Almost Ready',
  })

  const defaultStatus = deliveryDate
    ? { ...deliveryDateOrToday({ deliveryDate }, intl), status: 'isReadyToGo' }
    : { text: defaultStatusText, status: 'isReadyToGo' }

  if (!source || source.toLowerCase() !== 'shipengine') {
    return defaultStatus
  }

  status_code = status_code.toUpperCase()

  switch (status_code) {
    case 'AC': {
      if (deliveryDate) {
        return { ...deliveryDateOrToday({ deliveryDate }, intl), status: 'isAccepted' }
      }
      return { text: defaultStatusText, status: 'isAccepted' }
    }
    case 'IT': {
      if (deliveryDate) {
        return { ...deliveryDateOrToday({ deliveryDate }, intl), status: 'isInTransit' }
      }
      const text = intl.formatMessage({
        description: 'estimatedDateOrStatus.inTransitButNoDate',
        defaultMessage: 'In Transit',
      })
      return { text, status: 'isInTransit' }
    }
    case 'DE':
      const text = intl.formatMessage({
        description: 'estimatedDateOrStatus.delivered',
        defaultMessage: 'Delivered',
      })
      return { text, status: 'isDelivered' }
    case 'EX':
    case 'UN':
      if (deliveryDate) {
        return { ...deliveryDateOrToday({ deliveryDate }, intl), status: 'isInTransit' }
      } else if (hasAnyEvents) {
        const text = intl.formatMessage({
          description: 'estimatedDateOrStatus.exceptionOrUnknownNoDateHasEvents',
          defaultMessage: 'In Transit',
        })
        return { text, status: 'isInTransit' }
      } else {
        const text = intl.formatMessage({
          description: 'estimatedDateOrStatus.exceptionOrUnknownNoDateNoEvents',
          defaultMessage: 'Status Unavailable',
        })
        return { text, status: 'isUnknown' }
      }
    case 'AT': {
      const text = intl.formatMessage({
        description: 'estimatedDateOrStatus.attemptedDelivery',
        defaultMessage: 'We Missed You',
      })
      return { text, status: 'isDeliveryAttempted' }
    }
    case 'NY':
      return defaultStatus
    default: {
      Sentry.captureMessage(`Unknown tracking status_code: '${status_code}'`)
      return defaultStatus
    }
  }
}

const useSkeletonStyles = makeBtStyles({
  skeleton: {
    background: 'rgba(0,0,0,8%)',
    color: 'transparent',
  },
})

export default function EstimatedDeliveryDateOrStatus({
  className = '',
  formatDate,
  children,
}: {
  className?: string
  formatDate?: (date: string) => React.ReactNode
  children?: ({
    date,
    text,
    status,
    formattedStatusOrDate,
  }: {
    date?: string
    text?: string
    status: string
    formattedStatusOrDate?: React.ReactNode
    trackingLoaded: boolean
  }) => React.ReactNode
}) {
  const intl = useIntl()
  const {
    source = null,
    status_code = '',
    estimated_delivery_date = null,
    events = [],
    loaded: trackingLoaded,
  } = useTrackingData()
  const skeletonClasses = useSkeletonStyles()

  const { date, text, status }: { date?: string; text?: string; status: string } = useMessageAndStatus(
    source,
    status_code,
    estimated_delivery_date,
    !!events.length,
  )

  formatDate =
    formatDate !== undefined
      ? formatDate
      : (date: string) => {
          const dayOfWeek = date && intl.formatDate(date, { weekday: 'long' })
          const monthName = date && intl.formatDate(date, { month: 'long' })
          const dayOfMonth = date && intl.formatDate(date, { day: 'numeric' })

          return (
            <FormattedMessage
              description="formattedEstimatedDeliveryDate"
              defaultMessage="<row>{dayOfWeek}, </row><row>{monthName} {dayOfMonth}</row>"
              values={{ dayOfWeek, monthName, dayOfMonth, row: (chunks: any) => <span>{chunks}</span> }}
            />
          )
        }

  const formattedStatusOrDate = trackingLoaded ? (
    date ? (
      formatDate(date)
    ) : (
      text
    )
  ) : (
    <span className={skeletonClasses.skeleton}>&nbsp;&nbsp;Loading&nbsp;&nbsp;</span>
  )

  const title =
    date &&
    intl.formatMessage(
      {
        description: 'estimatedDeliveryDateHoverTitle',
        defaultMessage: 'Estimated delivery: {date}',
      },
      { date: intl.formatDate(date, { weekday: 'long', month: 'long', year: 'numeric', day: 'numeric' }) },
    )

  return (
    <div className={`${className} ${status}`} data-testid="estimated-delivery-date-or-status" title={title}>
      {children ? children({ date, text, status, formattedStatusOrDate, trackingLoaded }) : formattedStatusOrDate}
    </div>
  )
}
