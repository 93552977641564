import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import FriendlyStatusMessage from '../components/FriendlyStatusMessage'
import LastTrackingEventLocation from '../components/LastTrackingEventLocation'
import { AbbreviatedDestinationAddress } from '../components/DestinationAddress'
import TrackingNumberLink from '../components/TrackingNumberLink'
import CarrierIcon from '../components/CarrierIcon'
import CarbonNeutral from '../components/CarbonNeutral'
import EstimatedDeliveryDateOrStatus from '../components/EstimatedDeliveryDateOrStatus'
import SignatureRequiredTag from '../components/SignatureRequiredTag'

import Card, { CardHeader, CardSection } from './Card'
import ContactSection from './ContactSection'
import DeliveredCheckMark from '../components/DeliveredCheckMark'
import { makeBtStyles } from '../Theme'

const useStyles = makeBtStyles(theme => ({
  contentLabel: {
    fontSize: '10px',
    fontWeight: '700',
    lineHeight: '12px',
    textTransform: 'uppercase',
    display: 'block',
    marginBottom: '2px',
  },
  dateOrStatus: {
    ...theme.characteristicFont,
    color: `${theme.colors.characteristic ? theme.colors.characteristic : theme.colors.main}`,
    ...theme.mainTrackingStatus,
    '&:not(.isDelivered):not(.isInTransit):not(.estimatedDeliveryDate)': {
      color: '#929292',
      fontWeight: 'normal',
    },
    marginBottom: '8px',
    '& .date-or-status__date': {
      whiteSpace: 'nowrap',
    },
  },
  textStatus: {
    display: 'inline-block',
    verticalAlign: 'baseline',
    '& > *': {
      display: 'block',
    },
  },
  friendlyStatus: {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '20px',
  },
  currentLocation: {
    marginBottom: '6px',
    display: 'block',
    fontWeight: '300',
  },
  destination: {
    fontWeight: '300',
  },
  trackingNumberSection: {
    borderBottom: 'none',
  },
  carrierIcon: {
    height: '35px',
    opacity: '0.5',
    filter: 'grayscale(1)',
    width: 'auto !important',
    paddingRight: '8px',
    '&.carrierIconSvg': {
      maxHeight: '14px',
    },
    '&.carrierIconText': {
      fontWeight: '300',
    },
  },
  trackingNumber: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: '8px',
    '& > *': {
      display: 'inline-block',
      verticalAlign: 'middle',
    },
  },
  contactSection: {
    padding: '12px 20px',
    borderTop: '1px solid #DFE3E8',
    height: '145px',
    position: 'absolute',
    bottom: '0',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))

export default function MainCard({ className }: { className?: string }) {
  const [checkShown, setCheckShown] = useState(false)
  const classes = useStyles()

  useEffect(() => {
    setTimeout(() => setCheckShown(true), 2000)
  }, [])

  return (
    <Card className={className} aria-labelledby="main-card-header">
      <CardHeader id="main-card-header">
        <FormattedMessage description="layout01.mainCard.heading" defaultMessage="Estimated Delivery" />
      </CardHeader>

      <CardSection>
        <EstimatedDeliveryDateOrStatus className={classes.dateOrStatus}>
          {({ formattedStatusOrDate, status }) => (
            <div>
              <span className={classes.textStatus}>{formattedStatusOrDate}</span>
              {checkShown && status === 'isDelivered' && (
                <>
                  <DeliveredCheckMark />
                </>
              )}
            </div>
          )}
        </EstimatedDeliveryDateOrStatus>
        <FriendlyStatusMessage className={classes.friendlyStatus} />
      </CardSection>

      <CardSection>
        <label className={classes.contentLabel}>
          <FormattedMessage description="layout01.mainCard.currentLocationLabel" defaultMessage="Current Location" />:
        </label>
        <LastTrackingEventLocation defaultText="---" className={classes.currentLocation} />

        <label className={classes.contentLabel}>
          <FormattedMessage description="layout01.mainCard.destinationLabel" defaultMessage="Destination" />:
        </label>
        <AbbreviatedDestinationAddress className={classes.destination} />
      </CardSection>

      <CardSection className={classes.trackingNumberSection}>
        <label className={classes.contentLabel}>
          <FormattedMessage description="layout01.mainCard.trackingNumberLabel" defaultMessage="Carrier Tracking" />:
        </label>
        <div className={classes.trackingNumber}>
          <CarrierIcon className={classes.carrierIcon} fallbackToName />
          <TrackingNumberLink />
        </div>
        <SignatureRequiredTag />
        <CarbonNeutral isFlex />
      </CardSection>

      <ContactSection className={classes.contactSection} />
    </Card>
  )
}
