import React from 'react'

import { NavDrawerClasses } from './NavDrawer'
import MenuLinksNavBar from './MenuLinksNavBar'
import DrawerNavBar from './DrawerNavBar'

export interface NavClasses {
  root?: string
  toolbar?: string
  paper?: string
  menuLink?: string
  link?: string
  linkContainer?: string
  socialMediaGrid?: string
  socialMediaLink?: string
  socialMediaLinkIcon?: string
}

export interface ToggleClasses {
  icon?: string
  showMoreSection?: string
  dropDownClassName?: string
}

export interface NavLogoClasses {
  textLogo?: string
  root?: string
  image?: string
}

export interface RootClasses {
  desktopMenuClasses: string
  draweMenuClasses: string
}

export default function SharedAppBar({
  classes,
  navClasses = {},
  navDrawerClasses,
  logoClasses,
  showSocialMedia,
}: {
  classes: RootClasses
  navClasses: NavClasses
  navDrawerClasses: NavDrawerClasses
  logoClasses: NavLogoClasses
  showSocialMedia: Boolean
}) {
  return (
    <>
      <MenuLinksNavBar
        className={classes?.desktopMenuClasses}
        logoClasses={logoClasses}
        navClasses={navClasses}
        showSocialMedia={showSocialMedia}
      />
      <DrawerNavBar
        className={classes?.draweMenuClasses}
        logoClasses={logoClasses}
        navClasses={navClasses}
        navDrawerClasses={navDrawerClasses}
      />
    </>
  )
}
