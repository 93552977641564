import React from 'react'
import classNames from 'classnames'

import { makeBtStyles } from '../../Theme'
import { MIN_DESKTOP_SIZE, MIN_TABLET_SIZE } from '../../utils'

import { ReactComponent as CheckedIcon } from './stepperSvg/stepperCheckmark.svg'
import { ReactComponent as MissedIcon } from './stepperSvg/stepperX.svg'

const useStepperStyles = makeBtStyles(theme => ({
  activeCheck: {
    '& path': {
      fill: 'white',
    },
  },
  inactiveCheck: {
    '& path': {
      fill: '#DEDEDE',
    },
  },
  circle: {
    zIndex: '1',
    lineHeight: '1em',
    borderRadius: '50%',
    border: '2px solid transparent',
  },
  activeCircle: {
    backgroundColor: theme.colors.main,
  },
  inactiveCircle: {
    backgroundColor: '#DEDEDE',
  },
  text: {
    textAlign: 'center',
  },
  activeText: {},
  inactiveText: {
    opacity: '40%',
  },
  stepBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },
  dividerLine: {
    zIndex: '0',
    position: 'absolute',
    width: '100%',
  },
  activeDivider: {
    backgroundColor: theme.colors.main,
  },
  inactiveDivider: {
    backgroundColor: '#DEDEDE',
  },
  activeIcon: {
    '& path': {
      fill: theme.colors.main,
    },
  },
  inactiveIcon: {
    '& path': {
      opacity: '40%',
    },
  },
  mobileIcon: {
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      display: 'none',
    },
  },
  mobileCurrentIcon: {
    [theme.breakpoints.up(MIN_TABLET_SIZE)]: {
      display: 'none',
    },
  },
  desktopLabel: {
    [theme.breakpoints.down(MIN_DESKTOP_SIZE)]: {
      display: 'none',
    },
  },
  currentTabletLabel: {
    [theme.breakpoints.up(MIN_DESKTOP_SIZE)]: {
      display: 'none',
    },
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      display: 'none',
    },
  },
}))

export default function Step({
  classes = {},
  label,
  Icon,
  active,
  index,
  current,
  showMiss,
}: {
  classes?: {
    circle?: string
    check?: string
    missed?: string
    dividerLine?: string
    stepBlock?: string
    text?: string
    icon?: string
  }
  label: string
  Icon: any
  active: boolean
  index: number
  current: boolean
  showMiss?: boolean
}) {
  const defaultClasses = useStepperStyles()
  const icon = (
    <Icon
      className={classNames(classes.icon, {
        [defaultClasses.activeIcon]: active,
        [defaultClasses.inactiveIcon]: !active,
      })}
    />
  )

  return (
    <div className={`${defaultClasses.stepBlock} ${classes?.stepBlock}`}>
      <div
        className={`${defaultClasses.circle} ${classes?.circle} ${
          active ? defaultClasses.activeCircle : defaultClasses.inactiveCircle
        }`}>
        {!!showMiss ? (
          <MissedIcon
            className={`${defaultClasses.check} ${classes?.missed} ${
              active ? defaultClasses.activeCheck : defaultClasses.inactiveCheck
            }`}
          />
        ) : (
          <CheckedIcon
            className={`${defaultClasses.check} ${classes?.check} ${
              active ? defaultClasses.activeCheck : defaultClasses.inactiveCheck
            }`}
          />
        )}
      </div>
      <div
        className={
          index !== 0
            ? `${defaultClasses.dividerLine} ${classes?.dividerLine} ${
                active ? defaultClasses.activeDivider : defaultClasses.inactiveDivider
              }`
            : ''
        }></div>

      <div className={defaultClasses.mobileIcon}>{icon}</div>

      {current && <div className={defaultClasses.mobileCurrentIcon}>{icon}</div>}

      <div
        className={`${defaultClasses.text} ${defaultClasses.desktopLabel} ${classes?.text} ${
          active ? '' : defaultClasses.inactiveText
        }`}>
        {label}
      </div>

      {current && (
        <div
          className={`${defaultClasses.text} ${defaultClasses.currentTabletLabel} ${classes?.text} ${
            active ? '' : defaultClasses.inactiveText
          }`}>
          {label}
        </div>
      )}
    </div>
  )
}
