import React, { useContext } from 'react'

export interface ReturnsPortalConfig {
  enabled?: boolean
  global_return_window_days?: number
  return_reasons?: [
    {
      text: string
      custom: boolean
    },
  ]
  accept_additional_comments?: boolean
  return_delivery_options?: DeliveryOption[]
  accepts_partial_order_returns?: boolean
}

export interface DeliveryOption {
  cost_policy: {
    cost: { [key: string]: string } // eg { "USD": "3.99", "CAD": "1.24" }
    description: string
    type: string
  }
  configuration: {
    carrier: {
      carrier_code: string
      display_name: string
    }
    service: {
      service_code: string
      display_name: string
    }
    packaging: {
      package_code: string
      display_name: string
    }
  }
}

const ReturnsPortalConfigContext = React.createContext<ReturnsPortalConfig | undefined>({})
export const ReturnsPortalConfigProvider = ReturnsPortalConfigContext.Provider
export function useReturnsPortalConfig(): ReturnsPortalConfig {
  const portalConfig = useContext(ReturnsPortalConfigContext) as ReturnsPortalConfig

  return portalConfig
}
