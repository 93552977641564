import React from 'react'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { useTheme } from '@material-ui/core/styles'

import { Theme } from '../Theme'

import Icon from './Icon'

const getStyles = (color?: string) => ({
  root: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: 'rgba(255,255,255,0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: color || '#137CBD',
    zIndex: 2000,
    flexDirection: 'column',
  },
  text: {
    color: '#222222',
    fontSize: '18px',
    fontWeight: '500',
    marginTop: '15px',
  },
  footer: {
    position: 'fixed',
    bottom: '0',
    width: '100%',
    textAlign: 'center',
    color: '#222222',
  },
})

export default function Spinner({ text, footer, color }: { text?: string; footer?: any; color?: string }) {
  const styles = getStyles(color)

  const size = color ? '6x' : '5x' // hack to make spinners look the same size before & after page-level styles loaded

  return (
    <div style={styles.root as React.CSSProperties}>
      <Icon icon={faCircleNotch} size={size} spin />
      {text && <div style={styles.text}>{text}</div>}
      {footer && <div style={styles.footer as React.CSSProperties}>{footer}</div>}
    </div>
  )
}

export function ThemedSpinner({ text, footer }: { text?: string; footer?: any }) {
  const theme: Theme = useTheme()

  return <Spinner color={theme.colors.main} />
}
