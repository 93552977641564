/* eslint-disable camelcase */
import axios from 'axios'

const statsUrl = (external_account_id, branding_id) => {
  const subPath = `record-analytics-event/${external_account_id}/${branding_id}`
  return `${process.env.REACT_APP_API_URL}/${subPath}`
}

let configured
let isPreview
let external_account_id
let branding_id
let tracking_number
let carrier_code
let buyer_email
let tenant_id

export default function pushStat(eventType, suppliedData) {
  const data = {
    action: eventType,
    carrier_code,
    tracking_number,
    buyer_email,
    tenant_id,
    ...suppliedData,
  }

  if (configured && !isPreview) {
    return new Promise(resolve => {
      setTimeout(resolve, 2000) // just in case our axios call takes too long
      axios.post(statsUrl(external_account_id, branding_id), data).then(resolve).catch(resolve)
    })
  }

  return new Promise(resolve => {
    resolve()
  }) // don't break upstream usage (eg allow navigation)
}

export function configureStats(params) {
  configured = true
  isPreview = params.isPreview
  external_account_id = params.external_account_id
  branding_id = params.branding_id
  tracking_number = params.tracking_number
  carrier_code = params.carrier_code
  buyer_email = params.buyer_email
  tenant_id = params.tenant_id
}
