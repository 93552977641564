import { makeStyles } from '@material-ui/core'
import React from 'react'
import { IntlShape, useIntl } from 'react-intl'
import { getLocale, LocaleProvider } from './locales'

export function smsTermsMessage(intl: IntlShape) {
  return intl.formatMessage({
    description: 'smsTermsAndConditions',
    defaultMessage:
      'By entering a number you agree to receive status updates for this order sent via text. Message and data rates may apply. Reply STOP to cancel.',
  })
}

export default function SmsTermsPage() {
  const locale = getLocale()

  console.log({ locale })

  return (
    <LocaleProvider locale={locale}>
      <SmsTerms />
    </LocaleProvider>
  )
}

const usePageStyles = makeStyles(theme => ({
  '@global': {
    html: {
      height: '100%',
    },
    body: {
      position: 'relative',
      minHeight: '100%',
      height: '100%',
      background: '#f8f8f8',
      '& #root': {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
  },
}))

const SmsTerms = () => {
  const intl = useIntl()
  usePageStyles()

  return (
    <div
      style={{ width: '80%', background: 'white', padding: '20px', border: '1px solid #DFE3E8', borderRadius: '2px' }}>
      {smsTermsMessage(intl)}
    </div>
  )
}
