import React from 'react'

import LineItems, { useHasLineItems } from '../components/LineItems'
import { useHasOrderNumber } from '../components/OrderNumber'
import { useFeatureEnables } from '../dataProviders'
import { isGiftWrapped } from '../components/GiftWrapPanel'
import { makeBtStyles } from '../Theme'
import { MIN_TABLET_SIZE, MIN_DESKTOP_SIZE } from '../utils'

import Card from './Card'
import { LineItemClasses } from './ShipmentActivityContent'

const useStyles = makeBtStyles(theme => ({
  scrollableSection: ({ showGiftLink }: { showGiftLink: boolean }) => ({
    paddingRight: '10px',
    [theme.breakpoints.up(MIN_DESKTOP_SIZE)]: {
      maxHeight: showGiftLink ? '270px' : '310px',
    },
    [theme.breakpoints.up(MIN_TABLET_SIZE)]: {
      overflow: 'auto',
      width: '260px',
      maxHeight: showGiftLink ? '270px' : '310px',
    },
    [theme.breakpoints.between(MIN_TABLET_SIZE, MIN_DESKTOP_SIZE)]: {
      height: '290px',
    },
  }),
}))

export function useIsLineItemsCardShown() {
  const hasLineItems = useHasLineItems()
  const hasOrderNumber = useHasOrderNumber()
  const { showShipmentDetails } = useFeatureEnables()

  return !((!hasLineItems && !hasOrderNumber) || !showShipmentDetails || isGiftWrapped)
}

export default function LineItemsCard({
  className,
  lineItemClasses,
}: {
  className: string
  lineItemClasses: LineItemClasses
}) {
  const { showGiftLink } = useFeatureEnables()
  const classes = useStyles({ showGiftLink })

  return (
    <Card className={className}>
      <div className={classes.scrollableSection}>
        <LineItems truncateToCount={2} classes={lineItemClasses} formatQuantity={q => <>Qty {q}</>} />
      </div>
    </Card>
  )
}
