import React, { useCallback, useState } from 'react'
import moment from 'moment-timezone'
import _capitalize from 'lodash/capitalize'
import _fill from 'lodash/fill'
import { FormattedMessage, useIntl } from 'react-intl'
import { Collapse } from '@material-ui/core'
import classnames from 'classnames'

import { TrackingEvent as TrackingEventType } from '../apiTypes/api'
import { useTrackingData } from '../dataProviders'
import { BtLink } from './BtLink'
import { ReactComponent as EventSkeleton } from './tracking-event-skeleton.svg'
import { makeBtStyles } from '../Theme'

const useStyles = makeBtStyles({
  skeletonList: {
    padding: '15px 20px',
  },
  skeletonEntry: {
    marginBottom: '20px',
    display: 'block',
  },
})

export default function TrackingEvents({
  classes,
  formatTimestamp,
  eachEvent,
  truncateToCount,
}: {
  truncateToCount?: number
  classes: {
    showMore?: string
    event?: string
    emptyMsg?: string
    collapseToggle?: string
    loadingSkeleton?: string
  }
  formatTimestamp?: Function
  eachEvent?: ({
    content,
    key,
    event,
    index,
  }: {
    content: any
    key: string | number | undefined
    event: TrackingEventType
    index: number
  }) => React.ReactNode
}) {
  const { events = [], loaded } = useTrackingData()
  const [showMore, setShowMore] = useState(false)
  const toggleMore = useCallback(() => setShowMore(oldShowMore => !oldShowMore), [])
  const isToggleable = !!truncateToCount && events.length > truncateToCount
  const defaultClasses = useStyles()

  if (!loaded) {
    const entries = _fill(Array(truncateToCount || 3), 1) // just need an array to map over

    return (
      <div className={defaultClasses.skeletonList}>
        {entries.map(() => (
          <EventSkeleton className={defaultClasses.skeletonEntry} />
        ))}
      </div>
    )
  }

  if (!events.length) {
    return (
      <div className={classes.emptyMsg}>
        <FormattedMessage
          description="noTrackingEventsYetMsg"
          defaultMessage="Your label has been created. Tracking status will appear once the carrier has received the shipment."
        />
      </div>
    )
  }

  let alwaysShownEvents = events.slice(0, isToggleable ? truncateToCount : events.length)
  let toggledEvents = isToggleable ? events.slice(truncateToCount) : []

  return (
    <>
      {alwaysShownEvents.map((event, index) => {
        const key = event.carrier_occurred_at || index
        const content = (
          <TrackingEvent
            event={event}
            key={key}
            className={classes.event}
            formatTimestamp={formatTimestamp}
            index={index}
          />
        )

        return eachEvent ? eachEvent({ content, key, event, index }) : content
      })}
      {isToggleable && (
        <>
          <Collapse in={showMore}>
            {toggledEvents.map((event, loopIndex) => {
              const index = loopIndex + alwaysShownEvents.length
              const key = event.carrier_occurred_at || index
              const content = (
                <TrackingEvent
                  event={event}
                  key={key}
                  className={classes.event}
                  formatTimestamp={formatTimestamp}
                  index={index}
                />
              )

              return eachEvent ? eachEvent({ content, key, event, index }) : content
            })}
          </Collapse>
          <BtLink
            onClick={toggleMore}
            className={classnames(classes.showMore, classes.collapseToggle, { isOpen: showMore })}>
            {showMore ? (
              <FormattedMessage description="trackingEvents.viewLessActivity" defaultMessage="View Less Activity" />
            ) : (
              <FormattedMessage description="trackingEvents.viewAllActivity" defaultMessage="View All Activity" />
            )}
          </BtLink>
        </>
      )}
    </>
  )
}

export const TrackingEvent = React.memo(
  ({
    event,
    className = '',
    formatTimestamp,
    index,
  }: {
    event: TrackingEventType
    className?: string
    formatTimestamp?: Function
    index: number
  }) => {
    const intl = useIntl()
    const {
      carrier_occurred_at: occurredAt,
      city_locality: cityLocality,
      country_code: countryCode,
      state_province: state,
      description,
    } = event

    // Using carrier_occurred_at gets us closest to matching the carriers' tracking pages
    // See https://auctane.atlassian.net/browse/SHIPIT-14056
    const timestamp = occurredAt && moment(occurredAt).isValid() && moment(occurredAt)

    if (!formatTimestamp) {
      formatTimestamp = (timestamp: Date) => intl.formatDate(timestamp)
    }

    const formattedTimestamp = timestamp && formatTimestamp(timestamp)

    const city = _capitalize((cityLocality || '').toLocaleLowerCase())
    const country = countryCode !== 'US' ? countryCode : ''
    const location = [city, state, country].filter(e => e).join(', ')

    return (
      <div className={className} role="listitem" data-testid={index === 0 && 'latest-tracking-event'}>
        <span className="timestamp" title={occurredAt || ''}>
          {formattedTimestamp}
        </span>
        <span className="description">{description}</span>
        <span className="location">{location}</span>
      </div>
    )
  },
)
