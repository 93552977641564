import React from 'react'
import * as Sentry from '@sentry/browser'

export default class ErrorBoundary extends React.Component {
  state = { hasError: false, message: null, carrierTrackingUrl: null, error: null, errorInfo: null }

  static getDerivedStateFromError(error) {
    return { hasError: true, message: error.message, carrierTrackingUrl: error.carrierTrackingUrl }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo })
    Sentry.captureMessage(error.message)
  }

  render() {
    if (this.state.hasError) {
      const { carrierTrackingUrl, error, errorInfo } = this.state

      if (carrierTrackingUrl && process.env.NODE_ENV === 'production') {
        window.location = carrierTrackingUrl
        return null
      }

      if (process.env.NODE_ENV !== 'development') {
        return <p>An unexpected error occurred. Please contact your merchant for help.</p>
      } else {
        return (
          <div>
            <h2>Something went wrong.</h2>
            {error && error.toString()}
            <br />
            {errorInfo?.componentStack}
          </div>
        )
      }
    }

    return this.props.children
  }
}

export class LambdaError extends Error {
  constructor(message, carrierTrackingUrl, ...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params)

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, LambdaError)
    }

    this.name = 'LambdaError'
    this.message = message
    this.carrierTrackingUrl = carrierTrackingUrl
  }
}

export class InnerErrorBoundary extends React.Component {
  componentDidCatch(error, errorInfo) {
    if (this.props.carrierTrackingUrl && process.env.NODE_ENV === 'production') {
      Sentry.captureException(error)

      window.location = this.props.carrierTrackingUrl
      return null
    }

    // Fallback to the outer ErrorBoundary
    throw error
  }

  render = () => this.props.children
}
