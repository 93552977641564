import React, { useState } from 'react'
import _debounce from 'lodash/debounce'
import axios from 'axios'

import productionPreviewMockData from './productionPreviewData' // DO NOT CHANGE THIS IMPORT. USED BY PREVIEW FROM SHIP-IT
import { originIsSafe } from './utils'

const DEBOUNCE_WAIT_MS = 150
const GENERATE_EMAIL_URL = `${process.env.REACT_APP_API_URL}email/generate`

const debouncedGenerate = _debounce(function ({ branding, trigger, notification_setting, setPreview, setLoading }) {
  axios
    .post(GENERATE_EMAIL_URL, { branding, trigger, shipment: productionPreviewMockData.shipment, notification_setting })
    .then(response => {
      setPreview((oldPreview: any) => ({ ...oldPreview, html: response.data }))
      setLoading(false)
    })
    .catch(error => console.error(error))
}, DEBOUNCE_WAIT_MS)

export default function EmailPreview() {
  const [isLoading, setLoading] = useState(true)
  const [previewData, setPreview] = useState<any>(() => ({
    previewListener: window.addEventListener('message', msg => {
      if (originIsSafe(msg.origin) && msg.data.source === 'branded-tracking-email-preview') {
        const { branding, trigger, notification_setting } = msg.data
        setLoading(true)
        debouncedGenerate({ branding, trigger, notification_setting, setPreview, setLoading })
      }
    }),
  }))

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      {isLoading && (
        <div
          style={{
            position: 'absolute',
            left: '0',
            right: '0',
            top: '0',
            bottom: '0',
            backgroundColor: 'white',
            opacity: '0.75',
          }}></div>
      )}
      <div dangerouslySetInnerHTML={{ __html: previewData?.html || '' }}></div>
    </div>
  )
}
