import React, { useEffect } from 'react'
import * as Sentry from '@sentry/browser'

import Spinner from './components/Spinner'
import { useBrandedDocTitle } from './utils'
import useLayout from './useLayout'
import { ReturnPolicyDialogProvider } from './components/ReturnPolicyDialog'

import {
  ShipmentDataProvider,
  BrandingDataProvider,
  TrackingDataProvider,
  PreviewConfigProvider,
  NotificationSettingProvider,
  SubscriptionsProvider,
  TrackContextDataProvider,
} from './dataProviders'

import { analytics, PAGE_TRACK, EVENT_TRACK } from './Analytics'

const styles = {
  loadingContainer: {
    minHeight: 'calc(100vh - 80px)',
    width: '96%',
    margin: '0 auto',
  },
}

function useSentryContext(data) {
  const { loaded, shipment, branding } = data

  if (loaded === false) {
    return
  }

  const brandingId = branding && branding.branding_id
  const trackingNumber = shipment && shipment.tracking_number
  const carrierCode = shipment && shipment.carrier_code
  const tenantMerchantId = shipment && shipment.external_account_id
  const email = shipment && shipment.destination && shipment.destination.email
  const userId = shipment && `${carrierCode}:${trackingNumber}:${email}`

  Sentry.configureScope(function (scope) {
    scope.setUser({ id: userId, email: email })
    scope.setTag('trackingNumber', trackingNumber)
    scope.setTag('carrierCode', carrierCode)
    scope.setTag('tenantMerchantId', tenantMerchantId)
    scope.setTag('brandingId', brandingId)
  })
}

const publishAnalytics = (branding, shipment, tracking = {}) => {
  const customerId = shipment.external_account_id || tracking.external_account_id
  analytics.identify(customerId)
  analytics.page(PAGE_TRACK)

  const eventMeta = {
    external_account_id: customerId,
    branding_id: branding.branding_id,
    tracking_number: shipment.tracking_number,
    carrier_code: shipment.carrier_code,
    buyer_email: shipment.destination.email,
    tenant_id: branding.tenant_id,
    status: 'Unknown',
  }
  if (tracking) eventMeta.status = tracking.status_code

  analytics.event(EVENT_TRACK, eventMeta)
}

export default function TrackingPage({ data, trackingData }) {
  const { loaded, shipment, branding, notification_setting, sms_subscriptions, preview, context } = data

  const tracking = trackingData.tracking
  const Layout = useLayout(branding)

  // skip subsequent stats on any re-render
  useEffect(() => {
    if (branding && shipment) {
      publishAnalytics(branding, shipment, tracking)
    }
  }, [branding, shipment, tracking])

  useBrandedDocTitle(data?.branding)
  useSentryContext(data)

  if (loaded === false) {
    return (
      <div style={styles.loadingContainer}>
        <Spinner />
      </div>
    )
  }

  return (
    <ShipmentDataProvider value={shipment}>
      <BrandingDataProvider value={branding}>
        <TrackingDataProvider value={trackingData}>
          <NotificationSettingProvider value={notification_setting}>
            <SubscriptionsProvider value={sms_subscriptions}>
              <TrackContextDataProvider value={context}>
                <PreviewConfigProvider value={preview}>
                  <ReturnPolicyDialogProvider>
                    <Layout.Layout>
                      <Layout.TrackingPage />
                    </Layout.Layout>
                  </ReturnPolicyDialogProvider>
                </PreviewConfigProvider>
              </TrackContextDataProvider>
            </SubscriptionsProvider>
          </NotificationSettingProvider>
        </TrackingDataProvider>
      </BrandingDataProvider>
    </ShipmentDataProvider>
  )
}
