import React from 'react'
import { Hidden, useTheme, useMediaQuery } from '@material-ui/core'

import { makeBtStyles } from '../Theme'
import PromoImage, { usePromoImage } from '../components/PromoImage'
import { MOBILE_CONTENT_WIDTH } from '../components/defaultStyleVars'
import { CONTENT_WIDTH } from '../components/defaultStyleVars'
import GiftWrapPanel from '../components/GiftWrapPanel'

import MainCard from './MainCard'
import TrackingCard from './TrackingCard'
import LineItemsCard, { useIsLineItemsCardShown } from './LineItemsCard'
import ContactCard from './ContactCard'
import SmsCard from './SmsCard'

export const MAIN_COL_WIDTH = 350
export const SECONDARY_COL_WIDTH = 300
export const COL_SPACING = 15

const CONTENT_HEIGHT = 600
const SHORT_PROMO_IMAGE_HEIGHT = 250
const PROMO_IMAGE_TALL_TYPE = 'tall'
const PROMO_IMAGE_MARGIN = 15

const useTrackingPageStyles = makeBtStyles(theme => ({
  contentWrapper: ({ lineItemsCardShown, hasPromoImage }: { lineItemsCardShown: boolean; hasPromoImage: boolean }) => ({
    margin: '30px auto',
    width:
      lineItemsCardShown || hasPromoImage
        ? `${CONTENT_WIDTH}px`
        : MAIN_COL_WIDTH + SECONDARY_COL_WIDTH + COL_SPACING + 'px',
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '0',
      maxWidth: 'none',
    },
  }),
  cardGrid: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  mainColumn: {
    width: `${MAIN_COL_WIDTH}px`,
    height: `${CONTENT_HEIGHT}px`,
    [theme.breakpoints.down('sm')]: {
      width: MOBILE_CONTENT_WIDTH,
      height: 'auto',
      minWidth: '270px',
      margin: '30px auto',
    },
  },
  secondaryColumn: {
    width: `${SECONDARY_COL_WIDTH}px`,
    height: `${CONTENT_HEIGHT}px`,
    [theme.breakpoints.down('sm')]: {
      width: MOBILE_CONTENT_WIDTH,
      height: 'auto',
      minWidth: '270px',
      margin: '30px auto',
    },
  },
  lineItemsCard: ({ hasPromoImage }: { hasPromoImage: boolean }) => ({
    height: hasPromoImage ? CONTENT_HEIGHT - SHORT_PROMO_IMAGE_HEIGHT - PROMO_IMAGE_MARGIN + 'px' : '100%',
    marginBottom: hasPromoImage ? `${PROMO_IMAGE_MARGIN}px` : 0,
  }),
  smsCard: {
    width: '100%',
    marginTop: `${COL_SPACING}px`,
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: MOBILE_CONTENT_WIDTH,
      margin: '30px auto',
    },
  },
  promoImageWrapper: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '320px',
      margin: '30px auto',
    },
  },
  giftWrapPanel: {
    display: 'block',
    borderRadius: '3px',
    border: '0.5px solid #DFE3E8',
    boxShadow: '0 1px 10 rgb (0, 0, 0, 0.05)',
    position: 'relative',
    marginBottom: '30px',
  },
}))

export default function TrackingPage() {
  const theme = useTheme()
  const lineItemsCardShown = useIsLineItemsCardShown()
  const promoImage = usePromoImage()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const promoMaxHeight = !isDesktop
    ? Number.POSITIVE_INFINITY
    : promoImage?.size_type === PROMO_IMAGE_TALL_TYPE
    ? CONTENT_HEIGHT
    : SHORT_PROMO_IMAGE_HEIGHT
  const classes = useTrackingPageStyles({ lineItemsCardShown, hasPromoImage: !!promoImage })

  return (
    <div className={classes.contentWrapper}>
      <GiftWrapPanel className={classes.giftWrapPanel} />
      <div className={classes.cardGrid}>
        <div className={classes.mainColumn}>
          <MainCard />
        </div>

        <Hidden mdUp>
          <SmsCard className={classes.smsCard} />
        </Hidden>

        <div className={classes.secondaryColumn}>
          <TrackingCard />
        </div>

        {(lineItemsCardShown || !!promoImage) && (
          <div className={classes.secondaryColumn}>
            {lineItemsCardShown && promoImage?.size_type !== PROMO_IMAGE_TALL_TYPE && (
              <LineItemsCard className={classes.lineItemsCard} />
            )}
            <div className={classes.promoImageWrapper}>
              <PromoImage maxHeight={promoMaxHeight} />
            </div>
          </div>
        )}
      </div>

      <ContactCard className={classes.secondaryColumn} />

      <Hidden smDown>
        <SmsCard className={classes.smsCard} />
      </Hidden>
    </div>
  )
}
