import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'element-closest-polyfill'
import 'promise-polyfill/src/polyfill'

import React from 'react'
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/browser'
import App from './TrackingPageApp'
import * as serviceWorker from './serviceWorker'
import ignoreErrors from './ignoreErrors'

const sentryDsn = 'https://062b2064520e4626a79d78d2624ea00c@o106853.ingest.sentry.io/5245152'
Sentry.init({ dsn: sentryDsn, ignoreErrors: ignoreErrors })

//TODO: When we get a true tenant table, this info will be available to us in a "tenant"
// data document
function tenant() {
  switch (window.location.hostname) {
    case 'trackshipment.store':
      return 'shippingeasy'
    case 'trackshipment.shipstation.com':
      return 'shipstation'
    default:
      return 'unknown'
  }
}

Sentry.configureScope(function (scope) {
  scope.setTag('tenant', tenant())
})

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
