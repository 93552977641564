import React, { useCallback, useState } from 'react'
import classnames from 'classnames'
import _isNil from 'lodash/isNil'
import { FormattedMessage, useIntl } from 'react-intl'
import { Collapse } from '@material-ui/core'
import { useShipmentData, Currency, useFeatureEnables } from '../dataProviders'
import ItemImage from './ItemImage'
import { isGiftWrapped } from './GiftWrapPanel'
import { BtLink } from './BtLink'

export const ItemDescription = ({
  item,
  classes = {},
}: {
  item: {
    description?: string
    product_name?: string
  }
  classes: {
    description?: string
  }
}) => {
  const { showItemOptions } = useFeatureEnables()

  if (!showItemOptions) {
    return null
  }

  let description = item.description

  if (!description || description === item.product_name) {
    return null
  }

  const regex = new RegExp(`^${item.product_name}[ ,]+`)
  if (description.match(regex)) {
    description = description.replace(regex, '')
  }

  return <div className={classes.description}>{description}</div>
}

interface ItemOptionsClasses {
  options?: string
  optionLabel?: string
  optionValue?: string
  optionItem?: string
}

export const ItemOptions = ({
  item,
  classes = {},
}: {
  item: {
    product_name?: string
    options?: string | Array<{ label: string; value: string }>
  }
  classes: ItemOptionsClasses
}) => {
  const { showItemOptions } = useFeatureEnables()
  const options = item.options

  if (!showItemOptions || !options) {
    return null
  }

  if (typeof options === 'string' && options !== item.product_name) {
    return <div className={classes.options}>{options}</div>
  } else if (Array.isArray(options)) {
    return (
      <ul className={classes.options}>
        {options.map(({ label, value }) => (
          <li key={label} className={classes.optionItem}>
            <span className={classes.optionLabel}>{label}:</span>
            <span className={classes.optionValue}>{value}</span>
          </li>
        ))}
      </ul>
    )
  }

  return null
}

export function useHasLineItems() {
  const { line_items } = useShipmentData()
  return line_items && line_items.length > 0
}

export function LineItemCount({ className }: { className?: string }) {
  const { line_items } = useShipmentData()
  const count = (line_items || []).reduce((sum, item) => {
    let quantity = parseInt(`${item.quantity}`) // interpolation kinda a hack to ensure integrity until we can rely on OpenAPI guarantee about type
    if (Number.isNaN(quantity)) {
      // we shouldn't usually hit this case, but do in preview mode b/c of non-numbers
      quantity = 1
    }
    return sum + quantity
  }, 0)

  if (count === 0) {
    return null
  }

  return (
    <span className={className}>
      <FormattedMessage defaultMessage="{count} {count, plural, one {item} other {items}}" values={{ count }} />
    </span>
  )
}

export default function LineItems({
  truncateToCount,
  classes = {},
  formatQuantity,
  children,
}: {
  truncateToCount?: number
  classes: {
    showMore?: string
    collapseToggle?: string
    item?: string
    image?: string
    name?: string
    description?: string
    quantity?: string
    unitPrice?: string
    subtotal?: string
  }
  formatQuantity: (quantity: number | undefined) => React.ReactNode
  children?: ({
    Image,
    name,
    Options,
    quantity,
    unitPrice,
    subtotal,
  }: {
    Image: (props: any) => React.ReactNode
    name: string | null | undefined
    Description: (props: any) => React.ReactNode
    Options: (props: any) => React.ReactNode
    quantity: React.ReactNode
    unitPrice: string
    subtotal: string
  }) => React.ReactNode
}) {
  const intl = useIntl()
  const { line_items } = useShipmentData()
  const [showMore, setShowMore] = useState(false)
  const toggleMore = useCallback(() => setShowMore(oldShowMore => !oldShowMore), [])
  const isToggleable = !!truncateToCount && line_items.length > truncateToCount
  const { showShipmentDetails, showItemImages, showShipmentPrices } = useFeatureEnables()
  formatQuantity = formatQuantity || (q => q)

  const formatCurrencyString = (unit_price: Currency | null | undefined) => {
    const value = unit_price?.amount || '0.00'

    if(!unit_price?.currency_code) {
      const currencyFractionDigits = new Intl.NumberFormat(intl.locale, {
        style: 'currency',
        currency: 'USD',
      }).resolvedOptions().maximumFractionDigits

      return parseFloat(value).toLocaleString(intl.locale, {
          maximumFractionDigits: currencyFractionDigits
      });
    }

    return parseFloat(value).toLocaleString(intl.locale, {
      style: 'currency',
      currency: unit_price.currency_code
    });
  }

  if (!showShipmentDetails || isGiftWrapped) {
    return null
  }

  const itemClassName = classnames(classes.item, {
    'item--no-image': !showItemImages,
  })

  let alwaysShownItems = line_items.slice(0, isToggleable ? truncateToCount : line_items.length)
  let toggledItems = isToggleable ? line_items.slice(truncateToCount) : []

  return (
    <>
      {(alwaysShownItems || []).map((item, index) => {
        const imageUrl = item.image_urls?.thumb || item.image_urls?.medium || item.image_urls?.original
        const name = item.product_name
        const quantity = formatQuantity(item.quantity)
        const unitPrice = !showShipmentPrices || _isNil(item.unit_price) ? '' : formatCurrencyString(item.unit_price)
        const subtotal =
          !showShipmentPrices || _isNil(item.subtotal_price) ? '' : formatCurrencyString(item.subtotal_price)

        return (
          <div className={itemClassName} key={`${name}_${index}`}>
            {showItemImages && (
              <div className={classes.image}>
                {showItemImages ? <ItemImage url={imageUrl} name={item.product_name} /> : null}
              </div>
            )}
            <div className={classes.name}>{name}</div>
            <ItemDescription item={item} classes={classes} />
            <ItemOptions item={item} classes={classes as ItemOptionsClasses} />
            <div className={classes.quantity}>{quantity}</div>
            {unitPrice && <div className={classes.unitPrice}>{unitPrice}</div>}
            {subtotal && <div className={classes.subtotal}>{subtotal}</div>}
          </div>
        )
      })}
      {isToggleable && (
        <>
          <Collapse in={showMore}>
            {toggledItems.map((item, loopIndex) => {
              const index = loopIndex + alwaysShownItems.length
              const imageUrl = item.image_urls?.thumb || item.image_urls?.medium || item.image_urls?.original
              const name = item.product_name
              const quantity = formatQuantity(item.quantity)
              const unitPrice =
                !showShipmentPrices || _isNil(item.unit_price) ? '' : formatCurrencyString(item.unit_price)
              const subtotal =
                !showShipmentPrices || _isNil(item.subtotal_price) ? '' : formatCurrencyString(item.subtotal_price)

              return (
                <div className={itemClassName} key={`${name}_${index}`}>
                  {showItemImages && (
                    <div className={classes.image}>
                      {showItemImages ? <ItemImage url={imageUrl} name={item.product_name} /> : null}
                    </div>
                  )}
                  <div className={classes.name}>{name}</div>
                  <ItemDescription item={item} classes={classes} />
                  <ItemOptions item={item} classes={classes as ItemOptionsClasses} />
                  <div className={classes.quantity}>{quantity}</div>
                  {unitPrice && <div className={classes.unitPrice}>{unitPrice}</div>}
                  {subtotal && <div className={classes.subtotal}>{subtotal}</div>}
                </div>
              )
            })}
          </Collapse>
          <BtLink
            onClick={toggleMore}
            className={classnames(classes.showMore, classes.collapseToggle, { isOpen: showMore })}>
            {showMore ? (
              <FormattedMessage description="lineItems.viewLessContents" defaultMessage="View Less Contents" />
            ) : (
              <FormattedMessage description="lineItems.viewAllContents" defaultMessage="View All Contents" />
            )}
          </BtLink>
        </>
      )}
    </>
  )
}
