import React from 'react'
import { FormattedMessage } from 'react-intl'
import { darken, desaturate } from 'polished'

import { makeBtStyles } from '../Theme'
import SocialMediaGrid from '../components/SocialMediaGrid'
import { MIN_TABLET_SIZE } from '../utils'

import MainStatusSection from './MainStatusSection'
import Card, { CardHeader, CardSection } from './Card'
import ContactSection from './ContactSection'
import { CONTENT_WIDTH, MOBILE_CONTENT_WIDTH } from '../components/defaultStyleVars'

const useStyles = makeBtStyles(theme => ({
  socialMediaRoot: {
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      display: 'none',
    },
  },
  mainCard: {
    background: theme.colors.main,
    color: 'white',
    borderTopLeftRadius: '10px',
    [theme.breakpoints.up(MIN_TABLET_SIZE)]: {
      borderBottomLeftRadius: '10px',
      borderRightStyle: 'none',
    },
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      flexDirection: 'column',
      alignItems: 'center',
      borderTopRightRadius: '10px',
    },
  },
  cardHeader: {
    borderBottom: 'none',
    lineHeight: '12px',
    fontSize: '18px',
    [theme.breakpoints.up(MIN_TABLET_SIZE)]: {
      padding: '40px 0px 10px 40px',
    },
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      marginTop: '20px',
      textAlign: 'center',
      fontSize: '22px',
    },
  },
  contactSection: {
    color: 'white',
    padding: '22px 40px',
    textAlign: 'left',
    display: 'block',
    borderTop: `1px solid ${darken(0.15, desaturate(0.1, theme.colors.main))}`,
    borderBottom: `1px solid ${darken(0.15, desaturate(0.1, theme.colors.main))}`,
    width: '100%',
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      display: 'none',
    },
    '&:first-child': {
      borderBottom: 'none',
    },
    '&:last-child': {
      borderTop: 'none',
    },
    '& .icon': {
      display: 'none',
    },
  },
}))

const useNavStyles = makeBtStyles(
  theme => ({
    root: {
      boxShadow: 'none',
      backgroundColor: 'white',
      border: '1px solid rgba(0, 0, 0, 0.12)',
    },
    toolbar: () => ({
      maxHeight: '64px',
      backgroundColor: 'white',
      width: MOBILE_CONTENT_WIDTH,
      padding: 0,
      margin: '0 auto',
      flexWrap: 'wrap',
      [theme.breakpoints.up(MIN_TABLET_SIZE)]: {
        width: `${CONTENT_WIDTH}px`,
        maxWidth: '98%',
      },
    }),
    paper: {},
    menuLink: {
      position: 'absolute',
      marginLeft: '-19px',
      '& svg': {
        transform: 'scale(1.6)',
        fill: theme.link.color,
      },
    },
    link: {
      fontSize: '14px',
      verticalAlign: 'middle',
      marginLeft: '15px',
    },
    socialMediaGrid: {
      display: 'flex',
      position: 'absolute',
      bottom: '78px',
      width: '100%',
      padding: '0 15px 0 15px',
    },
    socialMediaLink: {
      padding: '0 5px',
      fontSize: '18px',
      marginRight: '8px',
      marginLeft: '8px',
      '&:first-child': {
        marginLeft: '23px',
      },
      '&:last-child': {
        paddingRight: '0',
      },
    },
    socialMediaLinkIcon: {
      verticalAlign: 'middle',
      color: 'white',
    },
  }),
  { name: 'NavStyles' },
)

export default function MainCard({ className }: { className?: string }) {
  const classes = useStyles()
  const navClasses = useNavStyles()

  return (
    <Card className={`${classes.mainCard} ${className}`} aria-labelledby="main-card-header">
      <CardHeader id="main-card-header" className={classes.cardHeader}>
        <FormattedMessage description="layout02.mainCard.heading" defaultMessage="Estimated Delivery" />
      </CardHeader>

      <CardSection>
        <MainStatusSection />
      </CardSection>

      <ContactSection className={classes.contactSection} />
      <div className={classes.socialMediaRoot}>
        <SocialMediaGrid
          classes={{
            root: navClasses.socialMediaGrid,
            link: navClasses.socialMediaLink,
            linkIcon: navClasses.socialMediaLinkIcon,
          }}
        />
      </div>
    </Card>
  )
}
