import React, { useCallback, useState } from 'react'
import classnames from 'classnames'

import { Collapse } from '@material-ui/core'

import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'

import { BtLink } from './BtLink'
import Icon from './Icon'

interface InfoClasses {
  icon?: string
}

interface ToggleClasses {
  root?: string
  collapseToggle?: string
  showMore?: string
}

export default function CollapsableSection({
  children,
  classes,
  toggleClasses,
  label,
}: {
  children: React.ReactNode
  classes: InfoClasses
  toggleClasses: ToggleClasses
  label?: React.ReactNode
}) {
  const [showMore, setShowMore] = useState(false)
  const toggleMore = useCallback(() => setShowMore(oldShowMore => !oldShowMore), [])

  return (
    <div className={toggleClasses.root}>
      <BtLink onClick={toggleMore} className={classnames(toggleClasses.showMore, toggleClasses.collapseToggle)}>
        {label && <>{label}</>}
        <Icon icon={showMore ? faChevronUp : faChevronDown} className={classes?.icon} />
      </BtLink>
      <Collapse in={showMore}>{children}</Collapse>
    </div>
  )
}
