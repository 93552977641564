import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { makeBtStyles } from '../Theme'
import OrderNumber, { useHasOrderNumber } from '../components/OrderNumber'
import StatusStepper from '../components/stepper/StatusStepper'
import EstimatedDeliveryDateOrStatus from '../components/EstimatedDeliveryDateOrStatus'
import DeliveredCheckMark from '../components/DeliveredCheckMark'
import { MIN_TABLET_SIZE, MIN_DESKTOP_SIZE } from '../utils'

import Card, { CardHeader, CardSection } from './Card'
import ShipmentActivity from './ShipmentActivity'
import DestinationTrackingSection from './DestinationTrackingSection'

const useStyles = makeBtStyles(theme => ({
  cardHeader: {
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: '0.13px',
    color: '#222222',
    border: 'none',
    margin: '38px 0 0 50px',
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      margin: '20px 0 0 30px',
    },
    padding: '0',
  },
  trackingCardHeader: {
    padding: '0',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      display: 'block',
    },
  },
  orderNumberSection: {
    border: '1.5px solid #DEDEDE',
    padding: '5px 10px',
    margin: '38px 40px 0 0',
    minWidth: '220px',
    maxWidth: '350px',
    minHeight: '30px',
    display: 'flex',
    flexWrap: 'wrap',
    lineHeight: '21px',
    justifyContent: 'center',
    [theme.breakpoints.between(MIN_TABLET_SIZE, MIN_DESKTOP_SIZE)]: {
      margin: '30px 40px 0 0',
    },
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      margin: '20px 30px 0 30px',
    },
  },
  orderNumber: {
    marginLeft: '5px',
  },
  contentLabel: {
    marginRight: '5px',
    fontWeight: '600',
  },
  estimatedDeliveryDate: {
    padding: '7px 20px',
  },
  dateOrStatus: {
    fontSize: '40px',
    fontWeight: 'bold',
    margin: '0 0 0 30px',
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      margin: '0 0 0 10px',
    },
    paddingTop: '0px',
    color: `${theme.colors.characteristic ? theme.colors.characteristic : theme.colors.main}`,
  },
  deliveredCheck: {
    height: '30px',
  },
  displayOnMobile: {
    [theme.breakpoints.up(MIN_TABLET_SIZE)]: {
      display: 'none',
    },
  },
  hideOnMobile: {
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      display: 'none',
    },
  },
}))

const useStepperStyles = makeBtStyles(theme => ({
  stepWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '98%',
    [theme.breakpoints.up(MIN_DESKTOP_SIZE)]: {
      margin: '30px 10px 32px 10px',
    },
    [theme.breakpoints.down(MIN_DESKTOP_SIZE)]: {
      width: '95%',
      margin: '30px 0px 25px 10px',
    },
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      width: '100%',
      margin: '30px 0px 20px',
    },
  },
  friendlyStatus: {
    width: '470px',
    letterSpacing: '0.15px',
    margin: '0px 0px 0px 50px',
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      margin: '0 0 0 30px !important',
      width: '85% !important',
    },
    display: 'block',
    fontWeight: '400',
    [theme.breakpoints.down(MIN_DESKTOP_SIZE)]: {
      width: '100%',
      margin: '3px 0px 0px 50px',
    },
  },
  statusWrap: {
    fontSize: '16px',
    lineHeight: '19px',
    marginBottom: '-10px',
  },
  statusNoWrap: {
    fontSize: '16px',
    lineHeight: '19px',
  },
}))

const useStepStyles = makeBtStyles(theme => ({
  circle: {
    [theme.breakpoints.up(MIN_DESKTOP_SIZE)]: {
      marginBottom: '18px',
      height: '13px',
      width: '13px',
    },
    [theme.breakpoints.down(MIN_DESKTOP_SIZE)]: {
      marginBottom: '10px',
      height: '15px',
      width: '15px',
    },
  },
  check: {
    [theme.breakpoints.up(MIN_DESKTOP_SIZE)]: {
      height: '7px',
      width: '7px',
      margin: '0px 1px 4px',
    },
    [theme.breakpoints.down(MIN_DESKTOP_SIZE)]: {
      height: '9px',
      width: '9px',
      margin: '0px 1px 2px',
    },
  },
  missed: {
    [theme.breakpoints.up(MIN_DESKTOP_SIZE)]: {
      height: '5px',
      width: '7px',
      margin: '0px 0px 5px 1px',
    },
    [theme.breakpoints.down(MIN_DESKTOP_SIZE)]: {
      height: '7px',
      width: '7px',
      margin: '0px 2px 3px',
    },
  },
  dividerLine: {
    [theme.breakpoints.up(MIN_DESKTOP_SIZE)]: {
      height: '4px',
      top: '4.5px',
      right: '50%',
    },
    [theme.breakpoints.down(MIN_DESKTOP_SIZE)]: {
      height: '4px',
      top: '5px',
      right: '50%',
    },
  },
  icon: {
    [theme.breakpoints.up(MIN_DESKTOP_SIZE)]: {
      height: '18px',
      width: '23px',
      marginTop: '10px',
    },
    [theme.breakpoints.down(MIN_DESKTOP_SIZE)]: {
      height: '18px',
      marginTop: '10px',
    },
  },
  stepBlock: {
    [theme.breakpoints.up(MIN_DESKTOP_SIZE)]: {
      width: '160px !important',
    },
    [theme.breakpoints.down(MIN_DESKTOP_SIZE)]: {
      width: '120px',
    },
  },
  text: {
    fontSize: '12px',
    letterSpacing: '0.11px',
    marginTop: '5px',
  },
}))

export default function TrackingCard({ className }: { className?: string }) {
  const classes = useStyles()
  const stepperClasses = useStepperStyles()
  const stepClasses = useStepStyles()
  const hasOrderNumber = useHasOrderNumber()
  const [checkShown, setCheckShown] = useState(false)

  useEffect(() => {
    setTimeout(() => setCheckShown(true), 2000)
  }, [])

  return (
    <Card className={className} aria-labelledby="tracking-header">
      <CardSection className={classes.trackingCardHeader}>
        <CardHeader id="main-card-header" className={`${classes.cardHeader} ${classes.hideOnMobile}`}>
          <FormattedMessage description="layout03.trackingCard.heading" defaultMessage="Estimated Delivery" />
        </CardHeader>
        {hasOrderNumber && (
          <div className={classes.orderNumberSection}>
            <label className={classes.contentLabel}>
              <FormattedMessage description="layout03.lineItemsCard.orderNumberLabel" defaultMessage="Order Number" />:
            </label>
            <OrderNumber className={classes.orderNumber} />
          </div>
        )}
        <CardHeader id="main-card-header" className={`${classes.cardHeader} ${classes.displayOnMobile}`}>
          <FormattedMessage description="layout03.trackingCard.heading" defaultMessage="Estimated Delivery" />
        </CardHeader>
      </CardSection>
      <CardSection className={classes.estimatedDeliveryDate}>
        <EstimatedDeliveryDateOrStatus className={classes.dateOrStatus}>
          {({ formattedStatusOrDate, status }) => (
            <div>
              <span className={classes.textStatus}>{formattedStatusOrDate}</span>
              {checkShown && status === 'isDelivered' && (
                <>
                  <DeliveredCheckMark className={classes.deliveredCheck} />
                </>
              )}
            </div>
          )}
        </EstimatedDeliveryDateOrStatus>
      </CardSection>
      <StatusStepper classes={stepperClasses} stepClasses={stepClasses} />
      <DestinationTrackingSection />
      <ShipmentActivity className={classes.hideOnMobile} />
    </Card>
  )
}
