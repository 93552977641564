/* eslint-disable */
/* tslint:disable */
/* eslint-disable */
/**
 * Branded Tracking Service
 * REST API for Branded Tracking Service
 *
 * The version of the OpenAPI document: 2.9.9
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'company'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'address2'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'address3'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'country_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'postal_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'phone_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'email'?: string;
}
/**
 * The tracking state, shipment details, and branding configuration for a shipment
 * @export
 * @interface BrandedTrackingResponse
 */
export interface BrandedTrackingResponse {
    /**
     * 
     * @type {Tracking}
     * @memberof BrandedTrackingResponse
     */
    'tracking': Tracking;
    /**
     * 
     * @type {Branding}
     * @memberof BrandedTrackingResponse
     */
    'branding': Branding;
    /**
     * 
     * @type {Shipment}
     * @memberof BrandedTrackingResponse
     */
    'shipment': Shipment;
}
/**
 * A Branding resource that has been saved in the Branded Tracking Service
 * @export
 * @interface Branding
 */
export interface Branding {
    /**
     * 
     * @type {string}
     * @memberof Branding
     */
    'branding_id': string;
    /**
     * 
     * @type {string}
     * @memberof Branding
     */
    'layout_key': BrandingLayoutKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof Branding
     */
    'return_policy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Branding
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof Branding
     */
    'phone'?: string;
    /**
     * 
     * @type {BrandingPropertiesPoweredBy}
     * @memberof Branding
     */
    'powered_by'?: BrandingPropertiesPoweredBy;
    /**
     * 
     * @type {string}
     * @memberof Branding
     */
    'store_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof Branding
     */
    'store_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Branding
     */
    'logo_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof Branding
     */
    'logo_type'?: BrandingLogoTypeEnum;
    /**
     * 
     * @type {Array<Link>}
     * @memberof Branding
     */
    'menu_links'?: Array<Link> | null;
    /**
     * 
     * @type {Array<SocialLink>}
     * @memberof Branding
     */
    'social_media'?: Array<SocialLink> | null;
    /**
     * 
     * @type {Array<Link>}
     * @memberof Branding
     */
    'external_links'?: Array<Link> | null;
    /**
     * 
     * @type {BrandingPropertiesToggles}
     * @memberof Branding
     */
    'toggles'?: BrandingPropertiesToggles;
    /**
     * 
     * @type {BrandingPropertiesColors}
     * @memberof Branding
     */
    'colors'?: BrandingPropertiesColors;
    /**
     * 
     * @type {string}
     * @memberof Branding
     */
    'theme_key'?: BrandingThemeKeyEnum;
    /**
     * 
     * @type {BrandingPropertiesPromoImages}
     * @memberof Branding
     */
    'promo_images'?: BrandingPropertiesPromoImages;
}

export const BrandingLayoutKeyEnum = {
    Layout00: 'layout00',
    Layout01: 'layout01',
    Layout02: 'layout02',
    Layout03: 'layout03',
    StationLegacy: 'station_legacy'
} as const;

export type BrandingLayoutKeyEnum = typeof BrandingLayoutKeyEnum[keyof typeof BrandingLayoutKeyEnum];
export const BrandingLogoTypeEnum = {
    Image: 'image',
    Text: 'text'
} as const;

export type BrandingLogoTypeEnum = typeof BrandingLogoTypeEnum[keyof typeof BrandingLogoTypeEnum];
export const BrandingThemeKeyEnum = {
    Light: 'light',
    Dark: 'dark',
    Paparazzi: 'paparazzi',
    Peach: 'peach',
    Unicorns: 'unicorns',
    Custom: 'custom'
} as const;

export type BrandingThemeKeyEnum = typeof BrandingThemeKeyEnum[keyof typeof BrandingThemeKeyEnum];

/**
 * 
 * @export
 * @interface BrandingAllOf
 */
export interface BrandingAllOf {
    /**
     * 
     * @type {string}
     * @memberof BrandingAllOf
     */
    'branding_id': string;
}
/**
 * A set of common colors, fonts, images, layouts, display configuration options, and design elements that can be applied to consumer experiences such as shipment tracking pages, returns, and status emails.
 * @export
 * @interface BrandingProperties
 */
export interface BrandingProperties {
    /**
     * 
     * @type {string}
     * @memberof BrandingProperties
     */
    'layout_key': BrandingPropertiesLayoutKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof BrandingProperties
     */
    'return_policy'?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandingProperties
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandingProperties
     */
    'phone'?: string;
    /**
     * 
     * @type {BrandingPropertiesPoweredBy}
     * @memberof BrandingProperties
     */
    'powered_by'?: BrandingPropertiesPoweredBy;
    /**
     * 
     * @type {string}
     * @memberof BrandingProperties
     */
    'store_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandingProperties
     */
    'store_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandingProperties
     */
    'logo_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandingProperties
     */
    'logo_type'?: BrandingPropertiesLogoTypeEnum;
    /**
     * 
     * @type {Array<Link>}
     * @memberof BrandingProperties
     */
    'menu_links'?: Array<Link> | null;
    /**
     * 
     * @type {Array<SocialLink>}
     * @memberof BrandingProperties
     */
    'social_media'?: Array<SocialLink> | null;
    /**
     * 
     * @type {Array<Link>}
     * @memberof BrandingProperties
     */
    'external_links'?: Array<Link> | null;
    /**
     * 
     * @type {BrandingPropertiesToggles}
     * @memberof BrandingProperties
     */
    'toggles'?: BrandingPropertiesToggles;
    /**
     * 
     * @type {BrandingPropertiesColors}
     * @memberof BrandingProperties
     */
    'colors'?: BrandingPropertiesColors;
    /**
     * 
     * @type {string}
     * @memberof BrandingProperties
     */
    'theme_key'?: BrandingPropertiesThemeKeyEnum;
    /**
     * 
     * @type {BrandingPropertiesPromoImages}
     * @memberof BrandingProperties
     */
    'promo_images'?: BrandingPropertiesPromoImages;
}

export const BrandingPropertiesLayoutKeyEnum = {
    Layout00: 'layout00',
    Layout01: 'layout01',
    Layout02: 'layout02',
    Layout03: 'layout03',
    StationLegacy: 'station_legacy'
} as const;

export type BrandingPropertiesLayoutKeyEnum = typeof BrandingPropertiesLayoutKeyEnum[keyof typeof BrandingPropertiesLayoutKeyEnum];
export const BrandingPropertiesLogoTypeEnum = {
    Image: 'image',
    Text: 'text'
} as const;

export type BrandingPropertiesLogoTypeEnum = typeof BrandingPropertiesLogoTypeEnum[keyof typeof BrandingPropertiesLogoTypeEnum];
export const BrandingPropertiesThemeKeyEnum = {
    Light: 'light',
    Dark: 'dark',
    Paparazzi: 'paparazzi',
    Peach: 'peach',
    Unicorns: 'unicorns',
    Custom: 'custom'
} as const;

export type BrandingPropertiesThemeKeyEnum = typeof BrandingPropertiesThemeKeyEnum[keyof typeof BrandingPropertiesThemeKeyEnum];

/**
 * 
 * @export
 * @interface BrandingPropertiesColors
 */
export interface BrandingPropertiesColors {
    /**
     * 
     * @type {string}
     * @memberof BrandingPropertiesColors
     */
    'primary'?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandingPropertiesColors
     */
    'secondary'?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandingPropertiesColors
     */
    'tertiary'?: string;
}
/**
 * 
 * @export
 * @interface BrandingPropertiesPoweredBy
 */
export interface BrandingPropertiesPoweredBy {
    /**
     * 
     * @type {string}
     * @memberof BrandingPropertiesPoweredBy
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandingPropertiesPoweredBy
     */
    'logo_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandingPropertiesPoweredBy
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface BrandingPropertiesPromoImages
 */
export interface BrandingPropertiesPromoImages {
    /**
     * 
     * @type {BrandingPropertiesPromoImagesMain}
     * @memberof BrandingPropertiesPromoImages
     */
    'main'?: BrandingPropertiesPromoImagesMain;
}
/**
 * 
 * @export
 * @interface BrandingPropertiesPromoImagesMain
 */
export interface BrandingPropertiesPromoImagesMain {
    /**
     * 
     * @type {Array<PromoImage>}
     * @memberof BrandingPropertiesPromoImagesMain
     */
    'images'?: Array<PromoImage>;
}
/**
 * 
 * @export
 * @interface BrandingPropertiesToggles
 */
export interface BrandingPropertiesToggles {
    /**
     * 
     * @type {boolean}
     * @memberof BrandingPropertiesToggles
     */
    'show_branded_tracking_link'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BrandingPropertiesToggles
     */
    'show_custom_colors'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BrandingPropertiesToggles
     */
    'show_social_media'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BrandingPropertiesToggles
     */
    'show_store_url'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BrandingPropertiesToggles
     */
    'show_menu_links'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BrandingPropertiesToggles
     */
    'show_return_policy'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BrandingPropertiesToggles
     */
    'show_email'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BrandingPropertiesToggles
     */
    'show_phone'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BrandingPropertiesToggles
     */
    'show_shipment_details'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BrandingPropertiesToggles
     */
    'show_item_images'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BrandingPropertiesToggles
     */
    'show_item_options'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BrandingPropertiesToggles
     */
    'show_shipment_prices'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BrandingPropertiesToggles
     */
    'show_store_address'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BrandingPropertiesToggles
     */
    'show_gift_link'?: boolean;
}
/**
 * A code representing a supported carrier
 * @export
 * @enum {string}
 */

export const Carrier = {
    AccessWorldwide: 'access_worldwide',
    Airtranss: 'airtranss',
    AmazonBuyShipping: 'amazon_buy_shipping',
    AmazonShipping: 'amazon_shipping',
    AmazonShippingUk: 'amazon_shipping_uk',
    AmazonShippingUs: 'amazon_shipping_us',
    AnPost: 'an_post',
    AngelLastMile: 'angel_last_mile',
    Apc: 'apc',
    ApcOvernight: 'apc_overnight',
    ApgEcommerce: 'apg_ecommerce',
    ApicodeDpdLocal: 'apicode-dpd-local',
    ApicodeDummyCarrier: 'apicode-dummy-carrier',
    Aramex: 'aramex',
    AramexAuWalleted: 'aramex_au_walleted',
    Asendia: 'asendia',
    AsendiaManifestToAsendia: 'asendia_manifest_to_asendia',
    AsendiaUk: 'asendia_uk',
    AustraliaPost: 'australia_post',
    AustraliaPostMypostBusiness: 'australia_post_mypost_business',
    AustraliaPostV2: 'australia_post_v2',
    Axlehire: 'axlehire',
    B2cEurope: 'b2c_europe',
    BetterTrucks: 'better_trucks',
    Boxberry: 'boxberry',
    BringParcels: 'bring_parcels',
    BrokersWorldwide: 'brokers_worldwide',
    BrtIt: 'brt_it',
    BrtItWalleted: 'brt_it_walleted',
    CanadaPost: 'canada_post',
    CanadaPostWalleted: 'canada_post_walleted',
    Canpar: 'canpar',
    CanparWalleted: 'canpar_walleted',
    CarrierOauthDemo: 'carrier_oauth_demo',
    CastleParcels: 'castle_parcels',
    Cdl: 'cdl',
    Chronopost: 'chronopost',
    CirroECommerce: 'cirro_e_commerce',
    ColisPrive: 'colis_prive',
    ColisPriveStore: 'colis_prive_store',
    Colissimo: 'colissimo',
    ColissimoC2c: 'colissimo_c2c',
    ColissimoWalleted: 'colissimo_walleted',
    Correos: 'correos',
    CorreosExpress: 'correos_express',
    CorreosExpressWalleted: 'correos_express_walleted',
    Courierpost: 'courierpost',
    CouriersPlease: 'couriers_please',
    CourierspleaseWalleted: 'couriersplease_walleted',
    CraneWorldwideLogistics: 'crane_worldwide_logistics',
    CustomLabels: 'custom_labels',
    Dai: 'dai',
    Delivengo: 'delivengo',
    DeutschePostCrossBorder: 'deutsche_post_cross_border',
    DeutschePostDhl: 'deutsche_post_dhl',
    DhlEcommerce: 'dhl_ecommerce',
    DhlEcommerceAu: 'dhl_ecommerce_au',
    DhlEcommerceUk: 'dhl_ecommerce_uk',
    DhlEcommerceWallet: 'dhl_ecommerce_wallet',
    DhlExpress: 'dhl_express',
    DhlExpressAustralia: 'dhl_express_australia',
    DhlExpressCanada: 'dhl_express_canada',
    DhlExpressDeWalleted: 'dhl_express_de_walleted',
    DhlExpressFromShipstation: 'dhl_express_from_shipstation',
    DhlExpressMydhl: 'dhl_express_mydhl',
    DhlExpressUk: 'dhl_express_uk',
    DhlExpressWalleted: 'dhl_express_walleted',
    DhlExpressWorldwide: 'dhl_express_worldwide',
    DhlGlobalMail: 'dhl_global_mail',
    DhlParcelEs: 'dhl_parcel_es',
    DhlParcelPolska: 'dhl_parcel_polska',
    DhlParcelUk: 'dhl_parcel_uk',
    DirectFreight: 'direct_freight',
    DirectLink: 'direct_link',
    DoordashUs: 'doordash_us',
    DoordashWalleted: 'doordash_walleted',
    Dpd: 'dpd',
    DpdBaltics: 'dpd_baltics',
    DpdFrance: 'dpd_france',
    DpdGermany: 'dpd_germany',
    DpdGermanyWalleted: 'dpd_germany_walleted',
    DpdHungary: 'dpd_hungary',
    DpdIreland: 'dpd_ireland',
    DpdNl: 'dpd_nl',
    DpdPoland: 'dpd_poland',
    DpdPortugal: 'dpd_portugal',
    DpdRomania: 'dpd_romania',
    Dpdwallet: 'dpdwallet',
    Dropoff: 'dropoff',
    DummyWalletedCarrier: 'dummy-walleted-carrier',
    DummyModule: 'dummy_module',
    Dx: 'dx',
    Ecms: 'ecms',
    EcomLogistics: 'ecom_logistics',
    Econt: 'econt',
    Endicia: 'endicia',
    Envialia: 'envialia',
    EpgShipping: 'epg_shipping',
    Estafeta: 'estafeta',
    EstafetaMexico: 'estafeta_mexico',
    EvriInternational: 'evri_international',
    Express1: 'express_1',
    Fairsenden: 'fairsenden',
    Fan: 'fan',
    FastDespatchLogistics: 'fast_despatch_logistics',
    FastwayAu: 'fastway_au',
    FastwayNz: 'fastway_nz',
    Fedex: 'fedex',
    FedexV4: 'fedex-v4',
    FedexCanada: 'fedex_canada',
    FedexFromParcelcast: 'fedex_from_parcelcast',
    FedexInternationalConnect: 'fedex_international_connect',
    FedexIntlMailservice: 'fedex_intl_mailservice',
    FedexUk: 'fedex_uk',
    FedexWalleted: 'fedex_walleted',
    Ferrari: 'ferrari',
    Firstmile: 'firstmile',
    Flashbox: 'flashbox',
    FleetOptics: 'fleet_optics',
    FoxPost: 'fox_post',
    Freightways: 'freightways',
    Furdeco: 'furdeco',
    GeisCz: 'geis_cz',
    GeodisMyparcel: 'geodis_myparcel',
    GlobalAccess: 'global_access',
    GlobalE: 'global_e',
    Globalpost: 'globalpost',
    Globegistics: 'globegistics',
    Glocally: 'glocally',
    GlsCanada: 'gls_canada',
    GlsFrance: 'gls_france',
    GlsGermany: 'gls_germany',
    GlsGermanyWalleted: 'gls_germany_walleted',
    GlsNl: 'gls_nl',
    GlsSpain: 'gls_spain',
    GlsUs: 'gls_us',
    GoBolt: 'go_bolt',
    Gophr: 'gophr',
    GrupoAmpm: 'grupo_ampm',
    Herewego: 'herewego',
    Hermes: 'hermes',
    HermesShipstationCarrierServices: 'hermes_-_shipstation_carrier_services',
    HermesGermany: 'hermes_germany',
    Hermescorp: 'hermescorp',
    Imex: 'imex',
    Indyship: 'indyship',
    Inpost: 'inpost',
    InpostIt: 'inpost_it',
    InpostPl: 'inpost_pl',
    IntelcomCa: 'intelcom_ca',
    IntelliquickDelivery: 'intelliquick_delivery',
    InternationalBondedCouriers: 'international_bonded_couriers',
    InternationalBridge: 'international_bridge',
    Interpost: 'interpost',
    IntersoftRoyalmail: 'intersoft_royalmail',
    Jet: 'jet',
    LaPosteColissimo: 'la_poste_colissimo',
    LandmarkGlobal: 'landmark_global',
    LandmarkGlobalAu: 'landmark_global_au',
    LandmarkGlobalCa: 'landmark_global_ca',
    LandmarkGlobalUk: 'landmark_global_uk',
    Lasership: 'lasership',
    LettreSuivie: 'lettre_suivie',
    Linex: 'linex',
    LoomisExpress: 'loomis_express',
    LotteGlobalLogistics: 'lotte_global_logistics',
    Lso: 'lso',
    MaerskB2c: 'maersk_b2c',
    Meest: 'meest',
    MercadoLibreShipping: 'mercado_libre_shipping',
    Metroland: 'metroland',
    MngKargo: 'mng_kargo',
    MondialRelay: 'mondial_relay',
    MondialRelayDc: 'mondial_relay_dc',
    N9minutos: 'n9minutos',
    Nationex: 'nationex',
    NbexExpressSerbia: 'nbex_express_serbia',
    Netparcel: 'netparcel',
    NewZealandCouriers: 'new_zealand_couriers',
    NewZealandPostInternational: 'new_zealand_post_international',
    Newgistics: 'newgistics',
    NinjaVan: 'ninja_van',
    Nobordist: 'nobordist',
    NovaGlobal: 'nova_global',
    NovaPoshta: 'nova_poshta',
    NowCouriers: 'now_couriers',
    OldNewZealandPostCourierpost: 'old_new_zealand_post_courierpost',
    OneWorldExpress: 'one_world_express',
    OntoCourier: 'onto_courier',
    Ontrac: 'ontrac',
    Openborder: 'openborder',
    Packeta: 'packeta',
    Packfleet: 'packfleet',
    Pandion: 'pandion',
    PaqueteExpress: 'paquete_express',
    Parcelforce: 'parcelforce',
    ParcelforceShipstationCarrierServices: 'parcelforce_-_shipstation_carrier_services',
    ParcelforceWalleted: 'parcelforce_walleted',
    ParcelforceWalletedShipstationCarrierServices: 'parcelforce_walleted_-_shipstation_carrier_services',
    Parcll: 'parcll',
    Parxl: 'parxl',
    PassportCarrier: 'passport_carrier',
    Pcf: 'pcf',
    Pickpoint: 'pickpoint',
    PocztexKurier: 'pocztex_kurier',
    PostHaste: 'post_haste',
    PostaSlovenije: 'posta_slovenije',
    PosteItaliane: 'poste_italiane',
    Postnl: 'postnl',
    Postnord: 'postnord',
    ProCarrier: 'pro_carrier',
    Pudopoint: 'pudopoint',
    PurolatorCa: 'purolator_ca',
    PurolatorCanada: 'purolator_canada',
    PurolatorInternational: 'purolator_international',
    PurolatorIntl: 'purolator_intl',
    PurolatorWalleted: 'purolator_walleted',
    Quantium: 'quantium',
    RedJePakketje: 'red_je_pakketje',
    Redpack: 'redpack',
    Relaytech: 'relaytech',
    Rivo: 'rivo',
    RoyalMail: 'royal_mail',
    RoyalMailClickAndDrop: 'royal_mail_click_and_drop',
    RrDonnelley: 'rr_donnelley',
    Seko: 'seko',
    SekoCa: 'seko_ca',
    SekoLtlWalleted: 'seko_ltl_walleted',
    SekoUk: 'seko_uk',
    SekoUs: 'seko_us',
    Sending: 'sending',
    Sendle: 'sendle',
    SendleWalleted: 'sendle_walleted',
    Seur: 'seur',
    SevenSenders: 'seven_senders',
    Shippie: 'shippie',
    Shippingchimp: 'shippingchimp',
    ShipsiFleet: 'shipsi_fleet',
    Shipx: 'shipx',
    Skynet: 'skynet',
    SkynetZa: 'skynet_za',
    Skypostal: 'skypostal',
    Speedy: 'speedy',
    Ss: 'ss',
    StallionExpress: 'stallion_express',
    StampsCom: 'stamps_com',
    StampsComWl: 'stamps_com_wl',
    StarTrack: 'star_track',
    Starlinks: 'starlinks',
    Swyft: 'swyft',
    SwyftWalleted: 'swyft_walleted',
    TforceLogistics: 'tforce_logistics',
    TheDeliveryGroupUk: 'the_delivery_group_uk',
    Tnt: 'tnt',
    TntAustralia: 'tnt_australia',
    TntFrance: 'tnt_france',
    TntUk: 'tnt_uk',
    TollIpec: 'toll_ipec',
    TollPriority: 'toll_priority',
    Tousfacteurs: 'tousfacteurs',
    Tusk: 'tusk',
    Uber: 'uber',
    UberWalleted: 'uber_walleted',
    UnitedDeliveryService: 'united_delivery_service',
    Ups: 'ups',
    UpsMailInnovations: 'ups_mail_innovations',
    UpsWalleted: 'ups_walleted',
    UrbIt: 'urb_it',
    Usps: 'usps',
    ViaDelivery: 'via_delivery',
    VoucherDhlExpress: 'voucher-dhl-express',
    VoucherDhlExpressWalleted: 'voucher-dhl-express-walleted',
    VoucherDhlecommerce: 'voucher-dhlecommerce',
    VoucherFedex: 'voucher-fedex',
    VoucherGeneric: 'voucher-generic',
    VoucherStamps: 'voucher-stamps',
    VoucherUps: 'voucher-ups',
    Whistl: 'whistl',
    Wizmo: 'wizmo',
    Yamato: 'yamato',
    Yodel: 'yodel',
    YodelCollectPlusC2c: 'yodel_collect_plus_c2c',
    YodelWalleted: 'yodel_walleted',
    ZiingCarrier: 'ziing_carrier'
} as const;

export type Carrier = typeof Carrier[keyof typeof Carrier];


/**
 * A block of custom content provided by the merchant to be included in branded notifications, such as a custom message about shipping delays due to COVID-19
 * @export
 * @interface ContentBlock
 */
export interface ContentBlock {
    /**
     * 
     * @type {string}
     * @memberof ContentBlock
     */
    'type': ContentBlockTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ContentBlock
     */
    'value': string;
}

export const ContentBlockTypeEnum = {
    Alert: 'alert'
} as const;

export type ContentBlockTypeEnum = typeof ContentBlockTypeEnum[keyof typeof ContentBlockTypeEnum];

/**
 * A request to create a new Branding resource
 * @export
 * @interface CreateBrandingRequest
 */
export interface CreateBrandingRequest {
    /**
     * 
     * @type {BrandingProperties}
     * @memberof CreateBrandingRequest
     */
    'branding': BrandingProperties;
}
/**
 * The result of a Branding creation request
 * @export
 * @interface CreateBrandingResponse
 */
export interface CreateBrandingResponse {
    /**
     * The unique identifier for the newly-created Branding resource
     * @type {string}
     * @memberof CreateBrandingResponse
     */
    'branding_id': string;
}
/**
 * A request to create a new NotificationSetting resource
 * @export
 * @interface CreateNotificationSettingRequest
 */
export interface CreateNotificationSettingRequest {
    /**
     * 
     * @type {NotificationProperties}
     * @memberof CreateNotificationSettingRequest
     */
    'notification_setting': NotificationProperties;
}
/**
 * The result of a NotificationSetting creation request
 * @export
 * @interface CreateNotificationSettingResponse
 */
export interface CreateNotificationSettingResponse {
    /**
     * The unique identifier for the newly-created NotificationSetting resource
     * @type {string}
     * @memberof CreateNotificationSettingResponse
     */
    'notification_setting_id': string;
}
/**
 * A request to create a new Shipment resource
 * @export
 * @interface CreateShipmentRequest
 */
export interface CreateShipmentRequest {
    /**
     * 
     * @type {ShipmentProperties}
     * @memberof CreateShipmentRequest
     */
    'shipment': ShipmentProperties;
}
/**
 * The result of a Shipment creation request
 * @export
 * @interface CreateShipmentResponse
 */
export interface CreateShipmentResponse {
    /**
     * The unique identifier for the newly-created Shipment resource
     * @type {string}
     * @memberof CreateShipmentResponse
     */
    'shipment_id': string;
}
/**
 * 
 * @export
 * @interface Currency
 */
export interface Currency {
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    'amount'?: string;
    /**
     * A three-letter ISO4217 code representing a supported currency
     * @type {string}
     * @memberof Currency
     */
    'currency_code'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DeliveryState = {
    ShipmentCreated: 'shipment_created',
    DeliveryDateAnnounced: 'delivery_date_announced',
    OutForDelivery: 'out_for_delivery',
    Delivered: 'delivered'
} as const;

export type DeliveryState = typeof DeliveryState[keyof typeof DeliveryState];


/**
 * 
 * @export
 * @interface EmailAddress
 */
export interface EmailAddress {
    /**
     * 
     * @type {string}
     * @memberof EmailAddress
     */
    'email_address': string;
}
/**
 * Contains information about an API error
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {ErrorResponseBody}
     * @memberof ErrorResponse
     */
    'error': ErrorResponseBody;
}
/**
 * Describes a particular API error with an error code and a message
 * @export
 * @interface ErrorResponseBody
 */
export interface ErrorResponseBody {
    /**
     * 
     * @type {string}
     * @memberof ErrorResponseBody
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponseBody
     */
    'message': string;
    /**
     * Contains any additonal nested errors
     * @type {Array<ErrorResponseBody>}
     * @memberof ErrorResponseBody
     */
    'details'?: Array<ErrorResponseBody>;
}
/**
 * Represents a link, including text and optional image
 * @export
 * @interface Link
 */
export interface Link {
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    'text': string;
}
/**
 * A code representing a supported notification channel
 * @export
 * @interface NotificationChannel
 */
export interface NotificationChannel {
    /**
     * 
     * @type {string}
     * @memberof NotificationChannel
     */
    'channel_type': NotificationChannelChannelTypeEnum;
}

export const NotificationChannelChannelTypeEnum = {
    Email: 'email'
} as const;

export type NotificationChannelChannelTypeEnum = typeof NotificationChannelChannelTypeEnum[keyof typeof NotificationChannelChannelTypeEnum];

/**
 * NotificationProperties that contain notification configuration and triggers
 * @export
 * @interface NotificationProperties
 */
export interface NotificationProperties {
    /**
     * 
     * @type {Array<NotificationTrigger>}
     * @memberof NotificationProperties
     */
    'triggers': Array<NotificationTrigger>;
    /**
     * 
     * @type {NotificationPropertiesEmailConfig}
     * @memberof NotificationProperties
     */
    'email_config'?: NotificationPropertiesEmailConfig;
    /**
     * Enable customers to opt-in to SMS notifications from the Branded Tracking page. SMS messages will be sent for `delivery_date_announced`, `out_for_delivery`, and `delivered` delivery states by default.
     * @type {boolean}
     * @memberof NotificationProperties
     */
    'sms_enabled'?: boolean;
}
/**
 * 
 * @export
 * @interface NotificationPropertiesEmailConfig
 */
export interface NotificationPropertiesEmailConfig {
    /**
     * 
     * @type {Array<ContentBlock>}
     * @memberof NotificationPropertiesEmailConfig
     */
    'content_blocks'?: Array<ContentBlock>;
    /**
     * 
     * @type {string}
     * @memberof NotificationPropertiesEmailConfig
     */
    'reply_to_email'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationPropertiesEmailConfig
     */
    'from_email'?: string;
    /**
     * 
     * @type {Array<EmailAddress>}
     * @memberof NotificationPropertiesEmailConfig
     */
    'bcc_emails'?: Array<EmailAddress>;
}
/**
 * A notification setting resources that has been saved in the Branded Tracking Service
 * @export
 * @interface NotificationSetting
 */
export interface NotificationSetting {
    /**
     * 
     * @type {string}
     * @memberof NotificationSetting
     */
    'notification_setting_id': string;
    /**
     * 
     * @type {Array<NotificationTrigger>}
     * @memberof NotificationSetting
     */
    'triggers': Array<NotificationTrigger>;
    /**
     * 
     * @type {NotificationPropertiesEmailConfig}
     * @memberof NotificationSetting
     */
    'email_config'?: NotificationPropertiesEmailConfig;
    /**
     * Enable customers to opt-in to SMS notifications from the Branded Tracking page. SMS messages will be sent for `delivery_date_announced`, `out_for_delivery`, and `delivered` delivery states by default.
     * @type {boolean}
     * @memberof NotificationSetting
     */
    'sms_enabled'?: boolean;
}
/**
 * 
 * @export
 * @interface NotificationSettingAllOf
 */
export interface NotificationSettingAllOf {
    /**
     * 
     * @type {string}
     * @memberof NotificationSettingAllOf
     */
    'notification_setting_id': string;
}
/**
 * A trigger for a branded notification
 * @export
 * @interface NotificationTrigger
 */
export interface NotificationTrigger {
    /**
     * 
     * @type {DeliveryState}
     * @memberof NotificationTrigger
     */
    'state': DeliveryState;
    /**
     * Channels to trigger notifications for this delivery state
     * @type {Array<NotificationChannel>}
     * @memberof NotificationTrigger
     */
    'channels': Array<NotificationChannel>;
}
/**
 * A code representing a supported order source
 * @export
 * @enum {string}
 */

export const OrderSourceCode = {
    Shipstation: 'shipstation',
    Ebay: 'ebay',
    Amazon: 'amazon',
    Rakuten: 'rakuten',
    Paypal: 'paypal',
    Volusion: 'volusion',
    Etsy: 'etsy',
    GoogleCheckout: 'google_checkout',
    ZenCart: 'zen_cart',
    Payvment: 'payvment',
    MagentoGo: 'magento_go',
    Bigcommerce: 'bigcommerce',
    Shopify: 'shopify',
    AmazonUk: 'amazon_uk',
    Oscommerce: 'oscommerce',
    XCart: 'x-cart',
    Channeladvisor: 'channeladvisor',
    Opencart: 'opencart',
    Memberly: 'memberly',
    Spree: 'spree',
    CustomStore: 'custom_store',
    _3dcart: '3dcart',
    Yahoo: 'yahoo',
    Sears: 'sears',
    StitchLabs: 'stitch_labs',
    ScoutTopshelf: 'scout_topshelf',
    Magento: 'magento',
    Bizelo: 'bizelo',
    Lemonstand: 'lemonstand',
    Highwire: 'highwire',
    AmazonCa: 'amazon_ca',
    Goodsie: 'goodsie',
    Newegg: 'newegg',
    Ultracart: 'ultracart',
    Woocommerce: 'woocommerce',
    Commerceinterface: 'commerceinterface',
    Abantecart: 'abantecart',
    Sophio: 'sophio',
    Storenvy: 'storenvy',
    Brightpearl: 'brightpearl',
    AmazonDe: 'amazon_de',
    AmazonEs: 'amazon_es',
    AmazonFr: 'amazon_fr',
    AmazonIt: 'amazon_it',
    EcwidV1: 'ecwid_v1',
    Redditgifts: 'redditgifts',
    Wish: 'wish',
    Soldsie: 'soldsie',
    BestBuy: 'best_buy',
    Prestashop: 'prestashop',
    EbayUk: 'ebay_uk',
    EbayCa: 'ebay_ca',
    MivaMerchant: 'miva_merchant',
    Jane: 'jane',
    Vault: 'vault',
    Squarespace: 'squarespace',
    BigcommerceV2: 'bigcommerce_v2',
    Americommerce: 'americommerce',
    Skuvault: 'skuvault',
    Foxycart: 'foxycart',
    CsCart: 'cs-cart',
    Bonanza: 'bonanza',
    Mijoshop: 'mijoshop',
    Selleractive: 'selleractive',
    Morecommerce: 'morecommerce',
    Tradegecko: 'tradegecko',
    GrouponGoods: 'groupon_goods',
    Reverb: 'reverb',
    Square: 'square',
    Choxi: 'choxi',
    AmazonJp: 'amazon_jp',
    NeweggBusiness: 'newegg_business',
    WoocommerceV2: 'woocommerce_v2',
    FullscreenDirect: 'fullscreen_direct',
    Celery: 'celery',
    Penny: 'penny',
    Groopdealz: 'groopdealz',
    Cratejoy: 'cratejoy',
    DearSystems: 'dear_systems',
    Freestyle: 'freestyle',
    Sellbrite: 'sellbrite',
    Hatch: 'hatch',
    Zoey: 'zoey',
    Linnworks: 'linnworks',
    BigCartel: 'big_cartel',
    AmazonMx: 'amazon_mx',
    Suredone: 'suredone',
    Securestore: 'securestore',
    NeweggCa: 'newegg_ca',
    Spreesy: 'spreesy',
    Ecwid: 'ecwid',
    Walmart: 'walmart',
    Mozu: 'mozu',
    Stripe: 'stripe',
    Tanga: 'tanga',
    Weebly: 'weebly',
    Labelapi: 'labelapi',
    EbayAu: 'ebay_au',
    Houzz: 'houzz',
    Tophatter: 'tophatter',
    Odbc: 'odbc',
    SparkShipping: 'spark_shipping',
    Channelsale: 'channelsale',
    Corecommerce: 'corecommerce',
    Geekseller: 'geekseller',
    SapAnywhere: 'sap_anywhere',
    Cin7: 'cin7',
    Bandcamp: 'bandcamp',
    Jet: 'jet',
    SalesforceCommerceCloud: 'salesforce_commerce_cloud',
    Revolutionparts: 'revolutionparts',
    AmazonAu: 'amazon_au',
    Handshake: 'handshake',
    Acumatica: 'acumatica',
    Facebook: 'facebook',
    RateBrowser: 'rate_browser',
    ShipengineApi: 'shipengine_api',
    Wix: 'wix',
    Overstock: 'overstock',
    Listingmirror: 'listingmirror',
    Edesk: 'edesk',
    Gorgias: 'gorgias',
    OrderTime: 'order_time',
    Volo: 'volo',
    Inksoft: 'inksoft',
    BestBuyCa: 'best_buy_ca',
    AcumaticaV2: 'acumatica_v2',
    Visualsoft: 'visualsoft',
    WalmartCa: 'walmart_ca',
    SalesforceCore: 'salesforce_core',
    Notonthehighstreet: 'notonthehighstreet',
    ShipengineMerchant: 'shipengine_merchant',
    UnbrandedEcommerceStore: 'unbranded_ecommerce_store',
    Cdiscount: 'cdiscount',
    Alibaba: 'alibaba',
    EbayFr: 'ebay_fr',
    Oxatis: 'oxatis',
    Google: 'google',
    EbayV2: 'ebay_v2',
    Catch: 'catch',
    Darty: 'darty',
    Neto: 'neto',
    PaypalV2: 'paypal_v2',
    OrdersourceApi: 'ordersource_api',
    Fnac: 'fnac',
    Fanatics: 'fanatics',
    Kohls: 'kohls',
    Kroger: 'kroger',
    Urbn: 'urbn',
    AuctaneOrders: 'auctane-orders',
    ShopPremiumOutlets: 'shop_premium_outlets',
    Shopwired: 'shopwired',
    Lengow: 'lengow',
    Simplepart: 'simplepart',
    Liftoff: 'liftoff',
    OauthDummy: 'oauth_dummy',
    MercadoLibre: 'mercado_libre',
    DummyMarketplace: 'dummy-marketplace',
    Paparazzi: 'paparazzi'
} as const;

export type OrderSourceCode = typeof OrderSourceCode[keyof typeof OrderSourceCode];


/**
 * NotificationProperties that contain notification configuration and triggers
 * @export
 * @interface PatchNotificationProperties
 */
export interface PatchNotificationProperties {
    /**
     * 
     * @type {Array<NotificationTrigger>}
     * @memberof PatchNotificationProperties
     */
    'triggers'?: Array<NotificationTrigger>;
    /**
     * 
     * @type {PatchNotificationPropertiesEmailConfig}
     * @memberof PatchNotificationProperties
     */
    'email_config'?: PatchNotificationPropertiesEmailConfig | null;
    /**
     * Enable customers to opt-in to SMS notifications from the Branded Tracking page. SMS messages will be sent for `delivery_date_announced`, `out_for_delivery`, and `delivered` delivery states by default.
     * @type {boolean}
     * @memberof PatchNotificationProperties
     */
    'sms_enabled'?: boolean | null;
}
/**
 * 
 * @export
 * @interface PatchNotificationPropertiesEmailConfig
 */
export interface PatchNotificationPropertiesEmailConfig {
    /**
     * 
     * @type {Array<ContentBlock>}
     * @memberof PatchNotificationPropertiesEmailConfig
     */
    'content_blocks'?: Array<ContentBlock> | null;
    /**
     * 
     * @type {string}
     * @memberof PatchNotificationPropertiesEmailConfig
     */
    'reply_to_email'?: string | null;
    /**
     * 
     * @type {Array<EmailAddress>}
     * @memberof PatchNotificationPropertiesEmailConfig
     */
    'bcc_emails'?: Array<EmailAddress> | null;
}
/**
 * A request to patch the properties of an existing Notification Settings resource
 * @export
 * @interface PatchNotificationSettingRequest
 */
export interface PatchNotificationSettingRequest {
    /**
     * 
     * @type {PatchNotificationProperties}
     * @memberof PatchNotificationSettingRequest
     */
    'notification_setting'?: PatchNotificationProperties;
}
/**
 * The result of an PatchNotificationSettingRequest
 * @export
 * @interface PatchNotificationSettingResponse
 */
export interface PatchNotificationSettingResponse {
    /**
     * 
     * @type {string}
     * @memberof PatchNotificationSettingResponse
     */
    'notification_setting_id': string;
}
/**
 * The properties for a promo image
 * @export
 * @interface PromoImage
 */
export interface PromoImage {
    /**
     * 
     * @type {string}
     * @memberof PromoImage
     */
    'image_url': string;
    /**
     * 
     * @type {string}
     * @memberof PromoImage
     */
    'size_type': PromoImageSizeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PromoImage
     */
    'link_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof PromoImage
     */
    'title_text'?: string;
    /**
     * 
     * @type {PromoImageCrop}
     * @memberof PromoImage
     */
    'crop': PromoImageCrop;
}

export const PromoImageSizeTypeEnum = {
    Short: 'short',
    Tall: 'tall'
} as const;

export type PromoImageSizeTypeEnum = typeof PromoImageSizeTypeEnum[keyof typeof PromoImageSizeTypeEnum];

/**
 * Contains properties on how to crop an image. Contains the starting coordinates and size of the cropped image.
 * @export
 * @interface PromoImageCrop
 */
export interface PromoImageCrop {
    /**
     * 
     * @type {number}
     * @memberof PromoImageCrop
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof PromoImageCrop
     */
    'y': number;
    /**
     * 
     * @type {number}
     * @memberof PromoImageCrop
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof PromoImageCrop
     */
    'height': number;
}
/**
 * A shipment resource that has been saved in the Branded Tracking Service
 * @export
 * @interface Shipment
 */
export interface Shipment {
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    'shipment_id': string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    'tracking_number': string;
    /**
     * Marker that indicates the shipment is participating in carbon offsetting
     * @type {boolean}
     * @memberof Shipment
     */
    'carbon_neutral'?: boolean;
    /**
     * 
     * @type {Carrier}
     * @memberof Shipment
     */
    'carrier_code': Carrier;
    /**
     * 
     * @type {OrderSourceCode}
     * @memberof Shipment
     */
    'order_source_code'?: OrderSourceCode;
    /**
     * Typically a two or three letter language code, sometimes followed by optional subcodes for regions, etc.  See https://www.rfc-editor.org/rfc/bcp/bcp47.txt
     * @type {string}
     * @memberof Shipment
     */
    'locale'?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    'service_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    'tracking_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    'external_account_id': string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    'order_number'?: string;
    /**
     * An array of unique identifiers for the orders/sales_orders present during checkout and provided during SMS opt-in from the marketplace (e.g. the order id from Shopify). This should not be the primary key for an order from a GTM application. MVP implementation only considers the first order_id in the array, but will allow for multiple orders in a single shipment in the future.
     * @type {Array<string>}
     * @memberof Shipment
     */
    'order_ids'?: Array<string>;
    /**
     * A three-letter ISO4217 code representing a supported currency
     * @type {string}
     * @memberof Shipment
     */
    'currency_code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    'weight_in_ounces'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Shipment
     */
    'signature_required'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    'confirmation_requirement'?: ShipmentConfirmationRequirementEnum;
    /**
     * The date for which the purchased label was scheduled to be shipped
     * @type {string}
     * @memberof Shipment
     * @deprecated
     */
    'shipped_date'?: string;
    /**
     * An indicator if this is a return shipment. Return shipments will not trigger branded notifications.
     * @type {boolean}
     * @memberof Shipment
     */
    'is_return'?: boolean;
    /**
     * 
     * @type {Currency}
     * @memberof Shipment
     */
    'subtotal_price'?: Currency;
    /**
     * 
     * @type {Currency}
     * @memberof Shipment
     */
    'shipping_and_handling_price'?: Currency;
    /**
     * 
     * @type {Currency}
     * @memberof Shipment
     */
    'total_tax'?: Currency;
    /**
     * 
     * @type {Currency}
     * @memberof Shipment
     */
    'total_discount'?: Currency;
    /**
     * 
     * @type {Currency}
     * @memberof Shipment
     */
    'total_price'?: Currency;
    /**
     * Required for branded notifications if \'notification_setting_id\' is present
     * @type {string}
     * @memberof Shipment
     */
    'branding_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    'notification_setting_id'?: string | null;
    /**
     * The sender name used for branded emails. Appears as the name of who the branded email is coming from. Required if a \'notification_setting_id\' is present
     * @type {string}
     * @memberof Shipment
     */
    'email_sender_name'?: string;
    /**
     * 
     * @type {Address}
     * @memberof Shipment
     */
    'store_address'?: Address;
    /**
     * 
     * @type {Address}
     * @memberof Shipment
     */
    'destination': Address;
    /**
     * Details about the items in the shipment
     * @type {Array<ShipmentItem>}
     * @memberof Shipment
     */
    'line_items'?: Array<ShipmentItem>;
}

export const ShipmentConfirmationRequirementEnum = {
    Adult: 'adult',
    Signature: 'signature',
    Delivery: 'delivery',
    Null: 'null'
} as const;

export type ShipmentConfirmationRequirementEnum = typeof ShipmentConfirmationRequirementEnum[keyof typeof ShipmentConfirmationRequirementEnum];

/**
 * 
 * @export
 * @interface ShipmentAllOf
 */
export interface ShipmentAllOf {
    /**
     * 
     * @type {string}
     * @memberof ShipmentAllOf
     */
    'shipment_id': string;
}
/**
 * Represents a line-item product in a Shipment
 * @export
 * @interface ShipmentItem
 */
export interface ShipmentItem {
    /**
     * 
     * @type {number}
     * @memberof ShipmentItem
     */
    'quantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShipmentItem
     * @deprecated
     */
    'unit_price_cents'?: number;
    /**
     * 
     * @type {Currency}
     * @memberof ShipmentItem
     */
    'unit_price'?: Currency;
    /**
     * 
     * @type {number}
     * @memberof ShipmentItem
     * @deprecated
     */
    'subtotal_cents'?: number;
    /**
     * 
     * @type {Currency}
     * @memberof ShipmentItem
     */
    'subtotal_price'?: Currency;
    /**
     * 
     * @type {string}
     * @memberof ShipmentItem
     */
    'product_name'?: string;
    /**
     * 
     * @type {ShipmentItemImages}
     * @memberof ShipmentItem
     */
    'image_urls'?: ShipmentItemImages;
    /**
     * 
     * @type {Array<ShipmentItemOption>}
     * @memberof ShipmentItem
     */
    'options'?: Array<ShipmentItemOption>;
    /**
     * 
     * @type {string}
     * @memberof ShipmentItem
     */
    'description'?: string;
}
/**
 * Links to product images for a ShipmentItem
 * @export
 * @interface ShipmentItemImages
 */
export interface ShipmentItemImages {
    /**
     * 
     * @type {string}
     * @memberof ShipmentItemImages
     */
    'original': string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentItemImages
     */
    'medium'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentItemImages
     */
    'thumb'?: string;
}
/**
 * A key/value pair for custom shipment item options
 * @export
 * @interface ShipmentItemOption
 */
export interface ShipmentItemOption {
    /**
     * 
     * @type {string}
     * @memberof ShipmentItemOption
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentItemOption
     */
    'value': string;
}
/**
 * A shipment that includes a tracking number and carrier information
 * @export
 * @interface ShipmentProperties
 */
export interface ShipmentProperties {
    /**
     * 
     * @type {string}
     * @memberof ShipmentProperties
     */
    'tracking_number': string;
    /**
     * Marker that indicates the shipment is participating in carbon offsetting
     * @type {boolean}
     * @memberof ShipmentProperties
     */
    'carbon_neutral'?: boolean;
    /**
     * 
     * @type {Carrier}
     * @memberof ShipmentProperties
     */
    'carrier_code': Carrier;
    /**
     * 
     * @type {OrderSourceCode}
     * @memberof ShipmentProperties
     */
    'order_source_code'?: OrderSourceCode;
    /**
     * Typically a two or three letter language code, sometimes followed by optional subcodes for regions, etc.  See https://www.rfc-editor.org/rfc/bcp/bcp47.txt
     * @type {string}
     * @memberof ShipmentProperties
     */
    'locale'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentProperties
     */
    'service_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentProperties
     */
    'tracking_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentProperties
     */
    'external_account_id': string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentProperties
     */
    'order_number'?: string;
    /**
     * An array of unique identifiers for the orders/sales_orders present during checkout and provided during SMS opt-in from the marketplace (e.g. the order id from Shopify). This should not be the primary key for an order from a GTM application. MVP implementation only considers the first order_id in the array, but will allow for multiple orders in a single shipment in the future.
     * @type {Array<string>}
     * @memberof ShipmentProperties
     */
    'order_ids'?: Array<string>;
    /**
     * A three-letter ISO4217 code representing a supported currency
     * @type {string}
     * @memberof ShipmentProperties
     */
    'currency_code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShipmentProperties
     */
    'weight_in_ounces'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ShipmentProperties
     */
    'signature_required'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShipmentProperties
     */
    'confirmation_requirement'?: ShipmentPropertiesConfirmationRequirementEnum;
    /**
     * The date for which the purchased label was scheduled to be shipped
     * @type {string}
     * @memberof ShipmentProperties
     * @deprecated
     */
    'shipped_date'?: string;
    /**
     * An indicator if this is a return shipment. Return shipments will not trigger branded notifications.
     * @type {boolean}
     * @memberof ShipmentProperties
     */
    'is_return'?: boolean;
    /**
     * 
     * @type {Currency}
     * @memberof ShipmentProperties
     */
    'subtotal_price'?: Currency;
    /**
     * 
     * @type {Currency}
     * @memberof ShipmentProperties
     */
    'shipping_and_handling_price'?: Currency;
    /**
     * 
     * @type {Currency}
     * @memberof ShipmentProperties
     */
    'total_tax'?: Currency;
    /**
     * 
     * @type {Currency}
     * @memberof ShipmentProperties
     */
    'total_discount'?: Currency;
    /**
     * 
     * @type {Currency}
     * @memberof ShipmentProperties
     */
    'total_price'?: Currency;
    /**
     * Required for branded notifications if \'notification_setting_id\' is present
     * @type {string}
     * @memberof ShipmentProperties
     */
    'branding_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShipmentProperties
     */
    'notification_setting_id'?: string | null;
    /**
     * The sender name used for branded emails. Appears as the name of who the branded email is coming from. Required if a \'notification_setting_id\' is present
     * @type {string}
     * @memberof ShipmentProperties
     */
    'email_sender_name'?: string;
    /**
     * 
     * @type {Address}
     * @memberof ShipmentProperties
     */
    'store_address'?: Address;
    /**
     * 
     * @type {Address}
     * @memberof ShipmentProperties
     */
    'destination': Address;
    /**
     * Details about the items in the shipment
     * @type {Array<ShipmentItem>}
     * @memberof ShipmentProperties
     */
    'line_items'?: Array<ShipmentItem>;
}

export const ShipmentPropertiesConfirmationRequirementEnum = {
    Adult: 'adult',
    Signature: 'signature',
    Delivery: 'delivery',
    Null: 'null'
} as const;

export type ShipmentPropertiesConfirmationRequirementEnum = typeof ShipmentPropertiesConfirmationRequirementEnum[keyof typeof ShipmentPropertiesConfirmationRequirementEnum];

/**
 * A link to a supported social media profile
 * @export
 * @interface SocialLink
 */
export interface SocialLink {
    /**
     * 
     * @type {string}
     * @memberof SocialLink
     */
    'type': SocialLinkTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SocialLink
     */
    'url': string;
}

export const SocialLinkTypeEnum = {
    Twitter: 'twitter',
    Instagram: 'instagram',
    Youtube: 'youtube',
    Pinterest: 'pinterest',
    Tiktok: 'tiktok',
    Facebook: 'facebook',
    Linkedin: 'linkedin'
} as const;

export type SocialLinkTypeEnum = typeof SocialLinkTypeEnum[keyof typeof SocialLinkTypeEnum];

/**
 * Shipment tracking information
 * @export
 * @interface Tracking
 */
export interface Tracking {
    [key: string]: object | any;

    /**
     * The carrier-provided tracking number used to identify a shipment
     * @type {string}
     * @memberof Tracking
     */
    'tracking_number': string;
    /**
     * A normalized status code
     * @type {string}
     * @memberof Tracking
     */
    'status_code'?: TrackingStatusCodeEnum;
    /**
     * A friendly description of the normalized status code
     * @type {string}
     * @memberof Tracking
     */
    'status_description'?: string;
    /**
     * A carrier-specific status code
     * @type {string}
     * @memberof Tracking
     */
    'carrier_status_code'?: string;
    /**
     * A carrier-specific status description
     * @type {string}
     * @memberof Tracking
     */
    'carrier_status_description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tracking
     */
    'ship_date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Tracking
     */
    'estimated_delivery_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tracking
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tracking
     */
    'actual_delivery_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tracking
     */
    'exception_description'?: string | null;
    /**
     * 
     * @type {Array<TrackingEvent>}
     * @memberof Tracking
     */
    'events'?: Array<TrackingEvent>;
}

export const TrackingStatusCodeEnum = {
    Ac: 'AC',
    It: 'IT',
    De: 'DE',
    Ex: 'EX',
    Un: 'UN',
    At: 'AT',
    Ny: 'NY'
} as const;

export type TrackingStatusCodeEnum = typeof TrackingStatusCodeEnum[keyof typeof TrackingStatusCodeEnum];

/**
 * Shipment tracking information
 * @export
 * @interface TrackingEvent
 */
export interface TrackingEvent {
    /**
     * 
     * @type {string}
     * @memberof TrackingEvent
     */
    'occurred_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrackingEvent
     */
    'carrier_occurred_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrackingEvent
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrackingEvent
     */
    'city_locality'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrackingEvent
     */
    'state_province'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrackingEvent
     */
    'postal_code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrackingEvent
     */
    'country_code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrackingEvent
     */
    'company_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrackingEvent
     */
    'signer'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrackingEvent
     */
    'event_code'?: string;
    /**
     * 
     * @type {number}
     * @memberof TrackingEvent
     */
    'latitude'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TrackingEvent
     */
    'longitude'?: number | null;
}
/**
 * A request to update the properties of an existing Branding resource, or create a new one if none exists with the specified branding_id
 * @export
 * @interface UpdateBrandingRequest
 */
export interface UpdateBrandingRequest {
    /**
     * 
     * @type {BrandingProperties}
     * @memberof UpdateBrandingRequest
     */
    'branding': BrandingProperties;
}
/**
 * The result of an UpdateBrandingRequest
 * @export
 * @interface UpdateBrandingResponse
 */
export interface UpdateBrandingResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandingResponse
     */
    'branding_id': string;
}
/**
 * A request to update the properties of an existing Notification Settings resource
 * @export
 * @interface UpdateNotificationSettingRequest
 */
export interface UpdateNotificationSettingRequest {
    /**
     * 
     * @type {NotificationProperties}
     * @memberof UpdateNotificationSettingRequest
     */
    'notification_setting': NotificationProperties;
}
/**
 * The result of an UpdateNotificationSettingRequest
 * @export
 * @interface UpdateNotificationSettingResponse
 */
export interface UpdateNotificationSettingResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateNotificationSettingResponse
     */
    'notification_setting_id': string;
}
/**
 * A request to update the properties of an existing Shipment resource
 * @export
 * @interface UpdateShipmentRequest
 */
export interface UpdateShipmentRequest {
    /**
     * 
     * @type {ShipmentProperties}
     * @memberof UpdateShipmentRequest
     */
    'shipment': ShipmentProperties;
}
/**
 * The result of an UpdateShipmentRequest
 * @export
 * @interface UpdateShipmentResponse
 */
export interface UpdateShipmentResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateShipmentResponse
     */
    'shipment_id': string;
}

/**
 * BrandingApi - axios parameter creator
 * @export
 */
export const BrandingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new Branding resource
         * @summary Create Branding
         * @param {CreateBrandingRequest} [createBrandingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBranding: async (createBrandingRequest?: CreateBrandingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/branding`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Application-API-Key required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication On-Behalf-Of required
            await setApiKeyToObject(localVarHeaderParameter, "On-Behalf-Of", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBrandingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new shared Application level Branding resource. This resource can be accessed by any tenant of the application
         * @summary Create Shared Branding
         * @param {CreateBrandingRequest} [createBrandingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSharedBranding: async (createBrandingRequest?: CreateBrandingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/shared/branding`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Application-API-Key required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBrandingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve an existing Branding resource
         * @summary Get Branding
         * @param {string} brandingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBranding: async (brandingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandingId' is not null or undefined
            assertParamExists('getBranding', 'brandingId', brandingId)
            const localVarPath = `/branding/{branding_id}`
                .replace(`{${"branding_id"}}`, encodeURIComponent(String(brandingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Application-API-Key required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication On-Behalf-Of required
            await setApiKeyToObject(localVarHeaderParameter, "On-Behalf-Of", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve the SMS eligibility of an existing Branding resource
         * @summary Get SMS Eligibility for Branding
         * @param {string} brandingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrandingSmsEligibility: async (brandingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandingId' is not null or undefined
            assertParamExists('getBrandingSmsEligibility', 'brandingId', brandingId)
            const localVarPath = `/sms-eligibility/branding/{branding_id}`
                .replace(`{${"branding_id"}}`, encodeURIComponent(String(brandingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Application-API-Key required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication On-Behalf-Of required
            await setApiKeyToObject(localVarHeaderParameter, "On-Behalf-Of", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an existing Branding resource, or creates a new one with the specified branding_id if one does not exist
         * @summary Update Branding
         * @param {string} brandingId 
         * @param {UpdateBrandingRequest} [updateBrandingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBranding: async (brandingId: string, updateBrandingRequest?: UpdateBrandingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandingId' is not null or undefined
            assertParamExists('updateBranding', 'brandingId', brandingId)
            const localVarPath = `/branding/{branding_id}`
                .replace(`{${"branding_id"}}`, encodeURIComponent(String(brandingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Application-API-Key required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication On-Behalf-Of required
            await setApiKeyToObject(localVarHeaderParameter, "On-Behalf-Of", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBrandingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BrandingApi - functional programming interface
 * @export
 */
export const BrandingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BrandingApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new Branding resource
         * @summary Create Branding
         * @param {CreateBrandingRequest} [createBrandingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBranding(createBrandingRequest?: CreateBrandingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateBrandingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBranding(createBrandingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new shared Application level Branding resource. This resource can be accessed by any tenant of the application
         * @summary Create Shared Branding
         * @param {CreateBrandingRequest} [createBrandingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSharedBranding(createBrandingRequest?: CreateBrandingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateBrandingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSharedBranding(createBrandingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve an existing Branding resource
         * @summary Get Branding
         * @param {string} brandingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBranding(brandingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Branding>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBranding(brandingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve the SMS eligibility of an existing Branding resource
         * @summary Get SMS Eligibility for Branding
         * @param {string} brandingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBrandingSmsEligibility(brandingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandingSmsEligibility(brandingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates an existing Branding resource, or creates a new one with the specified branding_id if one does not exist
         * @summary Update Branding
         * @param {string} brandingId 
         * @param {UpdateBrandingRequest} [updateBrandingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBranding(brandingId: string, updateBrandingRequest?: UpdateBrandingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateBrandingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBranding(brandingId, updateBrandingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BrandingApi - factory interface
 * @export
 */
export const BrandingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BrandingApiFp(configuration)
    return {
        /**
         * Creates a new Branding resource
         * @summary Create Branding
         * @param {CreateBrandingRequest} [createBrandingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBranding(createBrandingRequest?: CreateBrandingRequest, options?: any): AxiosPromise<CreateBrandingResponse> {
            return localVarFp.createBranding(createBrandingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new shared Application level Branding resource. This resource can be accessed by any tenant of the application
         * @summary Create Shared Branding
         * @param {CreateBrandingRequest} [createBrandingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSharedBranding(createBrandingRequest?: CreateBrandingRequest, options?: any): AxiosPromise<CreateBrandingResponse> {
            return localVarFp.createSharedBranding(createBrandingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve an existing Branding resource
         * @summary Get Branding
         * @param {string} brandingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBranding(brandingId: string, options?: any): AxiosPromise<Branding> {
            return localVarFp.getBranding(brandingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve the SMS eligibility of an existing Branding resource
         * @summary Get SMS Eligibility for Branding
         * @param {string} brandingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrandingSmsEligibility(brandingId: string, options?: any): AxiosPromise<object> {
            return localVarFp.getBrandingSmsEligibility(brandingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an existing Branding resource, or creates a new one with the specified branding_id if one does not exist
         * @summary Update Branding
         * @param {string} brandingId 
         * @param {UpdateBrandingRequest} [updateBrandingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBranding(brandingId: string, updateBrandingRequest?: UpdateBrandingRequest, options?: any): AxiosPromise<UpdateBrandingResponse> {
            return localVarFp.updateBranding(brandingId, updateBrandingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BrandingApi - object-oriented interface
 * @export
 * @class BrandingApi
 * @extends {BaseAPI}
 */
export class BrandingApi extends BaseAPI {
    /**
     * Creates a new Branding resource
     * @summary Create Branding
     * @param {CreateBrandingRequest} [createBrandingRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandingApi
     */
    public createBranding(createBrandingRequest?: CreateBrandingRequest, options?: AxiosRequestConfig) {
        return BrandingApiFp(this.configuration).createBranding(createBrandingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new shared Application level Branding resource. This resource can be accessed by any tenant of the application
     * @summary Create Shared Branding
     * @param {CreateBrandingRequest} [createBrandingRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandingApi
     */
    public createSharedBranding(createBrandingRequest?: CreateBrandingRequest, options?: AxiosRequestConfig) {
        return BrandingApiFp(this.configuration).createSharedBranding(createBrandingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve an existing Branding resource
     * @summary Get Branding
     * @param {string} brandingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandingApi
     */
    public getBranding(brandingId: string, options?: AxiosRequestConfig) {
        return BrandingApiFp(this.configuration).getBranding(brandingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve the SMS eligibility of an existing Branding resource
     * @summary Get SMS Eligibility for Branding
     * @param {string} brandingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandingApi
     */
    public getBrandingSmsEligibility(brandingId: string, options?: AxiosRequestConfig) {
        return BrandingApiFp(this.configuration).getBrandingSmsEligibility(brandingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an existing Branding resource, or creates a new one with the specified branding_id if one does not exist
     * @summary Update Branding
     * @param {string} brandingId 
     * @param {UpdateBrandingRequest} [updateBrandingRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandingApi
     */
    public updateBranding(brandingId: string, updateBrandingRequest?: UpdateBrandingRequest, options?: AxiosRequestConfig) {
        return BrandingApiFp(this.configuration).updateBranding(brandingId, updateBrandingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationSettingApi - axios parameter creator
 * @export
 */
export const NotificationSettingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new NotificationSetting resource
         * @summary Create NotificationSetting
         * @param {CreateNotificationSettingRequest} [createNotificationSettingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNotificationSetting: async (createNotificationSettingRequest?: CreateNotificationSettingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notification-setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Application-API-Key required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication On-Behalf-Of required
            await setApiKeyToObject(localVarHeaderParameter, "On-Behalf-Of", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createNotificationSettingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new shared Application level NotificationSetting resource. This resource can be accessed by any tenant of the application.
         * @summary Create Shared NotificationSetting
         * @param {CreateNotificationSettingRequest} [createNotificationSettingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSharedNotificationSetting: async (createNotificationSettingRequest?: CreateNotificationSettingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/shared/notification-setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Application-API-Key required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createNotificationSettingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve an existing NotificationSetting resource
         * @summary Get NotificationSetting
         * @param {string} notificationSettingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationSetting: async (notificationSettingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationSettingId' is not null or undefined
            assertParamExists('getNotificationSetting', 'notificationSettingId', notificationSettingId)
            const localVarPath = `/notification-setting/{notification_setting_id}`
                .replace(`{${"notification_setting_id"}}`, encodeURIComponent(String(notificationSettingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Application-API-Key required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication On-Behalf-Of required
            await setApiKeyToObject(localVarHeaderParameter, "On-Behalf-Of", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an existing Notification resource
         * @summary Patch NotificationSetting
         * @param {string} notificationSettingId 
         * @param {PatchNotificationSettingRequest} [patchNotificationSettingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNotificationSetting: async (notificationSettingId: string, patchNotificationSettingRequest?: PatchNotificationSettingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationSettingId' is not null or undefined
            assertParamExists('patchNotificationSetting', 'notificationSettingId', notificationSettingId)
            const localVarPath = `/notification-setting/{notification_setting_id}`
                .replace(`{${"notification_setting_id"}}`, encodeURIComponent(String(notificationSettingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Application-API-Key required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication On-Behalf-Of required
            await setApiKeyToObject(localVarHeaderParameter, "On-Behalf-Of", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchNotificationSettingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an existing Notification resource
         * @summary Update NotificationSetting
         * @param {string} notificationSettingId 
         * @param {UpdateNotificationSettingRequest} [updateNotificationSettingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotificationSetting: async (notificationSettingId: string, updateNotificationSettingRequest?: UpdateNotificationSettingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationSettingId' is not null or undefined
            assertParamExists('updateNotificationSetting', 'notificationSettingId', notificationSettingId)
            const localVarPath = `/notification-setting/{notification_setting_id}`
                .replace(`{${"notification_setting_id"}}`, encodeURIComponent(String(notificationSettingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Application-API-Key required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication On-Behalf-Of required
            await setApiKeyToObject(localVarHeaderParameter, "On-Behalf-Of", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateNotificationSettingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationSettingApi - functional programming interface
 * @export
 */
export const NotificationSettingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationSettingApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new NotificationSetting resource
         * @summary Create NotificationSetting
         * @param {CreateNotificationSettingRequest} [createNotificationSettingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNotificationSetting(createNotificationSettingRequest?: CreateNotificationSettingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateNotificationSettingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNotificationSetting(createNotificationSettingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new shared Application level NotificationSetting resource. This resource can be accessed by any tenant of the application.
         * @summary Create Shared NotificationSetting
         * @param {CreateNotificationSettingRequest} [createNotificationSettingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSharedNotificationSetting(createNotificationSettingRequest?: CreateNotificationSettingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateNotificationSettingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSharedNotificationSetting(createNotificationSettingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve an existing NotificationSetting resource
         * @summary Get NotificationSetting
         * @param {string} notificationSettingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotificationSetting(notificationSettingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationSetting>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotificationSetting(notificationSettingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates an existing Notification resource
         * @summary Patch NotificationSetting
         * @param {string} notificationSettingId 
         * @param {PatchNotificationSettingRequest} [patchNotificationSettingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchNotificationSetting(notificationSettingId: string, patchNotificationSettingRequest?: PatchNotificationSettingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatchNotificationSettingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchNotificationSetting(notificationSettingId, patchNotificationSettingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates an existing Notification resource
         * @summary Update NotificationSetting
         * @param {string} notificationSettingId 
         * @param {UpdateNotificationSettingRequest} [updateNotificationSettingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNotificationSetting(notificationSettingId: string, updateNotificationSettingRequest?: UpdateNotificationSettingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateNotificationSettingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNotificationSetting(notificationSettingId, updateNotificationSettingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationSettingApi - factory interface
 * @export
 */
export const NotificationSettingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationSettingApiFp(configuration)
    return {
        /**
         * Creates a new NotificationSetting resource
         * @summary Create NotificationSetting
         * @param {CreateNotificationSettingRequest} [createNotificationSettingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNotificationSetting(createNotificationSettingRequest?: CreateNotificationSettingRequest, options?: any): AxiosPromise<CreateNotificationSettingResponse> {
            return localVarFp.createNotificationSetting(createNotificationSettingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new shared Application level NotificationSetting resource. This resource can be accessed by any tenant of the application.
         * @summary Create Shared NotificationSetting
         * @param {CreateNotificationSettingRequest} [createNotificationSettingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSharedNotificationSetting(createNotificationSettingRequest?: CreateNotificationSettingRequest, options?: any): AxiosPromise<CreateNotificationSettingResponse> {
            return localVarFp.createSharedNotificationSetting(createNotificationSettingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve an existing NotificationSetting resource
         * @summary Get NotificationSetting
         * @param {string} notificationSettingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationSetting(notificationSettingId: string, options?: any): AxiosPromise<NotificationSetting> {
            return localVarFp.getNotificationSetting(notificationSettingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an existing Notification resource
         * @summary Patch NotificationSetting
         * @param {string} notificationSettingId 
         * @param {PatchNotificationSettingRequest} [patchNotificationSettingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNotificationSetting(notificationSettingId: string, patchNotificationSettingRequest?: PatchNotificationSettingRequest, options?: any): AxiosPromise<PatchNotificationSettingResponse> {
            return localVarFp.patchNotificationSetting(notificationSettingId, patchNotificationSettingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an existing Notification resource
         * @summary Update NotificationSetting
         * @param {string} notificationSettingId 
         * @param {UpdateNotificationSettingRequest} [updateNotificationSettingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotificationSetting(notificationSettingId: string, updateNotificationSettingRequest?: UpdateNotificationSettingRequest, options?: any): AxiosPromise<UpdateNotificationSettingResponse> {
            return localVarFp.updateNotificationSetting(notificationSettingId, updateNotificationSettingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationSettingApi - object-oriented interface
 * @export
 * @class NotificationSettingApi
 * @extends {BaseAPI}
 */
export class NotificationSettingApi extends BaseAPI {
    /**
     * Creates a new NotificationSetting resource
     * @summary Create NotificationSetting
     * @param {CreateNotificationSettingRequest} [createNotificationSettingRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationSettingApi
     */
    public createNotificationSetting(createNotificationSettingRequest?: CreateNotificationSettingRequest, options?: AxiosRequestConfig) {
        return NotificationSettingApiFp(this.configuration).createNotificationSetting(createNotificationSettingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new shared Application level NotificationSetting resource. This resource can be accessed by any tenant of the application.
     * @summary Create Shared NotificationSetting
     * @param {CreateNotificationSettingRequest} [createNotificationSettingRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationSettingApi
     */
    public createSharedNotificationSetting(createNotificationSettingRequest?: CreateNotificationSettingRequest, options?: AxiosRequestConfig) {
        return NotificationSettingApiFp(this.configuration).createSharedNotificationSetting(createNotificationSettingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve an existing NotificationSetting resource
     * @summary Get NotificationSetting
     * @param {string} notificationSettingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationSettingApi
     */
    public getNotificationSetting(notificationSettingId: string, options?: AxiosRequestConfig) {
        return NotificationSettingApiFp(this.configuration).getNotificationSetting(notificationSettingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an existing Notification resource
     * @summary Patch NotificationSetting
     * @param {string} notificationSettingId 
     * @param {PatchNotificationSettingRequest} [patchNotificationSettingRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationSettingApi
     */
    public patchNotificationSetting(notificationSettingId: string, patchNotificationSettingRequest?: PatchNotificationSettingRequest, options?: AxiosRequestConfig) {
        return NotificationSettingApiFp(this.configuration).patchNotificationSetting(notificationSettingId, patchNotificationSettingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an existing Notification resource
     * @summary Update NotificationSetting
     * @param {string} notificationSettingId 
     * @param {UpdateNotificationSettingRequest} [updateNotificationSettingRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationSettingApi
     */
    public updateNotificationSetting(notificationSettingId: string, updateNotificationSettingRequest?: UpdateNotificationSettingRequest, options?: AxiosRequestConfig) {
        return NotificationSettingApiFp(this.configuration).updateNotificationSetting(notificationSettingId, updateNotificationSettingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ShipmentsApi - axios parameter creator
 * @export
 */
export const ShipmentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new Shipment resource
         * @summary Create Shipment
         * @param {CreateShipmentRequest} createShipmentRequest The Shipment to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createShipment: async (createShipmentRequest: CreateShipmentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createShipmentRequest' is not null or undefined
            assertParamExists('createShipment', 'createShipmentRequest', createShipmentRequest)
            const localVarPath = `/shipment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Application-API-Key required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication On-Behalf-Of required
            await setApiKeyToObject(localVarHeaderParameter, "On-Behalf-Of", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createShipmentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve an existing Shipment resource
         * @summary Get Shipment
         * @param {string} shipmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShipment: async (shipmentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shipmentId' is not null or undefined
            assertParamExists('getShipment', 'shipmentId', shipmentId)
            const localVarPath = `/shipment/{shipment_id}`
                .replace(`{${"shipment_id"}}`, encodeURIComponent(String(shipmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Application-API-Key required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication On-Behalf-Of required
            await setApiKeyToObject(localVarHeaderParameter, "On-Behalf-Of", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an existing Shipment resource
         * @summary Update Shipment
         * @param {string} shipmentId 
         * @param {UpdateShipmentRequest} [updateShipmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateShipment: async (shipmentId: string, updateShipmentRequest?: UpdateShipmentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shipmentId' is not null or undefined
            assertParamExists('updateShipment', 'shipmentId', shipmentId)
            const localVarPath = `/shipment/{shipment_id}`
                .replace(`{${"shipment_id"}}`, encodeURIComponent(String(shipmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Application-API-Key required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication JWT required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication On-Behalf-Of required
            await setApiKeyToObject(localVarHeaderParameter, "On-Behalf-Of", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateShipmentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShipmentsApi - functional programming interface
 * @export
 */
export const ShipmentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShipmentsApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new Shipment resource
         * @summary Create Shipment
         * @param {CreateShipmentRequest} createShipmentRequest The Shipment to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createShipment(createShipmentRequest: CreateShipmentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateShipmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createShipment(createShipmentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve an existing Shipment resource
         * @summary Get Shipment
         * @param {string} shipmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShipment(shipmentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Shipment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getShipment(shipmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates an existing Shipment resource
         * @summary Update Shipment
         * @param {string} shipmentId 
         * @param {UpdateShipmentRequest} [updateShipmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateShipment(shipmentId: string, updateShipmentRequest?: UpdateShipmentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateShipmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateShipment(shipmentId, updateShipmentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShipmentsApi - factory interface
 * @export
 */
export const ShipmentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShipmentsApiFp(configuration)
    return {
        /**
         * Creates a new Shipment resource
         * @summary Create Shipment
         * @param {CreateShipmentRequest} createShipmentRequest The Shipment to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createShipment(createShipmentRequest: CreateShipmentRequest, options?: any): AxiosPromise<CreateShipmentResponse> {
            return localVarFp.createShipment(createShipmentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve an existing Shipment resource
         * @summary Get Shipment
         * @param {string} shipmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShipment(shipmentId: string, options?: any): AxiosPromise<Shipment> {
            return localVarFp.getShipment(shipmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an existing Shipment resource
         * @summary Update Shipment
         * @param {string} shipmentId 
         * @param {UpdateShipmentRequest} [updateShipmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateShipment(shipmentId: string, updateShipmentRequest?: UpdateShipmentRequest, options?: any): AxiosPromise<UpdateShipmentResponse> {
            return localVarFp.updateShipment(shipmentId, updateShipmentRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShipmentsApi - object-oriented interface
 * @export
 * @class ShipmentsApi
 * @extends {BaseAPI}
 */
export class ShipmentsApi extends BaseAPI {
    /**
     * Creates a new Shipment resource
     * @summary Create Shipment
     * @param {CreateShipmentRequest} createShipmentRequest The Shipment to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShipmentsApi
     */
    public createShipment(createShipmentRequest: CreateShipmentRequest, options?: AxiosRequestConfig) {
        return ShipmentsApiFp(this.configuration).createShipment(createShipmentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve an existing Shipment resource
     * @summary Get Shipment
     * @param {string} shipmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShipmentsApi
     */
    public getShipment(shipmentId: string, options?: AxiosRequestConfig) {
        return ShipmentsApiFp(this.configuration).getShipment(shipmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an existing Shipment resource
     * @summary Update Shipment
     * @param {string} shipmentId 
     * @param {UpdateShipmentRequest} [updateShipmentRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShipmentsApi
     */
    public updateShipment(shipmentId: string, updateShipmentRequest?: UpdateShipmentRequest, options?: AxiosRequestConfig) {
        return ShipmentsApiFp(this.configuration).updateShipment(shipmentId, updateShipmentRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TrackingApi - axios parameter creator
 * @export
 */
export const TrackingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns tracking, branding, and shipment details for an existing shipment
         * @summary Get Branded Tracking
         * @param {string} brandingId The unique identifier of the Branding to apply
         * @param {string} [shipmentId] The unique id of a Shipment resource to track. If this parameter is not supplied, the remaining Shipment query parameters are required in order to uniquely identify a Shipment.
         * @param {string} [carrierCode] The carrier code of the shipment to track
         * @param {string} [trackingNumber] The tracking number of the shipment to track
         * @param {string} [orderNumber] The order number of the shipment to track
         * @param {string} [postalCode] The destination postal code of the shipment to track
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrandedTracking: async (brandingId: string, shipmentId?: string, carrierCode?: string, trackingNumber?: string, orderNumber?: string, postalCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandingId' is not null or undefined
            assertParamExists('getBrandedTracking', 'brandingId', brandingId)
            const localVarPath = `/track`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (brandingId !== undefined) {
                localVarQueryParameter['branding_id'] = brandingId;
            }

            if (shipmentId !== undefined) {
                localVarQueryParameter['shipment_id'] = shipmentId;
            }

            if (carrierCode !== undefined) {
                localVarQueryParameter['carrier_code'] = carrierCode;
            }

            if (trackingNumber !== undefined) {
                localVarQueryParameter['tracking_number'] = trackingNumber;
            }

            if (orderNumber !== undefined) {
                localVarQueryParameter['order_number'] = orderNumber;
            }

            if (postalCode !== undefined) {
                localVarQueryParameter['postal_code'] = postalCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrackingApi - functional programming interface
 * @export
 */
export const TrackingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TrackingApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns tracking, branding, and shipment details for an existing shipment
         * @summary Get Branded Tracking
         * @param {string} brandingId The unique identifier of the Branding to apply
         * @param {string} [shipmentId] The unique id of a Shipment resource to track. If this parameter is not supplied, the remaining Shipment query parameters are required in order to uniquely identify a Shipment.
         * @param {string} [carrierCode] The carrier code of the shipment to track
         * @param {string} [trackingNumber] The tracking number of the shipment to track
         * @param {string} [orderNumber] The order number of the shipment to track
         * @param {string} [postalCode] The destination postal code of the shipment to track
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBrandedTracking(brandingId: string, shipmentId?: string, carrierCode?: string, trackingNumber?: string, orderNumber?: string, postalCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrandedTrackingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandedTracking(brandingId, shipmentId, carrierCode, trackingNumber, orderNumber, postalCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TrackingApi - factory interface
 * @export
 */
export const TrackingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TrackingApiFp(configuration)
    return {
        /**
         * Returns tracking, branding, and shipment details for an existing shipment
         * @summary Get Branded Tracking
         * @param {string} brandingId The unique identifier of the Branding to apply
         * @param {string} [shipmentId] The unique id of a Shipment resource to track. If this parameter is not supplied, the remaining Shipment query parameters are required in order to uniquely identify a Shipment.
         * @param {string} [carrierCode] The carrier code of the shipment to track
         * @param {string} [trackingNumber] The tracking number of the shipment to track
         * @param {string} [orderNumber] The order number of the shipment to track
         * @param {string} [postalCode] The destination postal code of the shipment to track
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrandedTracking(brandingId: string, shipmentId?: string, carrierCode?: string, trackingNumber?: string, orderNumber?: string, postalCode?: string, options?: any): AxiosPromise<BrandedTrackingResponse> {
            return localVarFp.getBrandedTracking(brandingId, shipmentId, carrierCode, trackingNumber, orderNumber, postalCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TrackingApi - object-oriented interface
 * @export
 * @class TrackingApi
 * @extends {BaseAPI}
 */
export class TrackingApi extends BaseAPI {
    /**
     * Returns tracking, branding, and shipment details for an existing shipment
     * @summary Get Branded Tracking
     * @param {string} brandingId The unique identifier of the Branding to apply
     * @param {string} [shipmentId] The unique id of a Shipment resource to track. If this parameter is not supplied, the remaining Shipment query parameters are required in order to uniquely identify a Shipment.
     * @param {string} [carrierCode] The carrier code of the shipment to track
     * @param {string} [trackingNumber] The tracking number of the shipment to track
     * @param {string} [orderNumber] The order number of the shipment to track
     * @param {string} [postalCode] The destination postal code of the shipment to track
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackingApi
     */
    public getBrandedTracking(brandingId: string, shipmentId?: string, carrierCode?: string, trackingNumber?: string, orderNumber?: string, postalCode?: string, options?: AxiosRequestConfig) {
        return TrackingApiFp(this.configuration).getBrandedTracking(brandingId, shipmentId, carrierCode, trackingNumber, orderNumber, postalCode, options).then((request) => request(this.axios, this.basePath));
    }
}


