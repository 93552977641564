import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'

import { Dialog, DialogTitle, DialogContent, useDialog } from './Dialog'
import ReturnPolicyText from './ReturnPolicyText'
import { useFeatureEnables } from '../dataProviders'

export default function ReturnPolicyDialog() {
  const { open, toggle } = useReturnPolicyDialog()

  return (
    <Dialog id="return-policy-dialog" toggle={toggle} open={open}>
      <DialogTitle onClose={toggle}>
        <FormattedMessage description="returnPolicyDialogTitle" defaultMessage="Return Policy" />
      </DialogTitle>

      <DialogContent>
        <ReturnPolicyText />
      </DialogContent>
    </Dialog>
  )
}

const ReturnPolicyDialogContext = React.createContext({ open: false, toggle: () => null })

export function ReturnPolicyDialogProvider({ children }: { children: React.ReactNode }) {
  const [open, toggle] = useDialog()
  const { showReturnPolicy } = useFeatureEnables()

  return (
    <ReturnPolicyDialogContext.Provider value={{ open, toggle }}>
      {children}
      {showReturnPolicy && <ReturnPolicyDialog />}
    </ReturnPolicyDialogContext.Provider>
  )
}

export function useReturnPolicyDialog() {
  return useContext(ReturnPolicyDialogContext)
}
