import Layout01 from './layout01'
import Layout02 from './layout02'
import Layout03 from './layout03'

import { Branding, LayoutKeys } from './dataProviders'

export const layouts = {
  layout01: Layout01,
  layout02: Layout02,
  layout03: Layout03,
}

export default function useLayout(branding?: Branding) {
  let layoutKey = branding?.layout_key

  if (!layoutKey || (layoutKey as string) === 'layout00') {
    layoutKey = LayoutKeys.Layout01
  }

  let TheLayout: any = layouts[layoutKey]

  if (!TheLayout) {
    // eslint-disable-next-line no-console
    console.warn(`Missing layout ${layoutKey}`)
    TheLayout = layouts.layout01
  }

  return TheLayout
}
