import React, { useState } from 'react'

const placeholderImage = require('./PlaceholderImage.png')

export default function ItemImage({ url, name }: { url?: string; name?: string }) {
  const initialState = url || placeholderImage
  const [imageUrl, setImageHidden] = useState(initialState)

  const onError = () => setImageHidden(placeholderImage)

  return <img src={imageUrl} alt={name} onError={onError} style={{ maxWidth: '100%', maxHeight: '100%' }} />
}
