const ignoreErrors = [
  /^No error$/,
  /__show__deepen/,
  /_avast_submit/,
  /anonymous function: captureException/,
  /Blocked a frame with origin/,
  /console is not defined/,
  /cordova/,
  /DataCloneError/,
  /Error: AccessDeny/,
  /feedConf/,
  /ibFindAllVideos/,
  /myGloFrameList/,
  /SecurityError/,
  /MyIPhoneApp/,
  /ceCurrentVideo\.currentTime/,
  /vid_mate_check is not defined/,
  /win\.document\.body/,
  /window\._sharedData\.entry_data/,
  /ztePageScrollModule/,
]

export default ignoreErrors
