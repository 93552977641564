import React from 'react'

import SmsControls from '../components/SmsControls'
import { useFeatureEnables } from '../dataProviders'
import { makeBtStyles, Theme } from '../Theme'

import Card, { CardSection } from './Card'

const useStyles = makeBtStyles((theme: Theme) => ({
  root: {
    borderLeft: '3px solid #222222',
    '&:empty': {
      display: 'none',
    },
  },
}))

const useSmsControlsStyles = makeBtStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    marginBottom: '-12px',
  },
  phoneIcon: {
    fontSize: '30px',
    position: 'absolute',
    top: '6px',
    left: '0',
  },
  button: {
    marginBottom: '12px',
  },
  formWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  subscribedMessage: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: '38px',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  subscribedMessageText: {
    margin: '0 20px 12px 0',
    alignSelf: 'center',
  },
  mainMessage: {
    margin: '2px 12px 12px 38px',
  },
  form: {
    marginBottom: '12px',
  },
  termsMessage: {
    fontSize: '12px',
    margin: 0,
    color: '#929292',
  },
}))

export default function SmsCard({ className = '' }) {
  const classes = useStyles()
  const smsControlsClasses = useSmsControlsStyles()
  const { showSms } = useFeatureEnables()

  if (!showSms) {
    return null
  }

  return (
    <Card className={`${classes.root} ${className}`}>
      <CardSection>
        <SmsControls classes={smsControlsClasses} />
      </CardSection>
    </Card>
  )
}
