import React, { useContext } from 'react'

import { NotificationProperties } from '../apiTypes/api'

export interface NotificationSetting {
  sms_enabled?: boolean
}

const NotificationSettingContext = React.createContext({})
export const NotificationSettingProvider = NotificationSettingContext.Provider
export function useNotificationSetting(): NotificationSetting {
  const notificationSetting = (useContext(NotificationSettingContext) || {}) as NotificationProperties

  return {
    sms_enabled: notificationSetting.sms_enabled,
  }
}
