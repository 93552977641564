import React from 'react'

import { makeBtStyles, Theme } from '../Theme'

const useStyles = makeBtStyles((theme: Theme) => ({
  card: {
    height: '100%',
    overflow: 'hidden',
    position: 'relative', // some content in cards is 'absolute' WRT the card
    ...theme.card,
  },
  header: {
    borderBottom: '0.5px solid #DFE3E8',
    fontFamily: 'Roboto',
    fontSize: '10px',
    fontWeight: '700',
    lineHeight: '12px',
    padding: '15px 20px',
    ...theme.layoutSpecific.layout02.cardHeader,
  },
  section: {
    padding: '12px 20px',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
}))

export const CardHeader = ({
  children,
  className = '',
  ...rest
}: {
  children: React.ReactNode
  className?: string
  id?: string
}) => {
  let classes = useStyles()
  return (
    <div className={`${classes.header} ${className}`} {...rest}>
      {children}
    </div>
  )
}

export const CardSection = ({
  children,
  className = '',
  ...rest
}: {
  children: React.ReactNode
  className?: string
}) => {
  let classes = useStyles()
  return (
    <div className={`${classes.section} ${className}`} {...rest}>
      {children}
    </div>
  )
}

export default function Card({ children, className = '', ...rest }: { children: React.ReactNode; className?: string }) {
  let classes = useStyles()
  return (
    <div className={`${classes.card} ${className}`} {...rest}>
      {children}
    </div>
  )
}
