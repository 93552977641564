import React, { useCallback, useState } from 'react'
import { Collapse } from '@material-ui/core'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'

import { useBrandingData, useFeatureEnables } from '../dataProviders'

import { BtLink, NavLink } from './BtLink'
import Icon from './Icon'
import { ToggleClasses } from './SharedAppBar'

export default function NavLinks({
  linkClassName = '',
  truncateLinksAt,
  classes,
}: {
  linkClassName?: string
  truncateLinksAt?: number
  classes?: ToggleClasses
}) {
  const { showMenuLinks } = useFeatureEnables()
  const { menu_links } = useBrandingData()
  const [showMore, setShowMore] = useState(false)
  const toggleMore = useCallback(() => setShowMore(oldShowMore => !oldShowMore), [])
  const filteredMenuLinks = menu_links.filter(item => item && item.url && item.text)

  if (!showMenuLinks) {
    return null
  }

  if (truncateLinksAt && truncateLinksAt < filteredMenuLinks.length) {
    const moreOptions = filteredMenuLinks.slice(truncateLinksAt, filteredMenuLinks.length)
    return (
      <>
        {filteredMenuLinks.slice(0, truncateLinksAt).map(({ url, text }) => (
          <NavLink className={linkClassName} key={url} href={url}>
            {text}
          </NavLink>
        ))}

        <BtLink onClick={toggleMore} className={linkClassName}>
          More
          <Icon icon={showMore ? faChevronUp : faChevronDown} className={classes?.icon} />
        </BtLink>
        <Collapse in={showMore}>
          <div className={classes?.showMoreSection}>
            {moreOptions.map(({ url, text }) => (
              <NavLink className={`${linkClassName} ${classes?.dropDownClassName}`} key={url} href={url}>
                {text}
              </NavLink>
            ))}
          </div>
        </Collapse>
      </>
    )
  }

  return (
    <>
      {filteredMenuLinks.map(({ url, text }) => (
        <NavLink className={linkClassName} key={url} href={url}>
          {text}
        </NavLink>
      ))}
    </>
  )
}
