import React from 'react'
import List from '@material-ui/core/List'

import { useBrandingData, useFeatureEnables } from '../dataProviders'
import NavLogo from '../components/NavLogo'
import NavDrawerItem from '../components/NavDrawerItem'
import { MIN_TABLET_SIZE, MIN_DESKTOP_SIZE } from '../utils'

import { makeBtStyles } from '../Theme'
import { MOBILE_CONTENT_WIDTH } from '../components/defaultStyleVars'

const useNavDrawerStyles = makeBtStyles(theme => ({
  paper: {
    minWidth: '100%',
    bottom: '0',
    backgroundColor: 'white',
  },
  list: {
    width: '100%',
    margin: 'auto',
    padding: '0',
  },
  item: {
    padding: '10px 52px 5px',
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      textAlign: 'center',
      padding: '20px 52px 5px',
    },
  },
  socialMediaGrid: {
    width: MOBILE_CONTENT_WIDTH,
    margin: '16px auto 0',
    display: 'flex',
    justifyContent: 'center',
  },
  socialMediaLink: {
    padding: '12px',
  },
  socialMediaLinkIcon: {
    fontSize: '1.25rem',
  },
}))

const useLogoStyles = makeBtStyles(
  theme => ({
    textLogo: {
      textAlign: 'left',
      display: 'inline-block',
      marginLeft: 0,
      fontSize: 20,
      fontWeight: '500',
      lineHeight: '24px',
      color: '#222222',
      [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
        textAlign: 'left',
      },
      [theme.breakpoints.up('xs')]: {
        textAlign: 'center',
      },
    },
    root: {
      textAlign: 'center',
      display: 'inline-block',
      verticalAlign: 'middle',
      margin: '50px 5px 50px 0',
      [theme.breakpoints.down(MIN_DESKTOP_SIZE)]: {
        margin: '0 auto',
      },
      width: '100%',
    },
    image: {
      maxWidth: '150px',
      [theme.breakpoints.down(MIN_DESKTOP_SIZE)]: {
        maxHeight: '50px',
      },
    },
  }),
  { name: 'LogoStyles' },
)

const LinkList = ({
  classes = {},
}: {
  classes?: {
    list?: string
    item?: string
  }
}) => {
  const { showMenuLinks } = useFeatureEnables()
  const { menu_links = [] } = useBrandingData()
  const validItems = menu_links.filter(item => item && item.url && item.text)
  if (!showMenuLinks) {
    return null
  }
  return (
    <List className={classes.list}>
      {validItems.map(item => (
        <NavDrawerItem key={item.text} text={item.text} href={item.url} className={classes.item} />
      ))}
    </List>
  )
}

export default function VerticalAppBar() {
  const logoClasses = useLogoStyles()
  const navDrawerClasses = useNavDrawerStyles()

  const WrappedLinkList = (props: any = {}) => <LinkList classes={navDrawerClasses} {...props} />

  return (
    <>
      <NavLogo classes={logoClasses} />
      <WrappedLinkList />
    </>
  )
}

VerticalAppBar.propTypes = {}
