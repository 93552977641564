import React from 'react'

import { makeBtStyles } from '../Theme'
import { MIN_TABLET_SIZE, MIN_DESKTOP_SIZE } from '../utils'
import StatusStepper from '../components/stepper/StatusStepper'
import MenuLinksNavBar from '../components/MenuLinksNavBar'

import Card from './Card'
import ShipmentActivity from './ShipmentActivity'
import DestinationTrackingSection from './DestinationTrackingSection'
import { useLogoStyles, useNavStyles } from './AppBar'

const useStyles = makeBtStyles(theme => ({
  desktopMenuClasses: {
    [theme.breakpoints.down(MIN_DESKTOP_SIZE)]: {
      display: 'none',
    },
  },
  shipmentActivity: {
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      display: 'none',
    },
  },
  card: {
    [theme.breakpoints.up(MIN_DESKTOP_SIZE)]: {
      overflow: 'visible',
    },
    [theme.breakpoints.up(MIN_TABLET_SIZE)]: {
      borderLeftStyle: 'none',
      borderTopRightRadius: '10px',
      borderBottomRightRadius: '10px',
    },
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      borderBottomRightRadius: '10px',
      borderBottomLeftRadius: '10px',
    },
  },
  scrollableSection: {
    overflow: 'auto',
    height: 'calc(100% - 42px)',
  },
  defaultHiddenOnMobile: {},
  mostRecentLabel: {
    fontSize: '10px',
    fontWeight: '700',
    lineHeight: '12px',
    letterSpacing: '0',
    textTransform: 'uppercase',
    marginBottom: '12px',
    marginLeft: '-18px',
  },
  trackingEvent: {
    '& .timestamp': {
      fontSize: '12px',
      fontWeight: '700',
      lineHeight: '12px',
      display: 'block',
      textTransform: 'uppercase',
      marginBottom: '2px',
    },
    '& .description, & .location': {
      fontSize: '12px',
      fontWeight: '300',
      lineHeight: '14px',
      letterSpacing: '0',
    },
    '& .location': {
      marginLeft: '3px',
    },
  },
  noTrackingEventsMsg: {
    padding: '15px 20px',
    fontStyle: 'italic',
    color: '#929292',
  },
  collapseToggle: {
    padding: '10px',
    textAlign: 'center',
    width: '100%',
    '&.isOpen': {
      borderTop: '1px solid #DFE3E8',
    },
  },
}))

const useToggleStyles = makeBtStyles(theme => ({
  icon: {
    color: theme.colors.main,
    margin: '2px 1px',
    height: '9px',
  },
  showMoreSection: {
    position: 'absolute',
    zIndex: '10',
    right: '-135px',
    marginTop: '8px',
    width: '184px',
    background: '#FFFFFF',
    boxShadow: '1px 1px 3px rgba(0,0,0,0.25)',
    border: '1px solid #DFE3E8',
    borderRadius: '3px',
  },
  dropDownClassName: {
    margin: '0 !important',
    display: 'block',
    padding: '8px 15px',
    borderBottom: '1px solid #DFE3E8',
    '&:last-child': {
      borderBottom: '1px solid #DFE3E8',
    },
  },
}))

const useStepperStyles = makeBtStyles(theme => ({
  icon: {
    [theme.breakpoints.up(MIN_DESKTOP_SIZE)]: {
      height: '22px',
      marginTop: '10px',
    },
    [theme.breakpoints.down(MIN_DESKTOP_SIZE)]: {
      height: '18px',
      marginTop: '10px',
    },
  },
  stepWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.up(MIN_DESKTOP_SIZE)]: {
      margin: '30px 10px 32px 0px',
    },
    [theme.breakpoints.between(MIN_TABLET_SIZE, MIN_DESKTOP_SIZE)]: {
      width: '95%',
      margin: '30px 0px 20px',
    },
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      width: '100%',
      margin: '30px 0px 20px',
    },
  },
  friendlyStatus: {
    width: '470px',
    margin: '25px 0px 0px 30px',
    display: 'block',
    fontWeight: '400',
    [theme.breakpoints.down(MIN_DESKTOP_SIZE)]: {
      margin: '30px 0px 0px 40px',
    },
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      width: '100%',
      textAlign: 'center',
      margin: '30px 0px 0px 0px !important',
    },
  },
  statusWrap: {
    fontSize: '16px',
    lineHeight: '18px',
    marginBottom: '-10px',
  },
  statusNoWrap: {
    fontSize: '18px',
    lineHeight: '24px',
  },
}))

const useStepStyles = makeBtStyles(theme => ({
  circle: {
    [theme.breakpoints.up(MIN_DESKTOP_SIZE)]: {
      marginBottom: '30px',
      height: '25px',
      width: '25px',
    },
    [theme.breakpoints.down(MIN_DESKTOP_SIZE)]: {
      marginBottom: '10px',
      height: '15px',
      width: '15px',
    },
  },
  check: {
    [theme.breakpoints.up(MIN_DESKTOP_SIZE)]: {
      height: '13px',
      width: '13px',
      margin: '5px 4px',
    },
    [theme.breakpoints.down(MIN_DESKTOP_SIZE)]: {
      height: '9px',
      width: '9px',
      margin: '0px 1px 2px',
    },
  },
  missed: {
    [theme.breakpoints.up(MIN_DESKTOP_SIZE)]: {
      height: '10px',
      width: '10px',
      margin: '6px',
    },
    [theme.breakpoints.down(MIN_DESKTOP_SIZE)]: {
      height: '7px',
      width: '9px',
      margin: '0px 1px 3px',
    },
  },
  dividerLine: {
    [theme.breakpoints.up(MIN_DESKTOP_SIZE)]: {
      height: '9px',
      top: '8px',
      right: '50%',
    },
    [theme.breakpoints.down(MIN_DESKTOP_SIZE)]: {
      height: '4px',
      top: '5px',
      right: '50%',
    },
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      top: '6px',
    },
  },
  stepBlock: {
    [theme.breakpoints.up(MIN_DESKTOP_SIZE)]: {
      width: '160px !important',
    },
    [theme.breakpoints.down(MIN_DESKTOP_SIZE)]: {
      width: '120px',
    },
  },
  text: {
    fontSize: '11px',
    marginTop: '5px',
  },
}))

export default function TrackingCard({ className }: { className?: string }) {
  const classes = useStyles()
  const logoClasses = useLogoStyles()
  const navClasses = useNavStyles()
  const toggleClasses = useToggleStyles()
  const stepperClasses = useStepperStyles()
  const stepClasses = useStepStyles()

  return (
    <Card className={`${classes.card} ${className}`} aria-labelledby="tracking-card">
      <MenuLinksNavBar
        className={classes?.desktopMenuClasses}
        logoClasses={logoClasses}
        navClasses={navClasses}
        showSocialMedia={false}
        truncateLinksAt={3}
        toggleClasses={toggleClasses}
      />
      <StatusStepper classes={stepperClasses} stepClasses={stepClasses} />
      <DestinationTrackingSection />
      <ShipmentActivity className={classes.shipmentActivity} />
    </Card>
  )
}
