import { useState, useEffect } from 'react'
import axios from 'axios'

export const getTrackingUrl = args => {
  const PARAM_NAMES = [
    'branding_id',
    'carrier_code',
    'tracking_number',
    'order_number',
    'postal_code',
    'shipment_id',
    'hmac_packed_id',
  ]
  const presentParamNames = PARAM_NAMES.filter(p => args[p] !== null && args[p] !== undefined)
  const query = presentParamNames.map(paramName => `${paramName}=${args[paramName]}`).join('&')
  return `${process.env.REACT_APP_API_URL}track/?${query}`
}

export default function useTrackingData(trackingArgs) {
  const trackingUrl = getTrackingUrl(trackingArgs)

  const { isPreview, mockPreviewData } = { ...trackingArgs }

  const [state, setState] = useState({ loaded: false })
  const [error, setError] = useState()

  useEffect(() => {
    if (isPreview) {
      // This path will never get reloaded if preview changes so don't put transforms for preview data here.
      // This code is only here to mimick having loaded the tracking data (preview data changes happen on `previewData`).
      setState({ ...mockPreviewData, loaded: true })
    } else {
      axios
        .get(trackingUrl)
        .then(response => {
          const { data } = response
          data.loaded = true
          setState(data)
        })
        .catch(error => {
          setError(error)
        })
    }
  }, [trackingUrl, mockPreviewData, isPreview])

  // NB - if we ever need to return a `setState` here this whole process will need to be rethought
  return [{ tracking: state.tracking, loaded: state.loaded }, error, setState]
}
