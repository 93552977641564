import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/browser'

import useTrackingData from './useTrackingData'
import useShipmentBrandingData from './useShipmentBrandingData'

import pushStat, { configureStats } from './pushStat'
import pushEvent, { configureUiEvent } from './pushEvent'
import productionPreviewMockData from './productionPreviewData' // DO NOT CHANGE THIS IMPORT. USED BY PREVIEW FROM SHIP-IT
import { InnerErrorBoundary, LambdaError } from './ErrorBoundary'
import { getLocale, LocaleProvider } from './locales'
import TrackingPage from './TrackingPage'
import { TrackingDevControls } from './DevControls'

import ErrorBoundary from './ErrorBoundary'
import NotFound from './NotFound'
import EmailPreview from './EmailPreview'
import SmsTermsPage from './SmsTermsPage'

const EMAIL_PREVIEW_HASHBANG_REGEX = new RegExp(/^#!email-preview/)
const SMS_TERMS_HASHBANG_REGEX = new RegExp(/^#!sms-terms/)

export default function TrackingApp({ testData }) {
  const url = new URL(window.location.href)

  if (url.hash.match(EMAIL_PREVIEW_HASHBANG_REGEX)) {
    return <EmailPreview />
  }

  if (url.hash.match(SMS_TERMS_HASHBANG_REGEX)) {
    return <SmsTermsPage />
  }

  if (!window.location.search) {
    Sentry.captureMessage(`Page visit without URL params. Referrer: '${document.referrer}'`)
    return <NotFound />
  }

  return (
    <ErrorBoundary>
      <InnerApp testData={testData} />
    </ErrorBoundary>
  )
}
TrackingApp.propTypes = {
  testData: PropTypes.object,
}

function InnerApp({ testData }) {
  const url = new URL(window.location.href)
  const isPreviewMode = url.searchParams.get('preview')
  const mockPreviewData = testData || (isPreviewMode && productionPreviewMockData)

  const trackingArgs = {
    isPreview: !!mockPreviewData,
    mockPreviewData,
  }

  const idV1 = url.searchParams.get('d')
  if (idV1) {
    trackingArgs['hmac_packed_id'] = idV1
  } else {
    // if this is a query-string request,  either the shipment_id will be present, or the shipment properties
    ;['branding_id', 'carrier_code', 'tracking_number', 'order_number', 'postal_code', 'shipment_id'].forEach(
      q => (trackingArgs[q] = url.searchParams.get(q)),
    )
  }

  const [trackingData, trackingError] = useTrackingData(trackingArgs)
  const [data, error, setData] = useShipmentBrandingData(trackingArgs)

  if (error || trackingError) {
    // TODO: consider taking different actions based on which error we received
    const message = error?.response?.data?.error_message || trackingError?.response?.data?.error_message
    const carrierTrackingUrl =
      error?.response?.data?.carrier_tracking_url || trackingError?.response?.data?.carrier_tracking_url

    throw new LambdaError(
      message || `LambdaError(${carrierTrackingUrl ? 'message' : 'message & carrierTrackingUrl'}) MISSING`,
      carrierTrackingUrl,
    )
  }

  if (url.searchParams.get('mockShipment')) {
    data.shipment = productionPreviewMockData.shipment
    trackingData.tracking = productionPreviewMockData.tracking
  }

  useEffect(() => {
    if (data.branding && data.shipment && !mockPreviewData) {
      configureStats({
        isPreview: !!mockPreviewData,
        external_account_id:
          data.shipment.external_account_id || (trackingData.tracking && trackingData.tracking.external_account_id),
        branding_id: data.branding.branding_id,
        tracking_number: data.shipment.tracking_number,
        carrier_code: data.shipment.carrier_code,
        buyer_email: data.shipment.destination.email,
        tenant_id: data.branding.tenant_id,
      })
      pushStat('view')
      configureUiEvent({
        isPreview: !!mockPreviewData,
        shipmentId: data.shipment.shipment_id,
        applicationId: data.shipment.application_id,
      })
      pushEvent('ui.page.viewed')
    }
  }, [data, trackingData.tracking, data.branding, data.shipment, mockPreviewData])

  const locale = getLocale(data.shipment)

  return (
    <InnerErrorBoundary carrierTrackingUrl={data.carrier_tracking_url}>
      <LocaleProvider locale={locale}>
        <TrackingPage data={data} trackingData={trackingData} />
        <TrackingDevControls {...{ data, setData }} />
      </LocaleProvider>
    </InnerErrorBoundary>
  )
}
