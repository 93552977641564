import {
  ThemeConfig,
  Layout01ThemeCustomizations,
  Layout02ThemeCustomizations,
  Layout03ThemeCustomizations,
} from './index'

const Peach: ThemeConfig = {
  colors: {
    main: '#bf4545',
  },
  page: {
    background: '#f2e6df',
  },
  link: {},
  characteristicFont: {
    fontFamily: '"Playfair Display", sans-serif',
    fontWeight: '400',
  },
  mainTrackingStatus: {
    fontSize: '34px',
    lineHeight: '1.176',
  },
  signatureRequired: {
    background: '#fdd8cf',
    color: '#222222',
  },
  card: {
    background: 'white',
    boxShadow: '-9px 9px 0 #eed9d3',
  },
  recentTrackingDot: {
    backgroundColor: '#bf4545',
  },
  layoutSpecific: {
    layout01: {
      cardHeader: {
        color: '#767676',
      },
    } as Layout01ThemeCustomizations,
    layout02: {
      cardHeader: {
        // color: '#767676'
      },
    } as Layout02ThemeCustomizations,
    layout03: {
      cardHeader: {
        color: '#767676',
      },
    } as Layout03ThemeCustomizations,
  },
}

export default Peach
