/* eslint-disable no-unused-expressions,no-multi-assign,no-underscore-dangle,func-names,prefer-rest-params,no-plusplus,max-len,no-console,class-methods-use-this */

export const PAGE_TRACK = 'page_track'
export const EVENT_TRACK = 'event_track'
export const EVENT_LINK = 'event_link'
export const EVENT_INITIALIZED = 'event_initialized'
export const EVENT_GIFTWRAP_SHARE_CLICK = 'giftwrap_share_click'
export const EVENT_GIFTWRAP_SHARED = 'giftwrap_shared'

class Analytics {
  constructor() {
    const segmentApiKey = process.env.REACT_APP_SEGMENT_API_KEY
    if (!segmentApiKey && process.env.NODE_ENV !== 'test') {
      console.warn('no segment API key defined, integration will be disabled')
      return
    }

    !(function () {
      ///////////////////////////////////////////////////////////////////////////////////////////////////
      // Segment copy-pasted "snippet"
      ///////////////////////////////////////////////////////////////////////////////////////////////////
      const a = document.getElementsByTagName('script')[0]
      if (!a) return window.analytics
      const analytics = (window.analytics = window.analytics || [])
      if (!analytics.initialize && !analytics.invoked) {
        analytics.invoked = !0
        analytics.methods = [
          'trackSubmit',
          'trackClick',
          'trackLink',
          'trackForm',
          'pageview',
          'identify',
          'reset',
          'group',
          'track',
          'ready',
          'alias',
          'debug',
          'page',
          'once',
          'off',
          'on',
        ]
        analytics.factory = function (t) {
          return function () {
            const e = Array.prototype.slice.call(arguments)
            e.unshift(t)
            analytics.push(e)
            return analytics
          }
        }
        for (let t = 0; t < analytics.methods.length; t++) {
          const e = analytics.methods[t]
          analytics[e] = analytics.factory(e)
        }
        ///////////////////////////////////////////////////////////////////////////////////////////////////

        analytics.ready(() => {
          // I only see 10 (yes 10 vs 183,926,862 for the "other" `.page()` call). Not sure why, maybe
          // Segment is collapsing them & using the "other" call if it succeeds since the URL etc are
          // all the same? Docs don't mention that. Leaving it here for now.
          analytics.page(EVENT_INITIALIZED)
        })

        ///////////////////////////////////////////////////////////////////////////////////////////////////
        // Segment copy-pasted "snippet"
        ///////////////////////////////////////////////////////////////////////////////////////////////////
        analytics.load = function (t, e) {
          const n = document.createElement('script')
          n.type = 'text/javascript'
          n.async = !0
          n.src = `https://cdn.segment.com/analytics.js/v1/${t}/analytics.min.js`
          a.parentNode.insertBefore(n, a)
          analytics._loadOptions = e
        }
        analytics.SNIPPET_VERSION = '4.1.0'
        ///////////////////////////////////////////////////////////////////////////////////////////////////
      }

      analytics.load(segmentApiKey)
      return analytics
    })()
  }

  identify(userId, attributes) {
    if (!window.analytics) return
    window.analytics.identify(userId, attributes)
  }

  page(name) {
    if (!window.analytics) return
    const [client_width, client_height] = getClientWidth()
    window.analytics.page(name, {
      device_width: window?.screen?.width,
      device_height: window?.screen?.height,
      client_width,
      client_height,
    })
  }

  event(name, attrs) {
    if (!window.analytics) return
    window.analytics.track(name, attrs)
  }
}

function getClientWidth() {
  try {
    const docEl = window.document.documentElement
    const body = document.body
    const hasBodySize = body?.clientWidth && body?.clientHeight

    if (docEl?.clientWidth && docEl?.clientHeight && ('CSS1Compat' === window.document.compatMode || !hasBodySize)) {
      return [docEl.clientWidth, docEl.clientHeight]
    } else if (hasBodySize) {
      return [body.clientWidth, body.clientHeight]
    }
  } catch (_) {
    return []
  }

  return []
}

export const analytics = new Analytics()
