import React, { useContext, useState, useMemo } from 'react'

export * from './ShipmentProvider'
export * from './TrackingProvider'
export * from './BrandingProvider'
export * from './NotificationSettingProvider'
export * from './ReturnsPortalConfigProvider'
export * from './TrackContextProvider'
export * from './FeatureEnables'

/////////////////////////////////////////////////////////////////////////////
// Preview Config
/////////////////////////////////////////////////////////////////////////////
const PreviewConfigContext = React.createContext({ mobileNavOpen: false, isPreview: false })
export const PreviewConfigProvider = PreviewConfigContext.Provider
export function usePreviewConfig() {
  return useContext(PreviewConfigContext) || {}
}

/////////////////////////////////////////////////////////////////////////////
// Subscriptions
/////////////////////////////////////////////////////////////////////////////
export interface Subscription {
  country_code: string
  phone_number: string
  state: string
}

export interface SubscriptionsData {
  subscriptions: Array<Subscription>
  firstActiveSubscription: () => Subscription | undefined
  updateSubscriptions: (newSubscriptions: Subscription[]) => void
}

const SubscriptionsContext = React.createContext({
  subscriptions: [],
  firstActiveSubscription: () => undefined,
  updateSubscriptions: (newSubscriptions: Subscription[]) => null,
} as SubscriptionsData)

export const SubscriptionsProvider = ({ children, value }: { children: React.ReactNode; value: any }) => {
  const [subscriptions, setSubscriptions] = useState<Subscription[]>(value?.subscriptions || [])

  const contextValue = useMemo(
    () => ({
      subscriptions: subscriptions,
      firstActiveSubscription: () => subscriptions.find((sub: Subscription) => sub.state === 'subscribed'),
      updateSubscriptions: (newSubscriptions: Subscription[]) => setSubscriptions(newSubscriptions || []),
    }),
    [subscriptions],
  )

  return <SubscriptionsContext.Provider value={contextValue}>{children}</SubscriptionsContext.Provider>
}

export function useSubscriptionsData() {
  return useContext(SubscriptionsContext)
}
