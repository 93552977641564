import React, { useState } from 'react'
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons'
import { FormattedMessage, useIntl } from 'react-intl'

import { Subscription, useFeatureEnables, useSubscriptionsData } from '../dataProviders'

import Icon from './Icon'
import { BtLink } from './BtLink'
import SmsSubscribeForm from './SmsSubscribeForm'
import SmsTermsDialog, { useDialog } from './SmsTermsDialog'
import Button from './Button'

enum SubscriptionState {
  Form = 1,
  Previously,
  JustNow,
}

export default function SmsControls({
  classes = {},
}: {
  classes?: {
    root?: string
    button?: string
    formWrapper?: string
    subscribeMessage?: string
    form?: string
    mainMessage?: string
    subscribedMessage?: string
    subscribedMessageText?: string
    termsMessage?: string
    phoneIcon?: string
  }
}) {
  const intl = useIntl()
  const { showSms } = useFeatureEnables()
  const [openTerms, toggleTerms] = useDialog()
  const { firstActiveSubscription, updateSubscriptions } = useSubscriptionsData()
  const subscription = firstActiveSubscription()

  const initialState = subscription ? SubscriptionState.Previously : SubscriptionState.Form
  const [subscriptionState, setSubscriptionState] = useState<SubscriptionState>(initialState)

  const onSubscribe = (newSubscriptions: Subscription[]) => {
    updateSubscriptions(newSubscriptions)
    setSubscriptionState(SubscriptionState.JustNow)
  }

  const onCancelUpdate = () => {
    setSubscriptionState(SubscriptionState.JustNow)
  }

  if (!showSms) {
    return null
  }

  const ariaLabel = intl.formatMessage({ description: 'smsControls.label', defaultMessage: 'Receive SMS Updates' })

  return (
    <div className={classes.root} aria-label={ariaLabel}>
      <Icon className={classes.phoneIcon} icon={faMobileAlt} />
      {subscriptionState === SubscriptionState.JustNow ? (
        <div className={classes.subscribedMessage}>
          <p className={classes.subscribedMessageText}>
            <FormattedMessage
              description="SmsControls.justSubscribed.message"
              defaultMessage="Thanks for signing up. Check your mobile number ending in {lastFourDigits} to confirm."
              values={{ lastFourDigits: lastFourDigits(subscription?.phone_number) }}
            />
          </p>
          <Button onClick={() => setSubscriptionState(SubscriptionState.Form)} className={classes.button}>
            <FormattedMessage description="SmsControls.justSubscribed.button" defaultMessage="Update Number" />
          </Button>
        </div>
      ) : subscriptionState === SubscriptionState.Previously ? (
        <div className={classes.subscribedMessage}>
          <p className={classes.subscribedMessageText}>
            <FormattedMessage
              description="SmsControls.alreadySubscribed.message"
              defaultMessage="The mobile number ending in {lastFourDigits} is now receiving shipping and delivery updates via text."
              values={{ lastFourDigits: lastFourDigits(subscription?.phone_number) }}
            />
          </p>
          <Button onClick={() => setSubscriptionState(SubscriptionState.Form)} className={classes.button}>
            <FormattedMessage description="SmsControls.alreadySubscribed.button" defaultMessage="Update Number" />
          </Button>
        </div>
      ) : (
        <div className={classes.formWrapper}>
          <div className={classes.mainMessage}>
            <FormattedMessage
              description="SmsControls.mainMessage"
              defaultMessage="Receive shipping & delivery updates by text."
            />
            <p className={classes.termsMessage}>
              <FormattedMessage
                description="SmsControls.termsMessage"
                defaultMessage="By entering a number you accept the <link>terms</link>."
                values={{ link: (chunks: any) => <BtLink onClick={toggleTerms}>{chunks}</BtLink> }}
              />
            </p>
          </div>
          <SmsSubscribeForm
            onSuccess={onSubscribe}
            onCancel={onCancelUpdate}
            subscription={subscription}
            className={classes.form}
          />
        </div>
      )}
      <SmsTermsDialog open={openTerms} toggle={toggleTerms} />
    </div>
  )
}

function lastFourDigits(number = '') {
  return number.slice(Math.max(number.length - 4, 0), number.length)
}
