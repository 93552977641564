import React from 'react'
import _capitalize from 'lodash/capitalize'

import { useTrackingData } from '../dataProviders'

export default function LastTrackingEventLocation({
  className,
  defaultText = '',
}: {
  className?: string
  defaultText?: string
}) {
  const { latestUpdate } = useTrackingData() || {}
  const { city_locality: cityLocality, country_code: countryCode, state_province: state } = latestUpdate || {}

  const city = _capitalize((cityLocality || '').toLocaleLowerCase())
  const country = countryCode !== 'US' ? countryCode : ''
  const location = [city, state, country].filter(e => e).join(', ')

  return (
    <span data-testid="last-tracking-event-location" className={className}>
      {location || defaultText}
    </span>
  )
}
