import moment from 'moment'

const productImage = require('./productionPreviewAssets/product-placeholder.png')

const defaultPreviewData = {
  branding: {
    branded_tracking_config_id: '5678',
    branding_id: '5678',
    // NB - ONLY add values in here that are NECESSARY for faking a real page. Any "defaults" should exist in the main app
    //      and having entries here that don't match the version of the Preview API used can break things
  },
  shipment: {
    tracking_url: 'http://www.ups.com/',
    tracking_number: '1Z1234567891234567',
    carrier_code: 'ups',
    external_account_id: '1234',
    order_number: '1234567890',
    store_address: {
      first_name: 'fake',
      last_name: 'name',
      company: '',
      address: '123 nowhere street',
      address2: '',
      address3: '',
      city: 'Austin',
      state: 'TX',
      country_code: 'US',
      postal_code: '78756',
      phone_number: '00000000',
      email: 'user@example.com',
    },
    destination: {
      first_name: 'Jack',
      last_name: 'Ship',
      address: '1234 Main Street',
      address2: 'Suite 35',
      email: 'foo@mail.com',
      city: 'City',
      state: 'ST',
      postal_code: 'Zip Code',
      country_code: 'US',
    },
    weight_in_ounces: '145',
    line_items: [
      {
        product_name: 'Product Name',
        options: 'Product options',
        quantity: '#',
        image_urls: {
          thumb: productImage,
        },
      },
      {
        product_name: 'Product Name',
        options: 'Product Options',
        image_urls: {
          thumb: productImage,
        },
        quantity: '#',
      },
      {
        product_name: 'Product Name',
        options: 'Product Options',
        image_urls: {
          thumb: productImage,
        },
        quantity: '#',
      },
    ],
  },
  tracking: {
    source: 'shipengine',
    status_code: 'IT',
    carrier: 'fedex',
    external_account_id: '1234',
    tracking_number: '1Z1234567891234567',
    status_description: 'In Transit',
    carrier_status_code: 'DP',
    carrier_status_description: 'Departed UPS location',
    ship_date: moment().subtract(1, 'days').toISOString(),
    estimated_delivery_date: moment().add(3, 'days').toISOString(),
    actual_delivery_date: null,
    exception_description: null,
    events: [
      {
        carrier_occurred_at: moment().subtract(8, 'hours').toISOString(),
        description: 'Departed UPS location',
        city_locality: 'KERNERSVILLE',
        state_province: 'NC',
        postal_code: '27284',
        latitude: 36.1169,
        longitude: -80.0839,
        country_code: 'US',
        company_name: null,
        signer: null,
        event_code: 'DP',
      },
      {
        carrier_occurred_at: moment().subtract({ hours: 9, minutes: 38 }).toISOString(),
        description: 'Arrived at UPS location',
        city_locality: 'KERNERSVILLE',
        state_province: 'NC',
        postal_code: '27284',
        latitude: 36.1169,
        longitude: -80.0839,
        country_code: 'US',
        company_name: null,
        signer: null,
        event_code: 'AR',
      },
      {
        carrier_occurred_at: moment().subtract({ hours: 11, minutes: 1 }).toISOString(),
        description: 'Left UPS origin facility',
        city_locality: 'DURHAM',
        state_province: 'NC',
        postal_code: '27713',
        latitude: 35.9101,
        longitude: -78.9197,
        country_code: 'US',
        company_name: null,
        signer: null,
        event_code: 'DP',
      },
      {
        carrier_occurred_at: moment().subtract({ hours: 11, minutes: 42 }).toISOString(),
        description: 'Arrived at UPS location',
        city_locality: 'DURHAM',
        state_province: 'NC',
        postal_code: '27713',
        latitude: 35.9101,
        longitude: -78.9197,
        country_code: 'US',
        company_name: null,
        signer: null,
        event_code: 'AR',
      },
      {
        carrier_occurred_at: moment().subtract({ hours: 15, minutes: 30 }).toISOString(),
        description: 'Shipment information sent to UPS',
        city_locality: null,
        state_province: null,
        postal_code: '27278',
        country_code: 'US',
        company_name: null,
        signer: null,
        event_code: 'OC',
      },
      {
        carrier_occurred_at: moment().subtract({ hours: 21, minutes: 15 }).toISOString(),
        description: 'Picked up',
        city_locality: 'DURHAM',
        state_province: 'NC',
        postal_code: '27713',
        latitude: 36.1169,
        longitude: -80.0839,
        country_code: 'US',
        company_name: null,
        signer: null,
        event_code: 'PU',
      },
    ],
  },
}

export default defaultPreviewData
