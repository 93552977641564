import React from 'react'
import { withStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import MuiDialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'

import { makeBtStyles } from '../Theme'

const useTitleStyles = makeBtStyles(
  theme => ({
    root: {
      margin: 0,
      padding: '32px 36px 0',
      color: '#2A2B2A',
      fontSize: '14px',
      fontWeight: '700',
    },
    closeButton: {
      position: 'absolute',
      right: '4px',
      top: '-10px',
      color: '#3D8BEC',
      fontSize: '30px',
      fontWeight: '300',
      '&:hover': {
        background: 'none',
      },
    },
  }),
  { name: 'SharedDialogTitle' },
)

export const DialogTitle = ({ children, onClose, ...other }: { children: React.ReactNode; onClose: () => any }) => {
  const classes = useTitleStyles()

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {children}
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          &times;
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
}

export const DialogContent = withStyles(theme => ({
  root: {
    padding: '10px 36px 36px',
  },
}))(MuiDialogContent)

export function useDialog(initializeOpen = false): [boolean, () => any] {
  const [open, setOpen] = React.useState<boolean>(initializeOpen)
  const toggle = () => setOpen(!open)
  return [open, toggle]
}

export function Dialog({
  open,
  toggle,
  children,
  id,
  classes,
}: {
  open: boolean
  toggle: () => any
  children: React.ReactNode
  id: string
  classes?: any
}) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <MuiDialog
      maxWidth="sm"
      fullWidth
      fullScreen={fullScreen}
      onClose={toggle}
      aria-labelledby={id}
      open={open}
      classes={classes}>
      {children}
    </MuiDialog>
  )
}
