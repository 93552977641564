import React from 'react'
import { FormattedMessage } from 'react-intl'

import TrackingNumberLink from '../components/TrackingNumberLink'
import CarrierIcon from '../components/CarrierIcon'
import SignatureRequiredTag from '../components/SignatureRequiredTag'
import { AbbreviatedDestinationAddress } from '../components/DestinationAddress'
import CollapsableSection from '../components/CollapsableSection'
import { MIN_TABLET_SIZE, MIN_DESKTOP_SIZE } from '../utils'
import { makeBtStyles } from '../Theme'

import { CardSection } from './Card'
import ShipmentInformation from './ShipmentInformation'
import CarbonNeutral from '../components/CarbonNeutral'

const useStyles = makeBtStyles(theme => ({
  root: {
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      display: 'none',
    },
  },
  carrierIcon: {
    height: '35px',
    opacity: '0.5',
    filter: 'grayscale(1)',
    width: 'auto !important',
    paddingRight: '8px',
    '&.carrierIconSvg': {
      maxHeight: '14px',
    },
    '&.carrierIconText': {
      fontWeight: '300',
    },
  },
  contentLabel: {
    margin: '4px 0px',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    display: 'block',
  },
  destinationTrackingSection: {
    minHeight: '85px',
    paddingLeft: '53px',
    borderBottom: '0.5px solid #DFE3E8 !important',
    borderTop: '0.5px solid #DFE3E8',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      paddingLeft: '40px',
      borderTop: 'none',
      marginTop: '-20px',
      display: 'block',
    },
  },
  finalDestination: {
    width: '300px',
    marginRight: '20px',
    [theme.breakpoints.between(MIN_TABLET_SIZE, MIN_DESKTOP_SIZE)]: {
      marginRight: '0px',
    },
    fontSize: '14px',
    letterSpacing: '0.13px',
  },
  signatureRequired: {
    margin: '5px 0px 5px',
    fontSize: '9px',
  },
  carrierTracking: {
    fontSize: '14px',
  },
  trackingNumber: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: '8px',
  },
  trackingNumberWrapper: {
    width: '240px',
  },
  icon: {
    color: theme.colors.main,
    position: 'absolute',
    right: '40px',
    fontSize: '20px',
  },
}))

const useToggleStyles = makeBtStyles(theme => ({
  root: {
    [theme.breakpoints.up(MIN_TABLET_SIZE)]: {
      display: 'none',
    },
  },
  collapseToggle: {
    textDecoration: 'none !important',
    color: 'black',
    fontSize: '14px',
    fontWeight: '500',
    borderTop: '1px solid #DFE3E8',
    width: '100%',
    padding: '20px 30px 20px 40px',
  },
  showMore: {
    display: 'inline-block',
  },
}))

export default function DestinationTrackingSection() {
  const classes = useStyles()
  const toggleClasses = useToggleStyles()

  return (
    <>
      <ShipmentInformation classes={classes} />
      <CollapsableSection
        classes={classes}
        toggleClasses={toggleClasses}
        label={
          <FormattedMessage description="layout03.mobile.collapsableSection" defaultMessage="Shipment Information" />
        }>
        <CardSection className={classes.destinationTrackingSection}>
          <div className={classes.trackingNumberWrapper}>
            <label className={classes.contentLabel}>
              <FormattedMessage description="layout03.mobile.carrierTracking" defaultMessage="Carrier Tracking" />
            </label>
            <div className={classes.carrierTracking}>
              <div className={classes.carrierNumber}>
                <CarrierIcon className={classes.carrierIcon} fallbackToName />
                <TrackingNumberLink />
              </div>
              <SignatureRequiredTag className={classes.signatureRequired} />
            </div>
          </div>
          <div className={classes.finalDestination}>
            <label className={classes.contentLabel}>
              <FormattedMessage description="layout03.mobile.destinationLabel" defaultMessage="Final Destination" />
            </label>
            <AbbreviatedDestinationAddress />
          </div>
          <CarbonNeutral />
        </CardSection>
      </CollapsableSection>
    </>
  )
}
