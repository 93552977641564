import React from 'react'
import { FormattedMessage } from 'react-intl'

import LineItems, { useHasLineItems } from '../components/LineItems'
import OrderNumber, { useHasOrderNumber } from '../components/OrderNumber'
import { useFeatureEnables } from '../dataProviders'
import { isGiftWrapped } from '../components/GiftWrapPanel'

import { LineItemCardClasses, LineItemClasses } from './ShipmentActivityContent'
import Card from './Card'

export function useIsLineItemsCardShown() {
  const hasLineItems = useHasLineItems()
  const hasOrderNumber = useHasOrderNumber()
  const { showShipmentDetails } = useFeatureEnables()

  return !((!hasLineItems && !hasOrderNumber) || !showShipmentDetails || isGiftWrapped)
}

export default function LineItemsCard({
  className,
  lineItemClasses,
  lineItemCardClasses,
}: {
  className: string
  lineItemClasses: LineItemClasses
  lineItemCardClasses: LineItemCardClasses
}) {
  const hasOrderNumber = useHasOrderNumber()

  return (
    <Card className={className}>
      <div className={lineItemCardClasses.scrollableSection}>
        {hasOrderNumber && (
          <div className={lineItemCardClasses.orderNumberSection}>
            <label className={lineItemCardClasses.contentLabel}>
              <FormattedMessage description="layout02.lineItemsCard.orderNumberLabel" defaultMessage="Order Number" />:
            </label>
            <OrderNumber className={lineItemCardClasses.orderNumber} />
          </div>
        )}
        <LineItems truncateToCount={2} classes={lineItemClasses} formatQuantity={q => <>Qty {q}</>} />
      </div>
    </Card>
  )
}
