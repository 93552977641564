import React, { useState, FormEvent, MouseEvent, ChangeEvent } from 'react'
import axios from 'axios'
import classnames from 'classnames'
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons'

import { makeBtStyles } from '../Theme'
import { Subscription, useShipmentData } from '../dataProviders'
import { FormattedMessage, IntlShape, useIntl } from 'react-intl'
import Button from './Button'
import Icon from './Icon'

const useStyles = makeBtStyles(theme => ({
  root: {},
  countryPrefix: {
    border: '1px solid #DDDDDD',
    borderRight: 'none',
    borderRadius: '2px 0 0 2px',
    lineHeight: '38px',
    height: '40px',
    padding: '0 10px',
    '&.hasError': {
      borderColor: '#E73434',
    },
  },
  inputAndButton: {
    display: 'flex',
  },
  input: {
    width: '130px',
    fontSize: '14px',
    height: '40px',
    padding: '8px',
    border: '1px solid #DDDDDD',
    borderRightWidth: '0',
    '&:focus': {
      outline: 'none',
      borderColor: theme.colors.main,
      borderRightWidth: '1px',
    },
    '&::placeholder': {
      color: '#DEDEDE',
    },
    '&.hasError': {
      borderColor: '#E73434',
      borderRightWidth: '1px',
    },
  },
  submitButton: {
    borderRadius: '0 2px 2px 0',
  },
  cancelButton: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '22px', // tiny circle (x) so make it easier to (seem like) you can click it
    },
  },
  error: {
    fontSize: '12px',
    color: '#E73434',
    marginTop: '4px',
    maxWidth: '245px',
    '&:empty': {
      display: 'none',
    },
  },
}))

const ONLY_DIGITS_REGEX = /\D/g
const US_PHONE_NUMBER_LENGTH = 10
const US_COUNTRY_CODE = '1'

const justDigits = (number: string) => (number || '').trim().replace(ONLY_DIGITS_REGEX, '')

const truncateUsNumber = (number: string) => number.slice(0, US_PHONE_NUMBER_LENGTH)

const validateUsNumber = (number: string, intl: IntlShape) => {
  const num = justDigits(number)
  if (num.length !== US_PHONE_NUMBER_LENGTH) {
    return intl.formatMessage({ description: 'SmsForm.error.invalidNumber', defaultMessage: 'Phone number is invalid' })
  }
  return null
}

const formatUsNumber = (number: string) => {
  const areaCode = number.slice(0, 3)
  const prefix = number.slice(3, 6)
  const main = number.slice(6, 10)
  return [areaCode, prefix, main].filter(i => i).join('-')
}

export default function SmsSubscribeForm({
  onSuccess,
  onCancel,
  subscription,
  className = '',
}: {
  onSuccess: (newSubscriptions: Subscription[]) => void
  onCancel: () => void
  subscription?: Subscription
  className?: string
}) {
  const classes = useStyles()
  const intl = useIntl()

  const initialNumber = subscription && { ...subscription, phone_number: justDigits(subscription.phone_number) }
  const [number, setNumber] = useState<Subscription>(
    initialNumber || { phone_number: '', country_code: US_COUNTRY_CODE, state: '' },
  )
  const [submitError, setSubmitError] = useState<string>('')
  const { shipment_id } = useShipmentData()

  const onNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const phone_number = truncateUsNumber(justDigits(event.target.value))
    setNumber(num => ({ ...number, phone_number }))
    setSubmitError('')
  }

  const onSubmit = (event: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    const numberError = validateUsNumber(number.phone_number, intl) // should never hit but last line of defense
    if (numberError) {
      setSubmitError(numberError)
    } else {
      axios
        .put(`${process.env.REACT_APP_API_URL}sms-opt-in`, {
          country_code: number.country_code,
          phone_number: number.phone_number,
          shipment_id: shipment_id,
        })
        .then(({ data }) => onSuccess(data.subscriptions))
        .catch(error => {
          const msg =
            error?.response?.data?.error_message ||
            intl.formatMessage({
              description: 'SmsForm.error.genericSubmit',
              defaultMessage: 'Something went wrong. Please try again.',
            })
          setSubmitError(msg)
          console.error && console.error(error)
        })
    }
  }

  return (
    <form onSubmit={onSubmit} className={classnames(classes.root, className)}>
      <div className={classes.inputAndButton}>
        <div className={classnames(classes.countryPrefix, { hasError: submitError })}>+{US_COUNTRY_CODE}</div>
        <input
          className={classnames(classes.input, { hasError: submitError })}
          type="tel"
          aria-label={intl.formatMessage({ description: 'SmsForm.phoneLabel', defaultMessage: 'Mobile Number' })}
          aria-required="true"
          placeholder="000-000-0000"
          value={formatUsNumber(number.phone_number)}
          onChange={onNumberChange}
        />
        <Button type="submit" className={classes.submitButton} disabled={!!validateUsNumber(number.phone_number, intl)}>
          <FormattedMessage description="SmsForm.submitButton" defaultMessage="Sign Up" />
        </Button>
        {!!subscription && (
          <Button type="button" onClick={onCancel} variant="link" className={classes.cancelButton} aria-label="Cancel">
            <Icon icon={faTimesCircle} />
          </Button>
        )}
      </div>
      <div className={classes.error}>{submitError}</div>
    </form>
  )
}
