import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import classnames from 'classnames'

import TrackingEvents from '../components/TrackingEvents'
import { isGiftWrapped } from '../components/GiftWrapPanel'
import { makeBtStyles } from '../Theme'

import Card, { CardHeader, CardSection } from './Card'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'

function hexToRgb(hex: string | undefined) {
  const DEFAULT_COLOR = { r: 0, g: 0, b: 0 }

  if (!hex || hex.length !== 7 || hex[0] !== '#') {
    console.warn(`Invalid hex color in TrackingCard: '${hex}'`)
    return DEFAULT_COLOR
  }

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : DEFAULT_COLOR
}

const useStyles = makeBtStyles(theme => ({
  scrollableSection: {
    overflow: 'auto',
    height: 'calc(100% - 42px)',
  },
  eventRow: () => {
    const recentDotColor = theme.recentTrackingDot.backgroundColor

    if (!recentDotColor) {
      if (recentDotColor && !/#/.test(recentDotColor)) {
        console.warn(`Color names are not fully supported, please use hex values. Found '${recentDotColor}'`)
      }
    }

    const rgbRecentDotColor = hexToRgb(recentDotColor)

    return {
      paddingLeft: '38px',
      position: 'relative',
      color: '#929292',
      '&::before': {
        content: '""',
        width: '8px',
        height: '8px',
        borderRadius: '100%',
        backgroundColor: '#dedede',
        position: 'absolute',
        left: '20px',
        top: '15px',
      },
      '&.mostRecentEvent': {
        color: 'inherit',
        '&::before': {
          backgroundColor: recentDotColor,
          top: '38px',
          boxShadow: `0px 0px 0px 2px rgba(${rgbRecentDotColor.r},${rgbRecentDotColor.g},${rgbRecentDotColor.b}, 0.30)`,
        },
      },
    }
  },
  defaultHiddenOnMobile: {
    //display: 'none'
  },
  mostRecentLabel: {
    fontSize: '10px',
    fontWeight: '700',
    lineHeight: '12px',
    letterSpacing: '0',
    textTransform: 'uppercase',
    marginBottom: '12px',
    marginLeft: '-18px',
  },
}))

const useTrackingStyles = makeBtStyles(theme => ({
  event: {
    '& .timestamp': {
      fontSize: '10px',
      fontWeight: '700',
      lineHeight: '12px',
      display: 'block',
      textTransform: 'uppercase',
      marginBottom: '2px',
    },
    '& .description, & .location': {
      fontSize: '12px',
      fontWeight: '300',
      lineHeight: '14px',
      letterSpacing: '0',
    },
    '& .location': {
      marginLeft: '3px',
    },
  },
  emptyMsg: {
    padding: '15px 20px',
    fontStyle: 'italic',
    color: '#929292',
  },
  collapseToggle: {
    padding: '10px',
    textAlign: 'center',
    width: '100%',
    '&.isOpen': {
      borderTop: '1px solid #DFE3E8',
    },
  },
  showMore: {
    display: 'inline-block',
    '&::after': {
      display: 'inline-block',
      marginLeft: '.255em',
      verticalAlign: '.255em',
      content: '""', // double quoted b/c `content` has to have the quotes in it
      borderTop: '.3em solid',
      borderRight: '.3em solid transparent',
      borderBottom: '0',
      borderLeft: '.3em solid transparent',
    },
    '&.isOpen::after': {
      borderBottom: '.3em solid',
      borderTop: '0',
    },
  },
}))

export default function TrackingCard({ className }: { className?: string }) {
  const intl = useIntl()
  const classes = useStyles()
  const trackingClasses = useTrackingStyles()

  const theme = useTheme()
  const truncateCount = useMediaQuery(theme.breakpoints.down('sm')) ? 2 : 0

  const formatTimestamp = (timestamp: Date) => {
    const date = intl
      .formatDateToParts(timestamp, { day: 'numeric', month: 'long' })
      .map(p => p.value)
      .join('')
    const time = intl
      .formatDateToParts(timestamp, { hour: 'numeric', minute: 'numeric' })
      .map(p => p.value)
      .join('')
    return `${date} - ${time}`
  }

  const eventClassName = (index: number) => classnames(classes.eventRow, { mostRecentEvent: index === 0 })

  return (
    <Card className={className} aria-labelledby="tracking-header">
      <CardHeader id="tracking-header">
        {isGiftWrapped ? (
          <FormattedMessage description="layout01.trackingCard.headingAsGift" defaultMessage="Gift Activity" />
        ) : (
          <FormattedMessage description="layout01.trackingCard.heading" defaultMessage="Shipment Activity" />
        )}
      </CardHeader>
      <div className={classes.scrollableSection} role="list">
        <TrackingEvents
          classes={trackingClasses}
          truncateToCount={truncateCount}
          formatTimestamp={formatTimestamp}
          eachEvent={({ content, key, index, event }) => (
            <CardSection className={eventClassName(index)} key={key}>
              {index === 0 && (
                <div className={classes.mostRecentLabel}>
                  <FormattedMessage
                    description="layout01.trackingCard.mostRecentEventLabel"
                    defaultMessage="Most Recent"
                  />
                </div>
              )}
              {content}
            </CardSection>
          )}
        />
      </div>
    </Card>
  )
}
