import { Server, Response } from 'miragejs'
import axios from 'axios'
import _set from 'lodash/set'
import _get from 'lodash/get'

import { isDevMode } from './utils'

const url = new URL(window.location.href)
export const currentLayoutKey = url.searchParams.get('branding.layout_key') || 'layout01'
export const currentThemeKey = url.searchParams.get('branding.theme_key') || 'light'
export const currentMockName = url.searchParams.get('d') || 'withLinks'

// @ts-ignore
if (isDevMode() || window.Cypress) {
  const handleTrack = (/* schema, fakeRequest */) => {
    return new Promise(resolve => {
      const mockName = currentMockName

      if (!/^\w+$/.test(mockName)) {
        // Prevents URL attack. Keep simple, we can live with simple mock names
        throw new Error(`Invalid mock name '${mockName}'`)
      }

      axios.get(`/mocks/${mockName}.json`).then(
        response =>
          resolve(
            new Response(
              response.data.status_code,
              response.data.headers,
              mergeUrlParamData({ tracking: response.data.body.tracking }), // body
            ),
          ),
        () => {
          throw new Error(`No such mock '${mockName}'`)
        },
      )
    })
  }

  const handleBtResources = (/* schema, fakeRequest */) => {
    return new Promise(resolve => {
      const mockName = currentMockName

      if (!/^\w+$/.test(mockName)) {
        // Prevents URL attack. Keep simple, we can live with simple mock names
        throw new Error(`Invalid mock name '${mockName}'`)
      }

      axios.get(`/mocks/${mockName}.json`).then(
        response =>
          resolve(
            new Response(
              response.data.status_code,
              response.data.headers,
              mergeUrlParamData({ ...response.data.body, tracking: undefined }), // body
            ),
          ),
        () => {
          throw new Error(`No such mock '${mockName}'`)
        },
      )
    })
  }

  const handleSmsOptIn = (schema: any, fakeRequest: any) => {
    const { country_code, phone_number, shipment_id } = JSON.parse(fakeRequest.requestBody)

    let mock: string
    if (country_code !== '1') {
      mock = 'missing-country-code'
    } else if (!phone_number) {
      mock = 'missing-phone'
    } else if (phone_number === '5555555555') {
      console.debug(`Faking failure for + ${country_code} ${phone_number}, ${shipment_id}`)
      mock = 'generic-fail'
    } else if (phone_number === '1111111111') {
      console.debug(`Faking failure w/ message for ${phone_number}`)
      mock = 'fail-with-message'
    } else {
      console.debug(`Subscribed + ${country_code} ${phone_number}, ${shipment_id}`)
      mock = 'success'
    }

    return loadMock(`sms-opt-in/${mock}`, { phone_number, country_code })
  }

  const handlePortal = (/* schema: any, fakeRequest: any */) => {
    return loadMock('returns/portal')
  }

  const handleOrderSearch = (schema: any, fakeRequest: any) =>
    loadMock(`returns/search${fakeRequest?.queryParams?.order_number}`)

  const handleCreateReturn = (schema: any, fakeRequest: any) => {
    const request = JSON.parse(fakeRequest.requestBody)
    return loadMock(`returns/create-return${request.order_number}`)
  }

  const mergeUrlParamData = (responseData: any) => {
    let data = responseData

    url.searchParams.forEach((value, name) => {
      if (/(branding|shipment|tracking|notification_setting)\./.test(name)) {
        const type = typeof _get(data, name)
        const parsedValue = type === 'object' || type === 'boolean' ? JSON.parse(value) : value
        _set(data, name, parsedValue)
      }
    })

    return data
  }

  const replaceStaticData = (responseData: any, requestData: any) => {
    let responseString = JSON.stringify(responseData || {})
    Object.entries(requestData).forEach(([key, value]) => {
      responseString = responseString.replaceAll(`{${key}}`, value as string)
    })
    return JSON.parse(responseString)
  }

  const loadMock = (mock: string, requestData: any = {}) => {
    return new Promise(resolve => {
      axios.get(`/mocks/${mock}.json`).then(
        response =>
          resolve(
            new Response(
              response.data.status_code,
              response.data.headers,
              replaceStaticData(response.data.body, requestData),
            ),
          ),
        () => resolve(new Response(400, {}, { _mirage_error_msg: `No such mock '${mock}'` })),
      )
    })
  }

  let server = new Server({
    routes() {
      this.get(`${process.env.REACT_APP_API_URL}track`, handleTrack)
      this.get(`${process.env.REACT_APP_API_URL}bt_resources`, handleBtResources)
      this.put(`${process.env.REACT_APP_API_URL}sms-opt-in`, handleSmsOptIn)

      this.get(`${process.env.REACT_APP_API_URL}public/:id/search`, handleOrderSearch)
      this.get(`${process.env.REACT_APP_API_URL}public/:id`, handlePortal)
      this.post(`${process.env.REACT_APP_API_URL}public/:id/return`, handleCreateReturn)
    },
  })

  server.passthrough('/mocks/*')
  server.passthrough('https://api.segment.io/*')
  server.passthrough('https://cdn.segment.com/*')
}
