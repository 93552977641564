import React, { useEffect, useState } from 'react'
import moment from 'moment'

import { makeBtStyles } from '../Theme'
import DeliveredCheckMark from '../components/DeliveredCheckMark'
import EstimatedDeliveryDateOrStatus from '../components/EstimatedDeliveryDateOrStatus'
import { MIN_TABLET_SIZE } from '../utils'

const useStyles = makeBtStyles(theme => ({
  date: {
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      textAlign: 'center',
    },
    [theme.breakpoints.up(MIN_TABLET_SIZE)]: {
      //using this margin bottom to line up section lines between main card and tracking card
      marginBottom: '4px',
    },
  },
  day: {
    fontSize: '132px',
    fontWeight: 'bold',
    [theme.breakpoints.up(MIN_TABLET_SIZE)]: {
      margin: '-60px 0px 10px',
      textAlign: 'left',
      height: '160px',
    },
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      lineHeight: '140px',
      marginTop: '-20px',
    },
  },
  month: {
    fontSize: '36px',
    fontWeight: 'bold',
  },
  textStatus: {
    display: 'block',
    margin: '20px 0px 0px 20px',
    minHeight: '100px',
    textAlign: 'left',
    fontSize: '32px',
    fontWeight: 'bold',
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      textAlign: 'center',
      margin: '0',
    },
  },
  status: {
    textTransform: 'uppercase',
  },
  deliveredCheck: {
    height: '80px',
    stroke: 'white',
    margin: '20px 0 0 0',
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      margin: '20px 0 0 0',
      width: '100%',
      justifyContent: 'center',
    },
  },
}))

export default function MainStatusSection() {
  const classes = useStyles()
  const [checkShown, setCheckShown] = useState(false)

  useEffect(() => {
    setTimeout(() => setCheckShown(true), 2000)
  }, [])

  const formatDate = (date: string) => {
    const day = moment(date).format('D')
    const month = moment(date).format('MMMM').toUpperCase()

    return (
      <div className={classes.date}>
        <div className={classes.day}> {day} </div>
        <span className={classes.month}> {month} </span>
      </div>
    )
  }

  return (
    <EstimatedDeliveryDateOrStatus formatDate={formatDate}>
      {({ status, formattedStatusOrDate }) => (
        <div className={classes.textStatus}>
          <div className={classes.status}>{formattedStatusOrDate}</div>
          {status === 'isDelivered' && checkShown && <DeliveredCheckMark className={classes.deliveredCheck} />}
        </div>
      )}
    </EstimatedDeliveryDateOrStatus>
  )
}
