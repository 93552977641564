import React, { useContext } from 'react'
import _isNil from 'lodash/isNil'

import { Carrier, Shipment as ApiShipment, ShipmentConfirmationRequirementEnum, OrderSourceCode } from '../apiTypes/api'

export { Carrier, ShipmentConfirmationRequirementEnum } from '../apiTypes/api'

export interface Shipment {
  shipment_id: string
  carrier_code: Carrier
  confirmation_requirement?: ShipmentConfirmationRequirementEnum
  currency_code?: string
  order_source_code?: OrderSourceCode
  destination: {
    address: string
    address2?: string
    city?: string
    country_code?: string
    postal_code?: string
    state?: string
  }
  external_account_id: string
  line_items: Array<ShipmentItem>
  order_number?: string
  service_name?: string
  tracking_number: string
  tracking_url?: string
  store_address: {
    country_code?: string
  }
  carbon_neutral?: boolean
}

export interface ShipmentItem {
  image_urls?: {
    medium?: string
    original: string
    thumb?: string
  }
  options?: Array<{ label: string; value: string }>
  description?: string
  product_name?: string
  quantity?: number
  subtotal_price?: Currency
  subtotal_cents?: number
  unit_price?: Currency
  unit_price_cents?: number
}

export interface Currency {
  currency_code?: string | null
  amount?: string
}

const ShipmentDataContext = React.createContext({})
export const ShipmentDataProvider = ShipmentDataContext.Provider
export function useShipmentData(): Shipment {
  const { signature_required, ...shipmentData } = (useContext(ShipmentDataContext) as ApiShipment) || {}
  const destination = shipmentData.destination || {}
  const store_address = shipmentData.store_address
  const shipmentConfirmation: ShipmentConfirmationRequirementEnum = shipmentData.confirmation_requirement
    ? (shipmentData.confirmation_requirement as ShipmentConfirmationRequirementEnum)
    : ShipmentConfirmationRequirementEnum.Null

  const formatSubtotal = (item: ShipmentItem) => {
    if (_isNil(item.subtotal_price)) {
      const amount = ((item.subtotal_cents || 0) / 100).toFixed(2)
      const currency = shipmentData.currency_code || 'USD'
      return { amount: amount, currency_code: currency }
    } else {
      return item.subtotal_price
    }
  }

  const formatUnitPrice = (item: ShipmentItem) => {
    if (_isNil(item.unit_price)) {
      const amount = ((item.unit_price_cents || 0) / 100).toFixed(2)
      const currency = shipmentData.currency_code || 'USD'

      return { amount: amount, currency_code: currency }
    } else {
      return item.unit_price
    }
  }

  return {
    shipment_id: shipmentData.shipment_id,
    carrier_code: shipmentData.carrier_code,
    confirmation_requirement: shipmentConfirmation,
    currency_code: shipmentData.currency_code || 'USD',
    order_source_code: shipmentData.order_source_code,
    destination: {
      address: destination.address,
      address2: destination.address2,
      city: destination.city,
      country_code: destination.country_code,
      postal_code: destination.postal_code,
      state: destination.state,
    },
    external_account_id: shipmentData.external_account_id,
    line_items: (shipmentData.line_items || []).map(item => ({
      image_urls: item.image_urls
        ? {
            medium: item.image_urls.medium,
            original: item.image_urls.original,
            thumb: item.image_urls.thumb,
          }
        : undefined,
      options: item.options,
      description: item.description,
      product_name: item.product_name,
      quantity: item.quantity,
      subtotal_price: formatSubtotal(item),
      unit_price: formatUnitPrice(item),
    })),
    order_number: shipmentData.order_number,
    service_name: shipmentData.service_name,
    tracking_number: shipmentData.tracking_number,
    tracking_url: shipmentData.tracking_url,
    carbon_neutral: shipmentData.carbon_neutral,
    store_address: {
      country_code: store_address && store_address.country_code,
    },
  }
}
