import React from 'react'
import { makeBtStyles } from '../Theme'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

import Icon from './Icon'
import { TrackingLink } from './BtLink'
import { useShipmentData } from '../dataProviders'

// @ts-ignore
import carrierData from 'resources/carriers.yml'

const useStyles = makeBtStyles({
  icon: {
    fontSize: '0.8rem',
  },
})

const MARKED_AS_SHIPPED = 'MARKED_AS_SHIPPED';

export default function TrackingNumberLink({ onClick, className = '' }: { onClick?: () => any; className?: string }) {
  const { tracking_number, carrier_code, tracking_url } = useShipmentData()
  const carrierUrlPattern = carrierData[carrier_code]?.url
  const carrierUrl = carrierUrlPattern && carrierUrlPattern.replace('{0}', tracking_number);

  const classes = useStyles()
  // If the shipment data provides a tracking URL, use that. Otherwise, use the carrier URL.
  const href = onClick ? undefined : (tracking_url ?? carrierUrl)

  // Don't show tracking number if it's marked as shipped from Shipstation
  // We want to notify the user that the order has been shipped, but we don't want to show the tracking number since
  // orders manually marked this way will lack a tracking number.
  // https://help.shipstation.com/hc/en-us/articles/360026157691-Mark-Orders-as-Shipped#UUID-5e51849b-a99f-10cf-69f4-a71817a66df4
  if (tracking_number.match(MARKED_AS_SHIPPED)) return null;

  return (
    <TrackingLink className={className} onClick={onClick} href={href} data-testid="tracking-number-link">
      {tracking_number} <Icon icon={faExternalLinkAlt} className={`${classes.icon} external-link`} />
    </TrackingLink>
  )
}
