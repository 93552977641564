import React from 'react'

import { makeBtStyles } from '../Theme'
import SocialMediaGrid from '../components/SocialMediaGrid'
import { useFeatureEnables } from '../dataProviders'
import PoweredBy from '../components/PoweredBy'
import { MIN_TABLET_SIZE } from '../utils'

import Card from './Card'
import ContactSection from './ContactSection'
import VerticalAppBar from './VerticalAppBar'
import { CONTENT_WIDTH, MOBILE_CONTENT_WIDTH } from '../components/defaultStyleVars'

const useStyles = makeBtStyles(theme => ({
  mainCard: {
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  contactWrapper: ({ showGiftLink }: { showGiftLink: boolean }) => ({
    position: 'absolute',
    bottom: showGiftLink ? '120px' : '130px',
  }),
  contactSection: {
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '0.15px',
    padding: '15px 52px',
    textAlign: 'left',
    display: 'block',
    width: '100%',
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      display: 'none',
    },
    '& .icon': {
      display: 'none',
    },
  },
  poweredBy: {
    position: 'absolute',
    bottom: '10px',
    left: '-16px',
  },
}))

const useSocialMediaStyles = makeBtStyles(
  theme => ({
    root: {
      boxShadow: 'none',
      backgroundColor: 'white',
      border: '1px solid rgba(0, 0, 0, 0.12)',
    },
    toolbar: () => ({
      maxHeight: '64px',
      backgroundColor: 'white',
      width: MOBILE_CONTENT_WIDTH,
      padding: 0,
      margin: '0 auto',
      flexWrap: 'wrap',
      [theme.breakpoints.up(MIN_TABLET_SIZE)]: {
        width: `${CONTENT_WIDTH}px`,
        maxWidth: '98%',
      },
    }),
    paper: {},
    menuLink: {
      position: 'absolute',
      marginLeft: '-19px',
      '& svg': {
        transform: 'scale(1.6)',
        fill: theme.link.color,
      },
    },
    link: {
      fontSize: '14px',
      verticalAlign: 'middle',
      marginLeft: '15px',
    },
    socialMediaGrid: ({ showGiftLink }: { showGiftLink: boolean }) => ({
      display: 'flex',
      position: 'absolute',
      bottom: showGiftLink ? '76px' : '87px',
      width: '200px',
      margin: '0 0 0 40px',
    }),
    socialMediaLink: {
      padding: '0 5px',
      fontSize: '20px',
      marginRight: '3px',
      marginLeft: '5px',
      '&:last-child': {
        paddingRight: '0',
      },
    },
    socialMediaLinkIcon: {
      verticalAlign: 'middle',
    },
  }),
  { name: 'NavStyles' },
)

export default function MainCard({ className }: { className?: string }) {
  const { showGiftLink } = useFeatureEnables()

  const classes = useStyles({ showGiftLink })
  const socialMediaClasses = useSocialMediaStyles({ showGiftLink })

  return (
    <Card className={`${classes.mainCard} ${className}`} aria-labelledby="main-card-header">
      <VerticalAppBar />
      <div className={classes.contactWrapper}>
        <ContactSection className={classes.contactSection} />
      </div>
      <SocialMediaGrid
        classes={{
          root: socialMediaClasses.socialMediaGrid,
          link: socialMediaClasses.socialMediaLink,
          linkIcon: socialMediaClasses.socialMediaLinkIcon,
        }}
      />
      <PoweredBy className={classes.poweredBy} />
    </Card>
  )
}
