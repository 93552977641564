import React from 'react'
import classnames from 'classnames'
import { getLuminance, darken, lighten, desaturate, transparentize } from 'polished'

import { makeBtStyles } from '../Theme'

const useStyles = makeBtStyles(theme => {
  const primaryBgColor = theme.colors.main ? theme.colors.main : ''
  const primaryTextColor = getLuminance(primaryBgColor) > 0.5 ? 'black' : 'white'

  return {
    root: {
      display: 'inline-block',
      borderRadius: '2px',
      boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.07)',
      height: '40px',
      lineHeight: '38px',
      padding: '0 16px',
      whiteSpace: 'nowrap',
      cursor: 'pointer',
      textDecoration: 'none',
      textAlign: 'center',
      '&:focus': {
        boxShadow: `0 0 0 3px ${transparentize(0.7, primaryBgColor)}`,
      },
      '&:focus, &:hover, &:active': {
        textDecoration: 'none',
        zIndex: '1',
        outline: 'none',
      },
      '&:disabled': {
        cursor: 'not-allowed',
      },
      '&.btn--default': {
        backgroundImage: 'linear-gradient(180deg, #F7F7F7 0%, #F0F0F0 100%)',
        border: '1px solid #DDDDDD',
        color: '#222222',
        '&:hover, &:active': {
          color: '#222222',
          backgroundImage: 'linear-gradient(180deg, #F0F0F0 0%, #E7E7E7 100%)',
        },
        '&:active': {
          backgroundImage: 'linear-gradient(180deg, #F0F0F0 100%, #F7F7F7 0%)',
        },
        '&:disabled': {
          color: '#929292',
          cursor: 'not-allowed',
        },
      },
      '&.btn--link': {
        '&, &:hover, &:focus, &:active': {
          border: '1px solid transparent',
          background: 'transparent',
          boxShadow: 'none',
        },
        '&:hover, &:focus, &:active': {
          textDecoration: 'underline',
        },
      },
      '&.btn--primary': {
        backgroundColor: primaryBgColor,
        border: `1px solid ${primaryBgColor}`,
        color: primaryTextColor,
        fontWeight: '600',
        '&:hover, &:active': {
          backgroundColor:
            getLuminance(primaryBgColor) > 0.1 ? darken(0.05, primaryBgColor) : lighten(0.1, primaryBgColor),
        },
        '&:active': {
          backgroundColor:
            getLuminance(primaryBgColor) > 0.1 ? darken(0.05, primaryBgColor) : lighten(0.1, primaryBgColor),
        },
        '&:disabled': {
          backgroundColor: lighten(0.35, desaturate(0.2, primaryBgColor)),
          borderColor: lighten(0.35, desaturate(0.2, primaryBgColor)),
          cursor: 'not-allowed',
        },
      },
    },
  }
})

export default function Button(props: any) {
  const classes = useStyles()
  props = { ...props }

  const variant = props.variant || 'default'
  delete props.variant

  const Element = props.as || 'button'
  delete props.as

  return <Element {...props} className={classnames(classes.root, props.className, `btn--${variant}`)} />
}
