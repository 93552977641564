import React from 'react'
import pushStat from '../pushStat'
import pushEvent from '../pushEvent'
import { analytics, EVENT_LINK } from '../Analytics'

// Has to be a class for use in Material-UI as `component` prop (ListItem in NavDrawerItem)
export function NavLink(props: any) {
  if (props.href) {
    return <BtLink linkType="navigation" target="_blank" {...props} />
  } else {
    return <span className={props.className}>{props.children}</span>
  }
}

export const CarbonNeutralLink = (props: any) => <BtLink linkType="carbonneutral" target="_blank" {...props} />
export const TrackingLink = (props: any) => <BtLink linkType="tracking" target="_blank" {...props} />
export const SocialMediaLink = (props: any) => <BtLink linkType="socialmedia" target="_blank" {...props} />

function _BtLink(
  props: {
    target?: string
    children?: React.ReactNode
    linkType?: string
    href?: string
    onClick?: (event: React.MouseEvent) => any
    className?: string
  },
  ref: any,
) {
  const linkProps: any = {
    ...props,
    href: props.href || '',
    onClick: props.onClick || (() => null),
  }
  delete linkProps.children
  delete linkProps.linkType

  const { target, children, linkType, href, onClick } = {
    ...props,
    children: props.children || null, // Can't use `defaultValues` with forwardRef
    linkType: props.linkType || '',
  }

  if (target === '_blank') {
    linkProps.rel = 'noopener noreferrer'
  }

  linkProps.href = linkProps.href || '#'

  linkProps.onClick = (e: React.MouseEvent) => {
    if (href) {
      const linkInfo = { url: href, type: linkType }
      const linkStatPush = () => pushStat('click', linkInfo)
      const linkEventPush = () => pushEvent('ui.link.clicked', { url: href, url_type: linkType })
      const linkTrack = () => analytics.event(EVENT_LINK, linkInfo)

      if (target === '_blank') {
        linkStatPush()
        linkEventPush()
        linkTrack()
      } else {
        e.preventDefault()
        linkEventPush()
        linkStatPush().finally(() => {
          window.location.href = href
        })
        linkTrack()
      }
    } else if (onClick) {
      e.preventDefault()
      onClick(e)
    }
  }

  return (
    <a {...linkProps} ref={ref}>
      {children}
    </a>
  )
}

export const BtLink = React.forwardRef(_BtLink)
