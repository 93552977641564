import React from 'react'

import { Branding } from '../dataProviders'
import ThemeProvider from '../Theme'
import PoweredBy from '../components/PoweredBy'
import { makeBtStyles } from '../Theme'

import AppBar from './AppBar'

import TrackingPage from './TrackingPage'
import ReturnsPage from './ReturnsPage'

const useStyles = makeBtStyles(theme => ({
  layout01: {
    position: 'relative', // want 'absolute'-ly positioned elements to be able to go below the fold (body => height:100%)
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flexGrow: '1',
    display: 'flex',
  },
}))

function Layout01({ children }: { branding: Branding; children: React.ReactNode }) {
  const classes = useStyles()

  return (
    <ThemeProvider>
      <div data-testid="layout01" className={classes.layout01}>
        <AppBar />
        <div className={classes.content}>{children}</div>
        <PoweredBy />
      </div>
    </ThemeProvider>
  )
}

export default {
  Layout: Layout01,
  TrackingPage,
  ReturnsPage,
}
