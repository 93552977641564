import React, { useContext } from 'react'
import { Shipment } from './ShipmentProvider'

interface TrackContextApi {
  sms_prohibited_by_branding?: boolean
  sms_supported_country_codes?: string[]
  ebay_order_source_codes?: string[]
}

export interface TrackContext {
  sms_prohibited_by_branding: boolean
  sms_supported_country_codes: string[]
  ebay_order_source_codes: string[]
  isBrandingSuppressed: (shipment: Shipment) => boolean
}

const TrackContextDataContext = React.createContext({})
export const TrackContextDataProvider = TrackContextDataContext.Provider
export function useTrackContextData(): TrackContext {
  const trackContextApi = (useContext(TrackContextDataContext) as TrackContextApi) || {}

  return {
    sms_prohibited_by_branding: trackContextApi.sms_prohibited_by_branding || false,
    sms_supported_country_codes: trackContextApi.sms_supported_country_codes || [],
    ebay_order_source_codes: trackContextApi.ebay_order_source_codes || [],
    isBrandingSuppressed: (shipment: Shipment) =>
      trackContextApi.ebay_order_source_codes?.includes(shipment.order_source_code || '') || false,
  }
}
