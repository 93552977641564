import React from 'react'

import { useBrandingData } from '../dataProviders'

export default function ReturnPolicyText() {
  const { return_policy } = useBrandingData()

  if (!return_policy) {
    return null
  }

  const paragraphs = return_policy.split('\n')

  return (
    <>
      {paragraphs.map(p => (
        <p>{p}</p>
      ))}
    </>
  )
}
