import React from 'react'

import SmsControls from '../components/SmsControls'
import { useFeatureEnables } from '../dataProviders'
import { makeBtStyles, Theme } from '../Theme'
import { MIN_TABLET_SIZE, MIN_DESKTOP_SIZE } from '../utils'

import Card, { CardSection } from './Card'

const useStyles = makeBtStyles((theme: Theme) => ({
  root: {
    '&:empty': {
      display: 'none',
    },
  },
}))

const useSmsControlsStyles = makeBtStyles((theme: Theme) => ({
  mainMessage: {
    margin: '-10px 0px 12px 0px',
    fontSize: '14px',
    fontWeight: '500',
    [theme.breakpoints.down(MIN_DESKTOP_SIZE)]: {
      margin: '0px 0px 12px 10px',
    },
  },
  phoneIcon: {
    visibility: 'hidden',
  },
  root: {
    position: 'relative',
    [theme.breakpoints.between(MIN_TABLET_SIZE, MIN_DESKTOP_SIZE)]: {
      marginTop: '-25px',
      marginBottom: '-15px',
    },
  },
  button: {
    marginBottom: '12px',
    width: '100%',
    [theme.breakpoints.between(MIN_TABLET_SIZE, MIN_DESKTOP_SIZE)]: {
      width: '20%',
    },
  },
  formWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  subscribedMessage: {
    justifyContent: 'space-between',
    [theme.breakpoints.between(MIN_TABLET_SIZE, MIN_DESKTOP_SIZE)]: {
      display: 'flex',
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  subscribedMessageText: {
    alignSelf: 'center',
    marginTop: '5px',
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      marginTop: '-15px',
    },
  },
  form: {
    marginBottom: '12px',
    [theme.breakpoints.up(MIN_DESKTOP_SIZE)]: {
      marginTop: '10px',
    },
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      marginTop: '5px',
    },
  },
  termsMessage: {
    fontSize: '14px',
    fontWeight: 'normal',
    margin: 0,
    color: '#929292',
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      fontSize: '12px',
    },
  },
}))

export default function SmsCard({ className = '' }) {
  const classes = useStyles()
  const smsControlsClasses = useSmsControlsStyles()
  const { showSms } = useFeatureEnables()

  if (!showSms) {
    return null
  }

  return (
    <Card className={`${classes.root} ${className}`}>
      <CardSection className={`${classes.smsCard} ${className}`}>
        <SmsControls classes={smsControlsClasses} />
      </CardSection>
    </Card>
  )
}
