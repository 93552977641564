import React from 'react'
import { ContactEmail, ContactPhone, ReturnPolicyLink } from '../components/ContactInfo'

export default function CardSection({ className = '' }: { className?: string }) {
  return (
    <div>
      <ContactPhone className={className} />
      <ContactEmail className={className} />
      <ReturnPolicyLink className={className} />
    </div>
  )
}
