import React from 'react'
import { string } from 'prop-types'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'

import { NavLink } from '../components/BtLink'

export default function NavDrawerItem({ href, text, className }: { href: string; text: string; className?: string }) {
  return (
    <ListItem component={NavLink} href={href} className={className} target="_blank" button>
      <ListItemText primary={text} />
    </ListItem>
  )
}

NavDrawerItem.propTypes = {
  href: string.isRequired,
  text: string.isRequired,
  className: string,
}
