import React from 'react'

import { makeBtStyles, Theme } from '../Theme'

import ContactSection from './ContactSection'
import Card from './Card'

const useStyles = makeBtStyles((theme: Theme) => ({
  root: {
    padding: '12px 20px',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    '&:empty': {
      display: 'none',
    },
  },
}))

export default function ContactCard({ className = '' }) {
  const classes = useStyles()

  return (
    <Card className={`${classes.root} ${className}`}>
      <ContactSection />
    </Card>
  )
}
