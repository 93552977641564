import React from 'react'
import { useIntl } from 'react-intl'
import moment from 'moment'

import { TrackingEvent, TrackingStatusCodes, TrackingStepNumbers, useTrackingData } from '../../dataProviders'
import FriendlyStatusMessage from '../FriendlyStatusMessage'

import Step from './Step'
import { ReactComponent as DeliveredIcon } from './stepperSvg/delivered.svg'
import { ReactComponent as InProgressIcon } from './stepperSvg/inProgress.svg'
import { ReactComponent as InTransitIcon } from './stepperSvg/inTransit.svg'
import { ReactComponent as OutForDeliveryIcon } from './stepperSvg/outForDelivery.svg'
import { ReactComponent as ShippedIcon } from './stepperSvg/shipped.svg'

function getCurrentStepFromTracking({
  status_code,
  estimated_delivery_date,
  events = [],
  loaded,
}: {
  status_code?: TrackingStatusCodes
  estimated_delivery_date?: string | null
  events?: Array<TrackingEvent>
  loaded: boolean
}) {
  if (!loaded) {
    return -1
  }

  const now = Date.now()
  const est_delivery_date = estimated_delivery_date ? moment(estimated_delivery_date) : null

  switch ((status_code || '').toUpperCase()) {
    case TrackingStatusCodes.AC:
    case TrackingStatusCodes.EX:
    case TrackingStatusCodes.UN:
    case TrackingStatusCodes.NY:
      if (estimated_delivery_date) {
        return TrackingStepNumbers.InTransit
      } else if (!!events.length) {
        return TrackingStepNumbers.InTransit
      } else {
        return TrackingStepNumbers.Proccessed
      }
    case TrackingStatusCodes.IT: {
      if (
        est_delivery_date?.isSame(now, 'year') &&
        est_delivery_date?.isSame(now, 'month') &&
        est_delivery_date?.isSame(now, 'day')
      ) {
        return TrackingStepNumbers.OutForDelivery
      } else {
        return TrackingStepNumbers.InTransit
      }
    }
    case TrackingStatusCodes.AT: {
      return TrackingStepNumbers.OutForDelivery
    }
    case TrackingStatusCodes.DE: {
      return TrackingStepNumbers.Delivered
    }
    default: {
      return TrackingStepNumbers.Proccessed
    }
  }
}

export default function StatusStepper({
  classes,
  stepClasses,
}: {
  classes: {
    icon?: string
    stepWrapper?: string
    friendlyStatus?: string
    statusWrap?: string
    statusNoWrap?: string
  }
  stepClasses: {
    circle?: string
    check?: string
    dividerLine?: string
    stepBlock?: string
    text?: string
  }
}) {
  const trackingData = useTrackingData()
  const statusCode = trackingData.status_code
  const friendlyWrap =
    trackingData.loaded &&
    statusCode !== TrackingStatusCodes.IT &&
    statusCode !== TrackingStatusCodes.AT &&
    statusCode !== TrackingStatusCodes.DE
  const intl = useIntl()
  const currentStep = getCurrentStepFromTracking(trackingData)
  const missedDelivery = statusCode === TrackingStatusCodes.AT

  const steps = [
    {
      label: intl.formatMessage({ description: 'statusStepper.processed', defaultMessage: 'Processed' }),
      icon: InProgressIcon,
    },
    {
      label: intl.formatMessage({ description: 'statusStepper.shipped', defaultMessage: 'Shipped' }),
      icon: ShippedIcon,
    },
    {
      label: intl.formatMessage({ description: 'statusStepper.inTransit', defaultMessage: 'In Transit' }),
      icon: InTransitIcon,
    },
    {
      label: intl.formatMessage({ description: 'statusStepper.outDelivery', defaultMessage: 'Out for Delivery' }),
      icon: OutForDeliveryIcon,
      showMiss: missedDelivery,
    },
    {
      label: intl.formatMessage({ description: 'statusStepper.delivered', defaultMessage: 'Delivered!' }),
      icon: DeliveredIcon,
    },
  ]

  return (
    <>
      <div className={`${classes.friendlyStatus} ${friendlyWrap ? classes.statusWrap : classes.statusNoWrap}`}>
        <FriendlyStatusMessage />
      </div>
      <div className={classes.stepWrapper}>
        {steps.map((step, index) => (
          <Step
            key={step.label}
            classes={stepClasses}
            label={step.label}
            Icon={step.icon}
            active={index < currentStep}
            index={index}
            current={index + 1 === currentStep}
            showMiss={step.showMiss}
          />
        ))}
      </div>
    </>
  )
}
