import React from 'react'

import { makeBtStyles } from '../Theme'
import { MIN_DESKTOP_SIZE, MIN_TABLET_SIZE } from '../utils'
import SharedAppBar from '../components/SharedAppBar'
import { CONTENT_WIDTH, MOBILE_CONTENT_WIDTH } from '../components/defaultStyleVars'

const useLogoStyles = makeBtStyles(
  theme => ({
    textLogo: {
      textAlign: 'left',
      display: 'inline-block',
      marginLeft: 0,
      fontSize: 20,
      fontWeight: '500',
      lineHeight: '24px',
      color: '#222222',
      [theme.breakpoints.down(MIN_DESKTOP_SIZE)]: {
        textAlign: 'left',
      },
      [theme.breakpoints.up('xs')]: {
        textAlign: 'center',
      },
    },
    root: {
      textAlign: 'left',
      display: 'inline-block',
      verticalAlign: 'middle',
      marginLeft: 0,
      marginRight: '5px',
      [theme.breakpoints.down(MIN_DESKTOP_SIZE)]: {
        margin: '0 auto',
      },
    },
    image: {
      maxHeight: '45px',
      maxWidth: '150px',
    },
  }),
  { name: 'LogoStyles' },
)

const useNavStyles = makeBtStyles(
  theme => ({
    root: {
      boxShadow: 'none',
      backgroundColor: 'white',
      border: '1px solid rgba(0, 0, 0, 0.12)',
    },
    toolbar: () => ({
      zIndex: '1000',
      maxHeight: '64px',
      backgroundColor: 'white',
      width: MOBILE_CONTENT_WIDTH,
      padding: 0,
      margin: '0 auto',
      flexWrap: 'wrap',
      [theme.breakpoints.up(MIN_TABLET_SIZE)]: {
        width: `${CONTENT_WIDTH}px`,
        maxWidth: '98%',
      },
    }),
    paper: {},
    menuLink: {
      position: 'absolute',
      marginLeft: '-19px',
      '& svg': {
        transform: 'scale(1.6)',
        fill: theme.link.color,
      },
    },
    linkContainer: {
      display: 'inline',
    },
    link: {
      fontSize: '14px',
      verticalAlign: 'middle',
      marginLeft: '15px',
    },
    socialMediaGrid: {
      display: 'flex',
    },
    socialMediaLink: {
      padding: '0 5px',
      fontSize: '18px',
      marginLeft: 'auto',
      '&:last-child': {
        paddingRight: '0',
      },
    },
    socialMediaLinkIcon: {
      verticalAlign: 'middle',
    },
  }),
  { name: 'NavStyles' },
)

const useNavDrawerStyles = makeBtStyles(
  theme => ({
    paper: {
      minWidth: '100%',
      bottom: '0',
      backgroundColor: 'white',
    },
    listWrapper: {},
    list: {
      width: '100%',
      margin: 'auto',
      padding: '0',
    },
    item: {
      fontSize: '18px',
      textAlign: 'center',
      borderBottom: '1px solid #f4f4f4',
      padding: '12px 0',
    },
    socialMediaGrid: {
      width: MOBILE_CONTENT_WIDTH,
      margin: '16px auto 0',
      display: 'flex',
      justifyContent: 'center',
    },
    socialMediaLink: {
      padding: '12px',
    },
    socialMediaLinkIcon: {
      fontSize: '1.25rem',
    },
  }),
  { name: 'DrawerStyles' },
)

const useStyles = makeBtStyles(theme => ({
  desktopMenuClasses: {
    [theme.breakpoints.down(MIN_DESKTOP_SIZE - 90)]: {
      display: 'none',
    },
  },
  draweMenuClasses: {
    [theme.breakpoints.up(MIN_DESKTOP_SIZE - 90)]: {
      display: 'none',
    },
  },
}))

export default function AppBar() {
  const classes = useStyles()
  const logoClasses = useLogoStyles()
  const navClasses = useNavStyles()
  const navDrawerClasses = useNavDrawerStyles()

  return (
    <SharedAppBar
      classes={classes}
      navClasses={navClasses}
      navDrawerClasses={navDrawerClasses}
      logoClasses={logoClasses}
      showSocialMedia={true}
    />
  )
}

AppBar.propTypes = {}
