import React from 'react'
import { makeBtStyles } from '../Theme'
import { FormattedMessage } from 'react-intl'

import { useShipmentData, ShipmentConfirmationRequirementEnum } from '../dataProviders'
import { ReactComponent as SignatureIcon } from './signature-icon.svg'

const useStyles = makeBtStyles(theme => ({
  root: {
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    padding: '6px 7px',
    fontSize: '10px',
    fontWeight: '500',
    display: 'block',
    width: 'max-content',
    ...theme.signatureRequired,
    '& svg': {
      display: 'inline-block',
      verticalAlign: 'middle',
      marginRight: '5px',
    },
  },
}))

export default function SignatureRequiredTag({ className = '' }) {
  const { confirmation_requirement } = useShipmentData()
  const classes = useStyles()

  if (
    !confirmation_requirement ||
    confirmation_requirement === ShipmentConfirmationRequirementEnum.Null ||
    confirmation_requirement === ShipmentConfirmationRequirementEnum.Delivery
  ) {
    return null
  }

  return (
    <span className={`${classes.root} ${className}`}>
      <SignatureIcon />
      {confirmation_requirement === ShipmentConfirmationRequirementEnum.Adult ? (
        <FormattedMessage description="adultSignatureRequired" defaultMessage="Adult Signature Required" />
      ) : (
        <FormattedMessage description="signatureRequired" defaultMessage="Signature Required" />
      )}
    </span>
  )
}
