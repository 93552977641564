import React from 'react'

import { ContactEmail, ContactPhone, ReturnPolicyLink } from '../components/ContactInfo'
import SocialMediaGrid from '../components/SocialMediaGrid'
import Card, { CardSection } from '../layout03/Card'
import { makeBtStyles } from '../Theme'

const useStyles = makeBtStyles(theme => ({
  card: {
    width: '650px',
    margin: '20px 0',
  },
  cardSection: {
    padding: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #DFE3E8',
    borderTop: 'none',
    borderRight: 'none',
    borderLeft: 'none',
  },
  contact: {
    fontSize: '16px',
    padding: '14px 35px',
    lineSpacing: '19px',
    letterSpacing: '0.15px',
    borderRight: '1px solid #DFE3E8',
    borderLeft: '1px solid #DFE3E8',
    '& .icon': {
      display: 'none',
    },
    '&:first-child': {
      padding: '14px 55px 14px 45px',
      maxWidth: '200px',
      border: 'none',
    },
    '&:last-child': {
      border: 'none',
      paddingRight: '15px',
      maxWidth: '200px',
    },
  },
  socialMediaLink: {
    fontSize: '20px',
  },
  socialMediaLinkIcon: {
    margin: '0 25px',
  },
  socialMediaGrid: {
    display: 'flex',
    margin: '12px 0',
  },
}))

export default function TabletContactCard() {
  const classes = useStyles()
  return (
    <Card className={classes.card}>
      <CardSection className={classes.cardSection}>
        <ReturnPolicyLink className={classes.contact} />
        <ContactPhone className={classes.contact} />
        <ContactEmail className={classes.contact} />
      </CardSection>
      <CardSection className={classes.cardSection}>
        <SocialMediaGrid
          classes={{
            root: classes.socialMediaGrid,
            link: classes.socialMediaLink,
            linkIcon: classes.socialMediaLinkIcon,
          }}
        />
      </CardSection>
    </Card>
  )
}
