import React from 'react'

import { makeBtStyles } from '../Theme'
import { CONTENT_WIDTH } from '../components/defaultStyleVars'

const useStyles = makeBtStyles(theme => ({
  contentWrapper: {
    margin: '30px auto',
    width: `${CONTENT_WIDTH}px`,
    maxWidth: '100%',
    alignSelf: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '30px 0',
      maxWidth: 'none',
    },
  },
}))

export default function ReturnsPage({ children }: { children: React.ReactNode }) {
  const classes = useStyles()

  return <div className={classes.contentWrapper}>{children}</div>
}
