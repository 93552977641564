import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import MuiAppBar from '@material-ui/core/AppBar'
import { Button, Toolbar } from '@material-ui/core'

import { ReactComponent as MenuSvg } from './Menu.svg'
import { NavClasses, NavLogoClasses } from './SharedAppBar'
import NavDrawer, { NavDrawerClasses, NavDrawerProvider } from './NavDrawer'
import NavLogo from './NavLogo'
import { useFeatureEnables, usePreviewConfig } from '../dataProviders'

export default function DrawerNavBar({
  className,
  logoClasses,
  navClasses = {},
  navDrawerClasses,
}: {
  className: string
  logoClasses: NavLogoClasses
  navClasses: NavClasses
  navDrawerClasses: NavDrawerClasses
}) {
  const intl = useIntl()
  const { showMenuLinks, showSocialMedia } = useFeatureEnables()

  const { mobileNavOpen } = usePreviewConfig()
  const [drawerState, setDrawerState] = useState(false)
  const toggleDrawer = (isOpen: boolean) => (event: any) => {
    // TODO: Find the proper typing for this event
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    setDrawerState(isOpen)
  }
  const openMobileNavAriaLabel = intl.formatMessage({
    description: 'openMobileNavAriaLabel',
    defaultMessage: 'Open Navigation',
  })

  useEffect(() => {
    if (mobileNavOpen === true || mobileNavOpen === false) {
      setDrawerState(mobileNavOpen)
    }
  }, [mobileNavOpen])

  return (
    <div className={className}>
      <NavDrawerProvider>
        <MuiAppBar position="static" classes={{ root: navClasses.root }}>
          <Toolbar classes={{ root: navClasses.toolbar }}>
            <>
              {(showMenuLinks || showSocialMedia) && (
                <Button
                  onClick={toggleDrawer(true)}
                  className={navClasses.menuLink}
                  aria-label={openMobileNavAriaLabel}>
                  <MenuSvg />
                </Button>
              )}
              <NavLogo classes={logoClasses} />
              <NavDrawer
                toggleDrawer={toggleDrawer}
                open={drawerState}
                classes={navDrawerClasses}
                logoClasses={logoClasses}
                navClasses={navClasses}
              />
            </>
          </Toolbar>
        </MuiAppBar>
      </NavDrawerProvider>
    </div>
  )
}
