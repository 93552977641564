import React from 'react'

import { makeBtStyles } from '../Theme'
import { useFeatureEnables } from '../dataProviders'
import { isGiftWrapped } from '../components/GiftWrapPanel'
import { MIN_TABLET_SIZE, MIN_DESKTOP_SIZE } from '../utils'

import { useIsLineItemsCardShown } from './LineItemsCard'
import TabbedShipmentActivityContent from './TabbedShipmentActivityContent'
import ShipmentActivityContent from './ShipmentActivityContent'

const useStyles = makeBtStyles(theme => ({
  root: ({ isTabbed }: { isTabbed: boolean }) => ({
    [theme.breakpoints.up(MIN_TABLET_SIZE)]: {
      display: isTabbed ? 'none' : '',
    },
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      display: isTabbed ? '' : 'none',
    },
  }),
  lineItemsCard: {
    border: 'none',
    margin: '0 0 0 20px',
    boxShadow: 'none',
    [theme.breakpoints.up(MIN_TABLET_SIZE)]: {
      width: '280px',
      margin: '0 0 0 25px',
      maxHeight: '100%',
    },
    [theme.breakpoints.down(MIN_DESKTOP_SIZE)]: {
      width: '100%',
    },
  },
  shipmentActivity: ({ showGiftLink }: { showGiftLink: boolean }) => ({
    padding: '20px 20px 10px 32px',
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.up(MIN_DESKTOP_SIZE)]: {
      maxHeight: showGiftLink ? '325px' : '355px',
    },
    [theme.breakpoints.between(MIN_TABLET_SIZE, MIN_DESKTOP_SIZE)]: {
      padding: '0px 20px 10px 32px',
      height: '300px',
    },
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      border: '1px solid #DFE3E8',
      borderTop: 'none',
      padding: '20px 20px 10px 20px',
      background: '#FFFFFF',
      margin: 'auto',
      marginTop: '0',
      width: '95% !important',
      maxWidth: '625px',
    },
  }),
  scrollableSection: ({ showGiftLink }: { showGiftLink: boolean }) => ({
    overflow: 'auto',
    width: '280px',
    paddingRight: '20px',
    marginRight: '19px',
    [theme.breakpoints.up(MIN_DESKTOP_SIZE)]: {
      width: '300px',
      height: showGiftLink ? '240px' : '310px',
    },
    [theme.breakpoints.between(MIN_TABLET_SIZE, MIN_DESKTOP_SIZE)]: {
      height: '300px',
    },
    [theme.breakpoints.down(MIN_DESKTOP_SIZE)]: {
      width: '100%',
    },
  }),
  eventRow: () => {
    return {
      [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
        padding: '0 0 20px 42px',
      },
      padding: '0 0 10px 42px',
      position: 'relative',
      color: '#929292',
      '&::before': {
        content: '""',
        width: '4px',
        backgroundColor: '#dedede',
        position: 'absolute',
        left: '20px',
        [theme.breakpoints.up(MIN_TABLET_SIZE)]: {
          height: '100%',
          top: '-10px',
        },
        [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
          height: '100%',
          top: '-20px',
        },
      },
      '&.mostRecentEvent': {
        color: 'inherit',
        '&::before': {
          backgroundColor: `${theme.colors.characteristic ? theme.colors.characteristic : theme.colors.main}`,
          top: '0',
          [theme.breakpoints.up(MIN_TABLET_SIZE)]: {
            height: '90%',
          },
          [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
            height: '90%',
          },
        },
      },
    }
  },
  shipmentTabs: {
    display: 'flex',
    background: '#FFFFFF',
    minHeight: '40px',
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      margin: 'auto',
      marginBottom: '0',
      width: '95% !important',
      maxWidth: '625px',
    },
    [theme.breakpoints.between(MIN_TABLET_SIZE, MIN_DESKTOP_SIZE)]: {
      margin: '25px 50px',
      minHeight: '0px',
    },
  },
  tab: ({ lineItemsCardShown }: { lineItemsCardShown: boolean }) => ({
    fontSize: '14px',
    lineSpacing: '17px',
    letterSpacing: '0.13px',
    fontWeight: 'bold',
    color: '#000000',
    width: '50%',
    textDecoration: 'none !important',
    [theme.breakpoints.up(MIN_DESKTOP_SIZE)]: {
      textAlign: 'left',
      margin: '20px 0 0 52px',
    },
    [theme.breakpoints.up(MIN_TABLET_SIZE)]: {
      borderTop: 'none',
    },
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      border: '1px solid #DEDEDE',
      padding: lineItemsCardShown ? '13px 0px' : '20px 0',
      width: lineItemsCardShown ? '50%' : '100%',
      textAlign: 'center',
      borderBottom: lineItemsCardShown ? '' : 'none',
    },
  }),
  activeTab: {
    borderBottom: 'none !important',
  },
  inactiveTab: {
    background: '#F6F5F5',
  },
  leftTab: {
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      borderRight: 'none !important',
    },
  },
  rightTab: {
    [theme.breakpoints.up(MIN_DESKTOP_SIZE)]: {
      marginLeft: '22px !important',
    },
    [theme.breakpoints.between(MIN_TABLET_SIZE, MIN_DESKTOP_SIZE)]: {
      marginLeft: '66px',
    },
  },
}))

const useTrackingStyles = makeBtStyles(theme => ({
  event: {
    '& .timestamp': {
      fontSize: '12px',
      fontWeight: '700',
      lineHeight: '12px',
      display: 'block',
      textTransform: 'uppercase',
      marginBottom: '2px',
    },
    '& .description, & .location': {
      fontSize: '14px',
      fontWeight: '300',
      lineHeight: '14px',
      letterSpacing: '0',
    },
    '& .location': {
      marginLeft: '3px',
    },
  },
  emptyMsg: {
    padding: '15px 20px',
    fontStyle: 'italic',
    color: '#929292',
  },
  collapseToggle: {
    [theme.breakpoints.up(MIN_TABLET_SIZE)]: {
      position: 'relative',
      bottom: '-24px',
      marginLeft: '20px',
    },
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      padding: '10px 20px',
    },
    fontSize: '14px',
    textTransform: 'lowercase',
    width: '100%',
  },
  showMore: {
    display: 'inline-block',
  },
}))

const useLineItemStyles = makeBtStyles(theme => ({
  item: {
    minHeight: '63px',
    marginBottom: '21px',
    '&.item--no-image': {
      minHeight: '63px',
      '& $name, & $quantity, & $options': {
        marginLeft: '20px',
      },
    },
  },
  image: {
    float: 'left',
    marginRight: '16px',
    width: '50px',
    height: '50px',
    border: '1px solid #DFE3E8',
    display: 'flex',
    '& img': {
      alignSelf: 'center',
      margin: '0 auto',
    },
  },
  name: {
    marginLeft: '66px',
    paddingRight: '20px',
    fontSize: '14px',
    lineHeight: '21px',
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-line-clamp': '1',
    '-webkit-box-orient': 'vertical',
  },
  description: {
    marginLeft: '66px',
    paddingRight: '20px',
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: '300',
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-line-clamp': '1',
    '-webkit-box-orient': 'vertical',
  },
  options: {
    marginLeft: '66px',
    paddingRight: '20px',
    fontSize: '12px',
    fontWeight: '300',
    lineHeight: '21px',
    listStyleType: 'none',
    padding: '0',
    marginTop: '0',
    marginBottom: '0',
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-line-clamp': '1',
    '-webkit-box-orient': 'vertical',
  },
  optionItem: {
    display: 'inline',
    '&:after': {
      content: '", "',
    },
    '&:last-child:after': {
      display: 'none',
    },
  },
  optionLabel: {
    fontWeight: 'bold',
    textTransform: 'capitalize',
    display: 'none',
  },
  quantity: {
    display: 'table',
    marginLeft: '66px',
    fontSize: '12px',
    fontWeight: '300',
    lineHeight: '20px',
  },
  unitPrice: {
    display: 'table',
    marginLeft: '66px',
    fontSize: '12px',
    fontWeight: '300',
    lineHeight: '21px',
  },
  subtotal: {
    display: 'none',
  },
  collapseToggle: {
    [theme.breakpoints.up(MIN_TABLET_SIZE)]: {
      position: 'relative',
      bottom: '-5px',
    },
    fontSize: '14px',
    textTransform: 'lowercase',
    width: '100%',
  },
}))

export default function ShipmentActivity({ className }: { className?: string }) {
  const lineItemsCardShown = useIsLineItemsCardShown()
  const isGiftWrappedBool = isGiftWrapped ? true : false

  const { showGiftLink } = useFeatureEnables()

  const classes = useStyles({ showGiftLink, lineItemsCardShown, isTabbed: false })
  const tabbedClasses = useStyles({ showGiftLink, lineItemsCardShown, isTabbed: true })

  const trackingClasses = useTrackingStyles()
  const lineItemClasses = useLineItemStyles()

  return (
    <div className={className}>
      <ShipmentActivityContent
        classes={classes}
        trackingClasses={trackingClasses}
        lineItemClasses={lineItemClasses}
        isGiftWrapped={isGiftWrappedBool}
        lineItemsCardShown={lineItemsCardShown}
      />
      <TabbedShipmentActivityContent
        classes={tabbedClasses}
        trackingClasses={trackingClasses}
        lineItemClasses={lineItemClasses}
        isGiftWrapped={isGiftWrappedBool}
        lineItemsCardShown={lineItemsCardShown}
      />
    </div>
  )
}
