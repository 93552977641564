import {
  ThemeConfig,
  Layout01ThemeCustomizations,
  Layout02ThemeCustomizations,
  Layout03ThemeCustomizations,
} from './index'

const DarkTheme: ThemeConfig = {
  colors: {
    main: '#2c3035',
  },
  page: {
    background: '#484848',
    color: '#2c3035',
  },
  link: {
    textDecoration: 'underline',
  },
  characteristicFont: {
    fontFamily: 'Oswald, sans-serif',
    fontWeight: '500',
  },
  mainTrackingStatus: {
    textTransform: 'uppercase',
    fontSize: '40px',
    lineHeight: '1',
  },
  signatureRequired: {
    background: '#fa965a',
    color: '#222222',
  },
  card: {
    background: 'white',
  },
  recentTrackingDot: {
    backgroundColor: '#2c3035',
  },
  layoutSpecific: {
    layout01: {
      cardHeader: {
        background: '#222222',
        color: 'white',
      },
    } as Layout01ThemeCustomizations,
    layout02: {
      cardHeader: {
        // background: '#222222',
        color: 'white',
      },
    } as Layout02ThemeCustomizations,
    layout03: {
      cardHeader: {
        // background: '#222222',
        color: 'white',
      },
    } as Layout03ThemeCustomizations,
  },
}

export default DarkTheme
