import React from 'react'
import { makeBtStyles } from '../Theme'

import ContactInfo from '../components/ContactInfo'

const useStyles = makeBtStyles(theme => ({
  root: {
    '& .contactPhone, & .contactEmail, & .returnPolicyLink': {
      display: 'flex',
      fontSize: '14px',
      fontWeight: '400',
      marginBottom: '8px',
      alignItems: 'center',
      position: 'relative',
      height: '32px',
      lineHeight: '17px',
      '& .text': {
        overflowWrap: 'break-word',
        maxWidth: '100%',
        paddingRight: '40px',
      },
      '& .icon': {
        width: '16px',
        height: '16px',
        padding: '7px',
        border: '1px solid #DFE3E8',
        borderRadius: '20px',
        position: 'absolute',
        right: '0',
        top: '0',
      },
    },
  },
}))

export default function CardSection({ className = '' }: { className?: string }) {
  const classes = useStyles()
  return <ContactInfo className={`${classes.root} ${className}`} />
}
