import React from 'react'

import ThemeProvider from './Theme'

export default function NotFound() {
  return (
    <ThemeProvider>
      <div
        style={{
          width: '850px',
          maxWidth: '90%',
          margin: '30px auto',
          border: '0.5px solid #DFE3E8',
          padding: '16px',
          background: 'white',
          textAlign: 'center',
        }}>
        <h1>Shipment not found</h1>
      </div>
    </ThemeProvider>
  )
}
