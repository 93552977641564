import React from 'react'
import { useIntl } from 'react-intl'
import moment from 'moment'
import classnames from 'classnames'

// @ts-ignore - no typing info for YML files
import carrierData from 'resources/carriers.yml'

import { useTrackingData, TrackingStatusCodes, TrackingEvent, useShipmentData, Carrier } from '../dataProviders'
import { isGiftWrapped } from './GiftWrapPanel'
import { makeBtStyles } from '../Theme'

function useFriendlyMessageFromStatus(
  {
    source,
    status_code,
    estimated_delivery_date,
    events = [],
  }: {
    source?: string
    status_code?: TrackingStatusCodes
    estimated_delivery_date?: string | null
    events?: Array<TrackingEvent>
  },
  carrier_code: Carrier,
) {
  const intl = useIntl()

  if (!source || source.toLowerCase() !== 'shipengine') {
    return null
  }

  const now = Date.now()
  const est_delivery_date = estimated_delivery_date ? moment(estimated_delivery_date) : null

  const IN_TRANSIT_MSG = isGiftWrapped
    ? intl.formatMessage({
        description: 'friendlyStatusMessage.inTransitAsGift',
        defaultMessage: 'Your gift is on its way.',
      })
    : intl.formatMessage({
        description: 'friendlyStatusMessage.inTransit',
        defaultMessage: 'Your shipment is on its way.',
      })

  switch ((status_code || '').toUpperCase()) {
    case 'AC':
      return intl.formatMessage({
        description: 'friendlyStatusMessage.accepted',
        defaultMessage: 'Tracking details will be available once package arrives at the shipping facility.',
      })
    case 'IT':
      if (
        est_delivery_date?.isSame(now, 'year') &&
        est_delivery_date?.isSame(now, 'month') &&
        est_delivery_date?.isSame(now, 'day')
      ) {
        return intl.formatMessage({
          description: 'friendlyStatusMessage.outForDelivery',
          defaultMessage: 'Your shipment is out for delivery.',
        })
      } else {
        return IN_TRANSIT_MSG
      }
    case 'DE':
      return intl.formatMessage({
        description: 'friendlyStatusMessage.delivered',
        defaultMessage: 'Your shipment has been delivered.',
      })
    case 'EX':
      return intl.formatMessage({
        description: 'friendlyStatusMessage.exception',
        defaultMessage: 'Your shipment status is unavailable. Check back later.',
      })
    case 'UN':
      if (estimated_delivery_date || events.length) {
        return IN_TRANSIT_MSG
      } else {
        return intl.formatMessage({
          description: 'friendlyStatusMessage.unknown',
          defaultMessage: 'Your shipment status is unknown. Check back later.',
        })
      }
    case 'AT': {
      const carrierName = carrierData[carrier_code]?.name || 'the carrier'
      return intl.formatMessage(
        {
          description: 'friendlyStatusMessage.attemptedDelivery',
          defaultMessage: 'Contact {carrierName} for information on the attempted delivery.',
        },
        { carrierName },
      )
    }
    default:
      return intl.formatMessage({
        description: 'friendlyStatusMessage.fallbackDefault',
        defaultMessage: 'Tracking details will be available once package arrives at the shipping facility.',
      })
  }
}

const useStyles = makeBtStyles({
  skeleton: {
    display: 'inline-block',
    backgroundColor: '#ededed',
    color: 'transparent',
    width: '250px',
  },
})

export default function FriendlyStatusMessage({ className = '' }) {
  const trackingData = useTrackingData()
  const { carrier_code } = useShipmentData()
  const classes = useStyles()

  const message = useFriendlyMessageFromStatus(trackingData, carrier_code)
  const loaded = trackingData.loaded

  if (!message && loaded) {
    return null
  }

  return <span className={classnames(className, { [classes.skeleton]: !loaded })}>{loaded ? message : 'Loading'}</span>
}
