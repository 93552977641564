/* eslint-disable camelcase */
import axios from 'axios'

const buildUrl = () => {
  return `${process.env.REACT_APP_API_URL}/record-ui-event`
}

let configured
let isPreview
let shipmentId
let applicationId

export default function push(event, context) {
  const data = {
    event,
    application_id: applicationId,
    shipment_id: shipmentId,
    event_data: context,
  }

  if (configured && !isPreview) {
    return new Promise(resolve => {
      setTimeout(resolve, 2000) // just in case our axios call takes too long
      axios.post(buildUrl(), data).then(resolve).catch(resolve)
    })
  }

  return new Promise(resolve => {
    resolve()
  }) // don't break upstream usage (eg allow navigation)
}

export function configureUiEvent(params) {
  configured = true
  isPreview = params.isPreview
  shipmentId = params.shipmentId
  applicationId = params.applicationId
}
