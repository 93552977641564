import React, { useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'

import { NavLink } from './BtLink'
import { useBrandingData, useFeatureEnables } from '../dataProviders'

export default function NavLogo({
  classes = {},
}: {
  classes: {
    root?: string
    image?: string
    textLogo?: string
  }
}) {
  const { logo_url: logoUrl, store_url: storeUrl, logo_type: logoType, store_name: storeName } = useBrandingData()
  const { useStoreUrl } = useFeatureEnables()

  const [imageUrl, setImageUrl] = useState(logoUrl)
  const onError = () => setImageUrl('') // fall back to store name
  const href = useStoreUrl ? storeUrl : ''

  useEffect(() => {
    // needed b/c we're effectively caching in local state to support the onError
    // and we need to make sure the logo updates on logo change when previewing
    if (imageUrl !== logoUrl && logoUrl) {
      setImageUrl(logoUrl)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoUrl]) // Don't want imageUrl in deps (will loop forever then)

  return (
    <NavLink href={href} className={classes.root}>
      {logoType === 'image' && imageUrl ? (
        <img src={imageUrl} alt={storeName || 'logo'} onError={onError} className={classes.image} />
      ) : (
        <Typography variant="h1" className={classes.textLogo}>
          {storeName}
        </Typography>
      )}
    </NavLink>
  )
}
