import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import classnames from 'classnames'

import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'

import { LineItemCount } from '../components/LineItems'
import TrackingEvents from '../components/TrackingEvents'
import Icon from '../components/Icon'

import { CardSection } from './Card'
import LineItemsCard from './LineItemsCard'

interface ActivityContent {
  root?: string
  lineItemsCard: string
  shipmentActivity?: string
  scrollableSection?: string
  marker?: string
  eventRow?: string
  shipmentTabs?: string
  tab?: string
  activeTab?: string
  inactiveTab?: string
  leftTab?: string
  rightTab?: string
}

export interface TrackingClassses {
  event?: string
  emptyMsg?: string
  collapseToggle?: string
  showMore?: string
}

export interface LineItemClasses {
  item?: string
  image?: string
  name?: string
  description?: string
  options?: string
  optionItem?: string
  optionLabel?: string
  quantity?: string
  unitPrice?: string
  subtotal?: string
  collapseToggle?: string
}

export interface LineItemCardClasses {
  contentLabel?: string
  scrollableSection?: string
  orderNumberSection?: string
  orderNumber?: string
}

export default function ShipmentActivityContent({
  classes,
  trackingClasses,
  lineItemClasses,
  lineItemCardClasses,
  isGiftWrapped,
  lineItemsCardShown,
}: {
  classes: ActivityContent
  trackingClasses: TrackingClassses
  lineItemClasses: LineItemClasses
  lineItemCardClasses: LineItemCardClasses
  isGiftWrapped: Boolean
  lineItemsCardShown: Boolean
}) {
  const intl = useIntl()

  const formatTimestamp = (timestamp: Date) => {
    const date = intl
      .formatDateToParts(timestamp, { day: 'numeric', month: 'long' })
      .map(p => p.value)
      .join('')
    const time = intl
      .formatDateToParts(timestamp, { hour: 'numeric', minute: 'numeric' })
      .map(p => p.value)
      .join('')
    return `${date} - ${time}`
  }

  const eventClassName = (index: number) => classnames(classes.eventRow, { mostRecentEvent: index === 0 })

  return (
    <div className={classes.root}>
      <div className={classes.shipmentTabs}>
        <div className={classes.tab}>
          {isGiftWrapped ? (
            <FormattedMessage description="layout02.shipmentTabs.activityAsGift" defaultMessage="Gift Activity" />
          ) : (
            <FormattedMessage description="layout02.shipmentTabs.activity" defaultMessage="Shipment Activity" />
          )}
        </div>
        {lineItemsCardShown && (
          <div className={`${classes.tab} ${classes.rightTab}`}>
            <FormattedMessage description="layout02.shipmentTabs.content" defaultMessage="Shipment Contents" />
            {' ('}
            <LineItemCount />
            {')'}
          </div>
        )}
      </div>
      <div className={classes.shipmentActivity}>
        <div className={classes.scrollableSection} role="list">
          <TrackingEvents
            classes={trackingClasses}
            truncateToCount={3}
            formatTimestamp={formatTimestamp}
            eachEvent={({ content, key, index }) => (
              <CardSection className={eventClassName(index)} key={key}>
                {index === 0 && <Icon icon={faMapMarkerAlt} className={classes.marker} />}
                {content}
              </CardSection>
            )}
          />
        </div>
        {lineItemsCardShown && (
          <LineItemsCard
            className={classes.lineItemsCard}
            lineItemClasses={lineItemClasses}
            lineItemCardClasses={lineItemCardClasses}
          />
        )}
      </div>
    </div>
  )
}
