import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { Dialog, DialogTitle, DialogContent } from './Dialog'
import { smsTermsMessage } from '../SmsTermsPage'

export { useDialog } from './Dialog'

export default function SmsTermsDialog({ open, toggle }: { open: boolean; toggle: () => null }) {
  const intl = useIntl()

  return (
    <Dialog id="sms-terms-dialog" toggle={toggle} open={open}>
      <DialogTitle onClose={toggle}>
        <FormattedMessage description="smsTermsDialogTitle" defaultMessage="SMS Terms" />
      </DialogTitle>

      <DialogContent>{smsTermsMessage(intl)}</DialogContent>
    </Dialog>
  )
}
