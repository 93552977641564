import { useState, useEffect } from 'react'
import axios from 'axios'

import { transformV1BrandingToV2, originIsSafe } from './utils'

export const getShipmentBrandingUrl = args => {
  const PARAM_NAMES = [
    'branding_id',
    'carrier_code',
    'tracking_number',
    'order_number',
    'postal_code',
    'shipment_id',
    'hmac_packed_id',
  ]
  const presentParamNames = PARAM_NAMES.filter(p => args[p] !== null && args[p] !== undefined)
  const query = presentParamNames.map(paramName => `${paramName}=${args[paramName]}`).join('&')
  return `${process.env.REACT_APP_API_URL}bt_resources/?${query}`
}

export default function useShipmentBrandingData(trackingArgs) {
  const shipmentBrandingUrl = getShipmentBrandingUrl(trackingArgs)

  const { isPreview, mockPreviewData } = { ...trackingArgs }

  const [state, setState] = useState({ branding: {}, loaded: false })
  const [error, setError] = useState()

  useEffect(() => {
    if (isPreview) {
      // This path will never get reloaded if preview changes so don't put transforms for preview data here.
      // This code is only here to mimick having loaded the tracking data (preview data changes happen on `previewData`).
      setState({ ...mockPreviewData, loaded: true })
    } else {
      axios
        .get(shipmentBrandingUrl)
        .then(response => {
          const { data } = response
          if (data.shipment) {
            data.shipment.carrier_code = data.shipment.carrier_code.toLowerCase()
          }
          data.loaded = true
          setState(data)
        })
        .catch(error => {
          setError(error)
        })
    }
  }, [shipmentBrandingUrl, mockPreviewData, isPreview])

  const [previewData, setPreview] = useState(() => ({
    previewListener: window.addEventListener('message', msg => {
      if (originIsSafe(msg.origin) && msg.data.source === 'branded-tracking-preview') {
        const { branding, notification_setting, preview } = msg?.data
        setPreview({ branding, notification_setting, preview })
        if (process.env.NODE_ENV !== 'test') {
          console.debug && console.debug('Preview payload:', msg?.data)
        }
      }
    }),
  }))

  // NB - if we ever need to return a `setState` here this whole process will need to be rethought
  return [
    {
      ...state,
      branding: transformV1BrandingToV2({
        ...state.branding,
        ...previewData.branding,
      }),
      notification_setting: {
        ...state.notification_setting,
        ...previewData.notification_setting,
      },
      preview: {
        isPreview,
        ...(previewData.preview || {}),
      },
    },
    error,
    setState,
  ]
}
