import React from 'react'

import { useShipmentData } from '../dataProviders'

export function useHasOrderNumber() {
  const { order_number } = useShipmentData()
  return !!order_number
}

export default function OrderNumber({ className }: { className?: string }) {
  const { order_number } = useShipmentData()

  if (!order_number) {
    return null
  }

  return <div className={className}>{order_number}</div>
}
